import React, { useState, useEffect } from "react"
import useAuth from '../../hooks/useAuth'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

// import "./editakunkasbank.scss"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { Row, Col } from "react-bootstrap"
import moment from "moment"
import { EditAkunKasBankValidationSchema } from "../../components/data/ValidationSchema"
import TextError from "../../components/form/TextError"
import CustomSelect from "../../components/form/CustomSelect"

require("moment/locale/id")
let config = ""
toast.configure()

const EditAkunKasBank = ({onClickHandleEdit, idGereja, idBakowi, idUserMaint, nomorIdAkunKasBank, perkList}) => {
  
  window.scrollTo(0, 0)
  const { auth } = useAuth()

  // const apiUrlAG = "/api/ag-akunkasbank"
  const apiUrlAG = auth.API_URL + "/ag-akunkasbank"

  const [perkiraanList, setPerkiraanList] = useState([])

  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const addAkunKasBank = (input) => {
    config = {
      method : "post",
      url : `${apiUrlAG}/akunkasbank/`,
      headers : {
        "Content-Type":"application/json"
      },
      data: {
        idGereja: input.idGereja,
        idBakowi: input.idBakowi,
        idAkunKasBank: input.idAkunKasBank,
        namaAkunKasBank: input.namaAkunKasBank,
        tipe: input.tipe,
        idPerkiraan: input.idPerkiraan,
        namaBank: input.namaBank,
        nomorRekening: input.nomorRekening,
        namaDiRekening: input.namaDiRekening,
        keterangan: input.keterangan,
        idUser: input.idUserMaint,
        userActivity: 'Add'
      }
    }
    return axiosPrivate(config)
  }

  const useAddAkunKasBank = () => {
    return useMutation(
      addAkunKasBank,
      {
        onError: () => {
          toast.error('Data akun kas bank gagal ditambah !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: () => {
          toast.success('Data akun kas bank berhasil ditambah ...', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
          queryClient.invalidateQueries()
        },
      }
    )
  }

  const updateAkunKasBank = (input) => {
    config = {
      method : "post",
      url : `${apiUrlAG}/akunkasbank/${input.idGereja}/${input.idBakowi}/${input.idAkunKasBank}`, 
      headers : {
        "Content-Type":"application/json"
      },
      data: {
        namaAkunKasBank: input.namaAkunKasBank,
        tipe: input.tipe,
        idPerkiraan: input.idPerkiraan,
        namaBank: input.namaBank,
        nomorRekening: input.nomorRekening,
        namaDiRekening: input.namaDiRekening,
        keterangan: input.keterangan,
        idUser: input.idUserMaint,
        userActivity: 'Update'
      }
    }
    return axiosPrivate(config)
  }

  const useUpdateAkunKasBank = (input) => {
    return useMutation(
      updateAkunKasBank,
      {
        onError: () => {
          toast.error('Data akun kas bank gagal diubah !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: () => {
          toast.success('Data akun kas bank berhasil diubah ...', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
          queryClient.invalidateQueries()
        },
      }
    )
  }  

  useEffect(() => {
    perkList && setPerkiraanList(perkList)
  }, [perkList])

  let menuScreenClassName = "w-100 justify-content-end text-center border-0 fw-bold text-light"
  let btnTambahClassName = "btn btn-outline-primary btn-sm fst-italic fw-bold"
  let btnUbahClassName = "btn btn-outline-success btn-sm fst-italic fw-bold"
  let btnConfirmClassName = "btn btn-sm fst-italic fw-bold text-light"
  let btnCancelClassName = "btn btn-dark btn-sm fst-italic fw-bold text-light"
  const initialValues = {
    idUserMaint: idUserMaint,
    idGereja: idGereja,
    idBakowi: idBakowi,
    idAkunKasBank: nomorIdAkunKasBank ? nomorIdAkunKasBank.idAkunKasBank : "",
    namaAkunKasBank: nomorIdAkunKasBank ? nomorIdAkunKasBank.namaAkunKasBank : "",
    tipe: nomorIdAkunKasBank ? nomorIdAkunKasBank.tipeAkunKasBank : "",
    tipeNama: nomorIdAkunKasBank
      ? (
          nomorIdAkunKasBank.tipeAkunKasBank === "K"
          ? "Kas"
          : (
              nomorIdAkunKasBank.tipeAkunKasBank === "B"
              ? "Bank"
              : "Tipe salah"
            )
        )
      : "",
    idPerkiraan: nomorIdAkunKasBank ? nomorIdAkunKasBank.idPerkiraan : "",
    namaPerkiraan: nomorIdAkunKasBank ? nomorIdAkunKasBank.namaPerkiraan : "",
    namaBank: nomorIdAkunKasBank ? nomorIdAkunKasBank.namaBank : "",
    nomorRekening: nomorIdAkunKasBank ? nomorIdAkunKasBank.nomorRekening : "",
    namaDiRekening: nomorIdAkunKasBank ? nomorIdAkunKasBank.namaDiRekening : "",
    keterangan: nomorIdAkunKasBank ? nomorIdAkunKasBank.keterangan : "",
    idUser: nomorIdAkunKasBank 
      ? nomorIdAkunKasBank.idUser
        ? nomorIdAkunKasBank.idUser
        : "*"
      : idUserMaint,
    namaUser:
      nomorIdAkunKasBank && nomorIdAkunKasBank.namaUser
        ? nomorIdAkunKasBank.namaUser
        : "*",
    userActivity:
      nomorIdAkunKasBank && nomorIdAkunKasBank.userActivity
        ? nomorIdAkunKasBank.userActivity
        : "*",
    tanggalSystem:
      nomorIdAkunKasBank && nomorIdAkunKasBank.tanggalSystem
        ? nomorIdAkunKasBank.tanggalSystem
        : "*",
    menuScreen: nomorIdAkunKasBank ? "Idle" : "A D D",
    menuClass: menuScreenClassName.concat(nomorIdAkunKasBank ? " bg-info" : " bg-primary"),
    fieldDisableKey: nomorIdAkunKasBank ? true : false,
    fieldDisable: nomorIdAkunKasBank ? true : false,
    btnMenuDisable: false,
    btnTambahClass: btnTambahClassName.concat(nomorIdAkunKasBank ? "" : " invisible"),
    btnUbahClass: btnUbahClassName.concat(nomorIdAkunKasBank ? "" : " invisible"),
    btnConfirmClass: btnConfirmClassName.concat(nomorIdAkunKasBank ? " invisible" : " bg-primary visible"),
    btnCancelClass: btnCancelClassName.concat(nomorIdAkunKasBank ? " invisible" : " bg-primary visible"),
    btnConfirmText: nomorIdAkunKasBank ? "" : "Tambahkan data ...",
  }

  const { mutate: addAKB } = useAddAkunKasBank()
  const { mutate: updateAKB } = useUpdateAkunKasBank()
  const onClickConfirm = (input) => {
    if (input.menuScreen === "A D D") {
      addAKB(input)
      onClickHandleEdit(false)
    }
    if (input.menuScreen === "UPDATE") {
      updateAKB(input)
      onClickHandleEdit(false)
    }
  }

  const clickExit = () => {
    onClickHandleEdit(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EditAkunKasBankValidationSchema}
      enableReinitialize
    >
      {(formik) => {
        // function screenIdle
        const screenIdle = () => {
          formik.setFieldValue("menuScreen", "Idle")
          formik.setFieldValue("btnTambahClass", btnTambahClassName.concat(" ", "visible"))
          formik.setFieldValue("btnUbahClass", btnUbahClassName.concat(" ", "visible"))
          formik.setFieldValue("menuClass", menuScreenClassName.concat(" ", "bg-primary"))
          // show buttonTU => 'FF'
          formik.setFieldValue("btnMenuDisable", false)
          // hidden button konfirmasi
          formik.setFieldValue("btnConfirmClass", btnConfirmClassName.concat(" ", "invisible"))
          formik.setFieldValue("btnCancelClass", btnCancelClassName.concat(" ", "invisible"))
          // disable semua field
          formik.setFieldValue("fieldDisableKey", true)
          formik.setFieldValue("fieldDisable", true)
        }
        // function clickTambah
        const clickTambah = () => {
          formik.setFieldValue("idAkunKasBank", "")
          formik.setFieldValue("namaAkunKasBank", "")
          formik.setFieldValue("tipe", "")
          formik.setFieldValue("idPerkiraan", "")
          formik.setFieldValue("namaPerkiraan", "")
          formik.setFieldValue("namaBank", "")
          formik.setFieldValue("nomorRekening", "")
          formik.setFieldValue("namaDiRekening", "")
          formik.setFieldValue("keterangan", "")
          formik.setFieldValue("idUser", idUserMaint)
          formik.setFieldValue("menuScreen", "A D D")
          formik.setFieldValue("menuClass", menuScreenClassName.concat(" ", "bg-primary"))
          // hidden buttonTU => [T]ambah - [U]bah
          formik.setFieldValue("btnTambahClass", btnTambahClassName.concat(" ", "invisible"))
          formik.setFieldValue("btnUbahClass", btnUbahClassName.concat(" ", "invisible"))
          // show button konfirmasi (Tambahkan data ... & Batal)
          formik.setFieldValue("btnConfirmClass", btnConfirmClassName.concat(" ", "bg-primary", " ", "visible"))
          formik.setFieldValue("btnCancelClass", btnCancelClassName.concat(" ", "visible"))
          formik.setFieldValue("btnConfirmText", "Tambahkan data ...")
          // enable semua field
          formik.setFieldValue("fieldDisableKey", false)
          formik.setFieldValue("fieldDisable", false)
        }
        // function clickUbah
        const clickUbah = () => {
          formik.setFieldValue("menuScreen", "UPDATE")
          formik.setFieldValue("menuClass", menuScreenClassName.concat(" ", "bg-success"))
          // hidden buttonTU
          formik.setFieldValue("btnTambahClass", btnTambahClassName.concat(" ", "invisible"))
          formik.setFieldValue("btnUbahClass", btnUbahClassName.concat(" ", "invisible"))
          // show button konfirmasi (Ubah data ... & Batal)
          formik.setFieldValue("btnConfirmClass", btnConfirmClassName.concat(" ", "bg-primary", " ", "visible"))
          formik.setFieldValue("btnCancelClass", btnCancelClassName.concat(" ", "visible"))
          formik.setFieldValue("btnConfirmText", "Ubah data ...")
          // enable semua field
          formik.setFieldValue("fieldDisable", false)
        }
        function renderTipe(e) {
          const tipeNm = e.target.value
          formik.handleChange(e)
          switch (tipeNm.toUpperCase()) {
            case "K":
              formik.setFieldValue("tipeNama", "Kas")
              break
            case "B":
              formik.setFieldValue("tipeNama", "Bank")
              break
            default:
              formik.setFieldValue("tipeNama", "Tipe salah")
              break
          }
        }
        return (
          <div className="container">
            <Form className="w-100">
              <Row className="fw-bold mb-2">
                <Field name="idUserMaint" type="hidden" />
                <Field name="idGereja" type="hidden" />
                <Field name="idBakowi" type="hidden" />
                <Field name="fieldDisableKey" type="hidden" />
                <Field name="fieldDisable" type="hidden" />
                <Field name="menuClass" type="hidden" />
                <Field name="btnTambahClass" type="hidden" />
                <Field name="btnUbahClass" type="hidden" />
                <Field name="btnMenuDisable" type="hidden" />
                <Field name="btnConfirmClass" type="hidden" />
                <Field name="btnCancelClass" type="hidden" />
                <Field name="btnConfirmText" type="hidden" />
                <Col md={9} sm={6} xs={6}>
                  <p className="mb-2">
                    <span className="text-primary fw-bold">
                      <i className="bi bi-arrow-right-square-fill"></i>
                    </span>
                    &nbsp; Nama Akun &nbsp; : &nbsp;
                    <strong>{formik.values.namaAkunKasBank}</strong>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </p>
                </Col>
                <Col md={3} sm={3} xs={3}>
                  <Field name="menuScreen" disabled={true} className={formik.values.menuClass} />
                </Col>
              </Row>
              <hr />
              <Row className="fw-bold">
                <Col className="form-group mt-2 col-3 justify-content-start">
                  <label className="text-primary w-20" htmlFor="idAkunKasBank"> Id </label>
                  <Field 
                    className="text-center w-30" 
                    id="idAkunKasBank" 
                    name="idAkunKasBank" 
                    disabled={formik.values.fieldDisableKey} 
                    placeholder="id"
                  />
                  <ErrorMessage name="idAkunKasBank" component={TextError} className="invalid-feedback text-warning" />
                </Col>
                <Col className="form-group mt-2 ps-0 col-9 justify-content-start">
                  <label className="text-primary w-15" htmlFor="namaAkunKasBank"> Nama </label>
                  <Field
                    className="text-start ms-1 w-75"
                    id="namaAkunKasBank"
                    name="namaAkunKasBank"
                    disabled={formik.values.fieldDisable}
                    placeholder="Nama Akun"
                  />
                  <ErrorMessage 
                    name="namaAkunKasBank" 
                    component={TextError} 
                    className="invalid-feedback text-warning" 
                  />
                </Col>
              </Row>
              <hr />
              <Row className="fw-bold">
                <Col className="form-group mt-2 col-3 justify-content-end">
                  <label className="text-primary w-20" htmlFor="tipe"> Tipe </label>
                  <Field
                    className="text-center w-15"
                    id="tipe"
                    name="tipe"
                    onChange={renderTipe}
                    disabled={formik.values.fieldDisable}
                  />
                  <Field 
                    className={"text-center w-50 fw-bold ms-1".concat(
                      ["K", "B"].includes(formik.values.tipe)
                        ? " text-success border-success"
                        : " text-warning border-danger"
                    )}
                    id="tipeNama"
                    name="tipeNama"
                    disabled={true}
                  />
                  <ErrorMessage name="tipe" component={TextError} className="invalid-feedback text-warning"/>
                </Col>
                <Col className="form-group mt-2 ps-0 col-1 justify-content-start">
                  <label className="text-primary" htmlFor="idPerkiraan"> Perkiraan </label>
                </Col>
                <Col className="form-group mt-2 ms-4 col-7 justify-content-start">
                  <CustomSelect
                    options={perkiraanList}
                    value={formik.values.idPerkiraan}
                    placeholder="Ketik / Pilih => Nomor / Nama Perkiraan"
                    onChange={(value) =>
                      value
                        ? formik.setFieldValue("idPerkiraan", value.value)
                        : null
                    }
                    disableOpt={(perkiraanList) => perkiraanList.GD === "G"}
                    disableSelect={formik.values.fieldDisable}
                    clearable={false}
                    className="mb-1"
                  />
                  <ErrorMessage name="idPerkiraan" component={TextError} className="invalid-feedback" />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col className="form-group mt-3 col-4">
                  <label className="text-primary w-25" htmlFor="namaBank"> Nama Bank </label>
                  <Field
                    className="text-start w-75"
                    id="namaBank"
                    name="namaBank"
                    disabled={formik.values.fieldDisable}
                  />
                </Col>
                <Col className="form-group mt-3 col-3">
                  <label className="text-primary w-25" htmlFor="nomorRekening"> No. Rek. </label>
                  <Field
                    className="text-start w-75"
                    id="nomorRekening"
                    name="nomorRekening"
                    disabled={formik.values.fieldDisable}
                  />
                </Col>
                <Col className="form-group mt-3 col-5">
                  <label className="text-primary w-25" htmlFor="namaDiRekening"> Nama di Rek. </label>
                  <Field
                    className="text-start w-75"
                    id="namaDiRekening"
                    name="namaDiRekening"
                    disabled={formik.values.fieldDisable}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col className="form-group mt-3 col-12">
                  <label className="text-primary w-15" htmlFor="keterangan"> Keterangan </label>
                  <Field
                    className="text-start w-70"
                    id="keterangan"
                    name="keterangan"
                    disabled={formik.values.fieldDisable}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="fw-bold small">
                <p>
                  <span className="text-warning">
                    <i className="bi bi-x-circle-fill"></i>
                  </span>
                  &nbsp; Maintenance : {formik.values.userActivity}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="text-warning fw-bold">
                    <i className="bi bi-arrow-right-circle"></i>
                  </span>
                  &nbsp; Id User Maint : {formik.values.idUser} - 
                  {formik.values.namaUser} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="text-warning fw-bold">
                    <i className="bi bi-arrow-right-circle"></i>
                  </span>
                  &nbsp; Tanggal Maintenance : 
                  {moment(formik.values.tanggalSystem).format("LLLL")}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
              </Row>
              <Row className="fw-bold small">
                <Col className="justify-content-start">
                  <button
                    type="button"
                    className={formik.values.btnConfirmClass}
                    disabled={
                      parseInt(formik.values.idAkunKasBank, 10) > 0 &&
                      parseInt(formik.values.idAkunKasBank, 10) < 256 &&
                      formik.values.namaAkunKasBank &&
                      formik.values.tipe &&
                      ["K", "B"].includes(formik.values.tipe) &&
                      formik.values.idPerkiraan
                        ? false
                        : true
                    }
                    onClick={() => onClickConfirm(formik.values)}
                  >
                    {formik.values.btnConfirmText}
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    className={formik.values.btnCancelClass}
                    onClick={() =>
                      formik.values.menuScreen === "UPDATE"
                        ? screenIdle()
                        : onClickHandleEdit(false)
                    }
                  >
                    Batal
                  </button>
                </Col>
                <Col className="text-end">
                  <button
                    type="button"
                    className={formik.values.btnTambahClass}
                    disabled={formik.values.btnMenuDisable}
                    onClick={() => clickTambah()}
                  >
                    Tambah
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    className={formik.values.btnUbahClass}
                    disabled={formik.values.btnMenuDisable}
                    onClick={() => clickUbah()}
                  >
                    Ubah
                  </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm fst-italic fw-bold"
                    onClick={() => clickExit()}
                  >
                    Keluar
                  </button>
                </Col>
              </Row>
              <hr />
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

export default EditAkunKasBank
