import React, { useContext } from 'react'
import useLogout from '../../hooks/useLogout'

import './sidebar.scss'
import { useNavigate, Link } from 'react-router-dom'
import { DarkModeContext } from '../../context/darkModeContext'

import logo from '../../images/logo.png'

const Sidebar = () => {
  const { dispatch } = useContext(DarkModeContext)
  const navigate = useNavigate()
  const logout = useLogout()

  const signOut = async () => {
    await logout()
    navigate('/login', { replace: true })
  } 

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/home" style={{ textDecoration: "none" }}>
          <img src={logo} alt="" className="logo" />
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <li>
              <i className="bi bi-columns-gap"></i>
              <span>Dashboard</span>
            </li>
          </Link>
          <p className="title">PELAYANAN</p>
          <Link to="/maintprogram" style={{ textDecoration: "none" }}>
            <li>
              <i className="bi bi-vinyl"></i>
              <span>Program</span>
            </li>
          </Link>
          <Link to="/maintkegiatan" style={{ textDecoration: "none" }}>
            <li>
              <i className="bi bi-command"></i>
              <span>Kegiatan</span>
            </li>
          </Link>
          {/* <Link to="/mainttransaksi" style={{ textDecoration: "none" }}>
            <li>
              <i className="bi bi-arrow-left-right text-primary"></i>
              <span>Transaksi</span>
            </li>
          </Link> */}
          <Link to="/transaksiadd" style={{ textDecoration: "none" }}>
            <li>
              <i className="bi bi-plus-circle-dotted text-primary"></i>
              <span>Entry Transaksi</span>
            </li>
          </Link>
          <Link to="/transaksilist" style={{ textDecoration: "none" }}>
            <li>
              <i className="bi bi-arrow-left-right text-primary"></i>
              <span>List Transaksi</span>
            </li>
          </Link>
          {/* <Link to="/maintberita" style={{ textDecoration: "none" }}>
            <li>
              <i className="bi bi-info-circle-fill text-primary"></i>
              <span>Berita</span>
            </li>
          </Link> */}
          <p className="title">LAPORAN & INFO</p>
          <Link to="/formpengajuan" style={{ textDecoration: "none" }}>
            <li>
              <i className="bi bi-clipboard-check text-info"></i>
              <span>Form Pengajuan</span>
            </li>
          </Link>
          <Link to="/bukukas" style={{ textDecoration: "none" }}>
            <li>
              <i className="bi bi-cash-coin text-info"></i>
              <span>Laporan Buku Kas</span>
            </li>
          </Link>
          <Link to="/laporankeuangan" style={{ textDecoration: "none" }}>
            <li>
              <i className="bi bi-book text-info"></i>
              <span>Laporan Keuangan</span>
            </li>
          </Link>
          <Link to="/danadukungan" style={{ textDecoration: "none" }}>
            <li>
              <i className="bi bi-intersect text-info"></i>
              <span>Info Dana Dukungan</span>
            </li>
          </Link>
          <Link to="/anggarankegiatan" style={{ textDecoration: "none" }}>
            <li>
              <i className="bi bi-coin text-info"></i>
              <span>Info Anggaran Kegiatan</span>
            </li>
          </Link>
          {/* <Link to="/infoprogram" style={{ textDecoration: "none" }}>
            <li>
              <i className="bi bi-newspaper text-info"></i>
              <span>Info Program</span>
            </li>
          </Link> */}
          <p className="title">TABEL</p>
          <Link to="/maintsaldoawal" style={{ textDecoration: "none" }}>
            <li>
              <i className="bi bi-layout-text-window text-success"></i>
              <span>Saldo Awal</span>
            </li>
          </Link>
          <Link to="/maintakunkasbank" style={{ textDecoration: "none" }}>
            <li>
              <i className="bi bi-file-earmark-ppt text-success"></i>
              <span>Akun Kas & Bank</span>
            </li>
          </Link>
          <Link to="/maintkelompoktransaksi" style={{ textDecoration: "none" }}>
            <li>
              <i className="bi bi-braces text-success"></i>
              <span>Kelompok Transaksi</span>
            </li>
          </Link>
          <Link to="/maintjenisprogram" style={{ textDecoration: "none" }}>
            <li>
              <i className="bi bi-card-list text-success"></i>
              <span>Jenis Program</span>
            </li>
          </Link>
          <p className="title">USER</p>
          <Link to="/maintuser" style={{ textDecoration: "none" }}>
            <li>
              <i className="bi bi-person-fill text-warning"></i>
              <span>Create - Update</span>
            </li>
          </Link>
          <Link to="/profileuser" style={{ textDecoration: "none" }}>
            <li>
              <i className="bi bi-key-fill text-warning"></i>
              <span>Profile User & Change Password</span>
            </li>
          </Link>
          <Link to="/maintuserakses" style={{ textDecoration: "none" }}>
            <li>
              <i className="bi bi-person-fill text-success"></i>
              <span>User Akses</span>
            </li>
          </Link>
            <li onClick={signOut}>
              <i className="bi bi-door-open text-warning"></i>
              <span>Sign Out</span>
            </li>
        </ul>
      </div>
      <div className="bottom">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
      </div>
    </div>
  )
}

export default Sidebar
