import React from 'react'
import useAuth from '../../hooks/useAuth'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

// import "./editjenisprogram.scss"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import { EditJenisProgramValidationSchema } from '../../components/data/ValidationSchema'
import TextError from '../../components/form/TextError'

require('moment/locale/id')
let config = ""
toast.configure()

const EditJenisProgram = ({onClickHandleEdit, idGereja, idBakowi, idUserMaint, nomorIdJenisProgram}) => {
  
  window.scrollTo(0, 0)
  const { auth } = useAuth()

  // const apiUrlAG = "/api/ag-jenisprogram"
  const apiUrlAG = auth.API_URL + "/ag-jenisprogram"

  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const addJenisProgram = (input) => {
    config = {
      method : "post",
      url : `${apiUrlAG}/jenisprogram/`,
      headers : {
        "Content-Type":"application/json"
      },
      data: {
        idGereja: input.idGereja,
        idJenisProgram: input.idJenisProgram,
        namaJenisProgram: input.namaJenisProgram,
        jenisLaporan: input.jenisLaporan,
        keterangan: input.keterangan,
        idUser: input.idUserMaint,
        userActivity: 'Add'
      }
    }
    return axiosPrivate(config)
  }

  const useAddJenisProgram = () => {
      return useMutation(
          addJenisProgram, 
          {
              onError: () => {
                  toast.error('Data jenis program gagal ditambah !', {
                      position: toast.POSITION.BOTTOM_CENTER,
                      autoClose: 2000
                  })
              },
              onSuccess: () => {
                  toast.success('Data jenis program berhasil ditambah ...', {
                          position: toast.POSITION.BOTTOM_CENTER,
                          autoClose: 2000
                  })
                  queryClient.invalidateQueries()
              },
          }
      )
  }

  const updateJenisProgram = (input) => {
    config = {
      method : "post",
      url : `${apiUrlAG}/jenisprogram/${input.idGereja}/${input.idJenisProgram}`, 
      headers : {
        "Content-Type":"application/json"
      },
      data: {
        namaJenisProgram: input.namaJenisProgram,
        jenisLaporan: input.jenisLaporan,
        keterangan: input.keterangan,
        idUser: input.idUserMaint,
        userActivity: 'Update'
      }
    }
    return axiosPrivate(config)
  }

  const useUpdateJenisProgram = () => {
    const queryClient = useQueryClient()
    return useMutation(
      updateJenisProgram, 
      {
        onError: () => {
          toast.error('Data jenis program gagal diubah !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: () => {
          toast.success('Data jenis program berhasil diubah ...', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
          queryClient.invalidateQueries()
        },
      }
    )
  }

  let menuScreenClassName = 'w-100 justify-content-end text-center border-0 fw-bold text-light'
  let btnTambahClassName = 'btn btn-outline-primary btn-sm fst-italic fw-bold'
  let btnUbahClassName = 'btn btn-outline-success btn-sm fst-italic fw-bold'
  let btnConfirmClassName = 'btn btn-sm fst-italic fw-bold text-light'
  let btnCancelClassName = 'btn btn-dark btn-sm fst-italic fw-bold text-light'
  const initialValues = {
    idUserMaint: idUserMaint,
    idGereja: idGereja,
    idJenisProgram: nomorIdJenisProgram ? nomorIdJenisProgram.idJenisProgram : '',
    namaJenisProgram: nomorIdJenisProgram ? nomorIdJenisProgram.namaJenisProgram : '',
    jenisLaporan: nomorIdJenisProgram ? nomorIdJenisProgram.jenisLaporan : '',
    keterangan: nomorIdJenisProgram ? nomorIdJenisProgram.keterangan : '',
    idUser: nomorIdJenisProgram ? (nomorIdJenisProgram.idUser ? nomorIdJenisProgram.idUser : '*') : idUserMaint,
    namaUser: nomorIdJenisProgram && nomorIdJenisProgram.namaUser ? nomorIdJenisProgram.namaUser : '*',
    userActivity: nomorIdJenisProgram && nomorIdJenisProgram.userActivity ? nomorIdJenisProgram.userActivity : '*',
    tanggalSystem: nomorIdJenisProgram && nomorIdJenisProgram.tanggalSystem ? nomorIdJenisProgram.tanggalSystem : '*',
    menuScreen: nomorIdJenisProgram ? 'Idle' : 'A D D',
    menuClass: menuScreenClassName.concat(nomorIdJenisProgram ? ' bg-info' : ' bg-primary'),
    fieldDisableKey: nomorIdJenisProgram ? true : false,
    fieldDisable: nomorIdJenisProgram ? true : false,
    btnMenuDisable: false,
    btnTambahClass: btnTambahClassName.concat(nomorIdJenisProgram ? '' : ' invisible'),
    btnUbahClass: btnUbahClassName.concat(nomorIdJenisProgram ? '' : ' invisible'),
    btnConfirmClass: btnConfirmClassName.concat(nomorIdJenisProgram ? ' invisible' : ' bg-primary visible'),
    btnCancelClass: btnCancelClassName.concat(nomorIdJenisProgram ? ' invisible' : ' bg-primary visible'),
    btnConfirmText: nomorIdJenisProgram ? '' : 'Tambahkan data ...'
  }

  const { mutate: addJnsPrg } = useAddJenisProgram()
  const { mutate: updateJnsPrg } = useUpdateJenisProgram()
  const onClickConfirm = (input) => {
    if (input.menuScreen === "A D D") {
      addJnsPrg(input)
      onClickHandleEdit(false)
    }
    if (input.menuScreen === "UPDATE") {
      updateJnsPrg(input)
      onClickHandleEdit(false)
    }
  }

  const clickExit = () => {
    onClickHandleEdit(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EditJenisProgramValidationSchema}
      enableReinitialize
    >
      {formik => {
        // function screenIdle
        const screenIdle = () => {
          formik.setFieldValue('menuScreen', 'Idle')
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'visible'))
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-primary'))
          // show buttonTU => 'FF'
          formik.setFieldValue('btnMenuDisable', false)
          // hidden button konfirmasi
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'invisible'))
          // disable semua field
          formik.setFieldValue('fieldDisableKey', true)
          formik.setFieldValue('fieldDisable', true)
        }
        // function clickTambah
        const clickTambah = () => {
          formik.setFieldValue('idJenisProgram', '')
          formik.setFieldValue('namaJenisProgram', '')
          formik.setFieldValue('jenisLaporan', '')
          formik.setFieldValue('keterangan', '')
          formik.setFieldValue('idUser', idUserMaint)
          formik.setFieldValue('menuScreen', 'A D D')
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-primary'))
          // hidden buttonTU => [T]ambah - [U]bah
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'invisible'))
          // show button konfirmasi (Tambahkan data ... & Batal)
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'bg-primary', ' ', 'visible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnConfirmText', 'Tambahkan data ...')
          // enable semua field
          formik.setFieldValue('fieldDisableKey', false)
          formik.setFieldValue('fieldDisable', false)
        }
        // function clickUbah
        const clickUbah = () => {
          formik.setFieldValue('menuScreen', 'UPDATE')
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-success'))
          // hidden buttonTU
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'invisible'))
          // show button konfirmasi (Ubah data ... & Batal)
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'bg-primary', ' ', 'visible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnConfirmText', 'Ubah data ...')
          // enable semua field
          formik.setFieldValue('fieldDisable', false)
        }
        return (
          <div className="container">
            <Form className="w-100">
              <Row className="fw-bold mb-2">
                <Field name="idUserMaint" type="hidden"/>
                <Field name="idGereja" type="hidden"/>
                <Field name="fieldDisableKey" type="hidden"/>
                <Field name="fieldDisable" type="hidden"/>
                <Field name="menuClass" type="hidden"/>
                <Field name="btnTambahClass" type="hidden"/>
                <Field name="btnUbahClass" type="hidden"/>
                <Field name="btnMenuDisable" type="hidden"/>
                <Field name="btnConfirmClass" type="hidden"/>
                <Field name="btnCancelClass" type="hidden"/>
                <Field name="btnConfirmText" type="hidden"/>
                <Col md={9} sm={6} xs={6}>
                  <p className="mb-2">
                    <span className="text-primary fw-bold"> 
                      <i className="bi bi-arrow-right-square-fill"></i> 
                    </span> &nbsp; 
                    Nama Jenis Program &nbsp; : &nbsp; <strong>{formik.values.namaJenisProgram}</strong> 
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                  </p>
                </Col>
                <Col md={3} sm={3} xs={3}>
                  <Field name="menuScreen" disabled={true} className={formik.values.menuClass}/>
                </Col>
              </Row>
              <hr/>
              <Row className="fw-bold">
                <Col className="form-group mt-2 col-2 justify-content-start">
                  <label className="text-primary w-20" htmlFor="idJenisProgram"> Id </label>
                  <Field 
                    className="text-center w-40" 
                    id="idJenisProgram" 
                    name="idJenisProgram" 
                    disabled={formik.values.fieldDisableKey} 
                    placeholder="id"
                  />
                  <ErrorMessage 
                    name="idJenisProgram" 
                    component={TextError} 
                    className="invalid-feedback text-warning" 
                  />
                </Col>
                <Col className="form-group mt-2 ps-0 col-8 justify-content-start">
                  <label className="text-primary w-10" htmlFor="namaJenisProgram"> Nama </label>
                  <Field 
                    className="text-start w-80" 
                    id="namaJenisProgram" 
                    name="namaJenisProgram" 
                    disabled={formik.values.fieldDisable} 
                    placeholder="Nama jenis program"
                  />
                  <ErrorMessage 
                    name="namaJenisProgram" 
                    component={TextError} 
                    className="invalid-feedback text-warning" 
                  />
                </Col>
                <Col className="form-group mt-2 col-2 justify-content-end">
                  <label className="text-primary w-70" htmlFor="jenisLaporan"> Jenis Laporan </label>
                  <Field 
                    className="text-center w-30" 
                    id="jenisLaporan" 
                    name="jenisLaporan" 
                    disabled={formik.values.fieldDisable} 
                  />
                  <ErrorMessage name="jenisLaporan" component={TextError} className="invalid-feedback text-warning" />
                </Col>
              </Row>
              <hr/>
              <Row>
                <Col className="form-group mt-3 col-12">
                  <label className="text-primary w-15" htmlFor="keterangan"> Keterangan </label>
                  <Field 
                    className="text-start w-70" 
                    id="keterangan" 
                    name="keterangan" 
                    disabled={formik.values.fieldDisable}
                  />
                </Col>
              </Row>
              <hr/>
              <Row className="fw-bold small">
                <p>
                  <span className="text-warning"> <i className="bi bi-x-circle-fill"></i> </span> &nbsp; 
                    Maintenance : {formik.values.userActivity} 
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                  <span className="text-warning fw-bold"> <i className="bi bi-arrow-right-circle"></i> </span> &nbsp; 
                    Id User Maint : {formik.values.idUser} - {formik.values.namaUser} 
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="text-warning fw-bold"> <i className="bi bi-arrow-right-circle"></i> </span> &nbsp; 
                    Tanggal Maintenance : {moment(formik.values.tanggalSystem).format('LLLL')} 
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
              </Row>
              <Row className="fw-bold small">
                <Col className="justify-content-start">
                  <button 
                    type="button" 
                    className={formik.values.btnConfirmClass}
                    disabled={
                      (
                        parseInt(formik.values.idJenisProgram, 10) > 0 && 
                        parseInt(formik.values.idJenisProgram, 10) < 256 &&
                        formik.values.namaJenisProgram 
                      ) 
                        ? false : true}
                    onClick={() => onClickConfirm(formik.values)}
                  > {formik.values.btnConfirmText} </button>
                  &nbsp;&nbsp;&nbsp;
                  <button 
                    type="button" 
                    className={formik.values.btnCancelClass} 
                    onClick={() => formik.values.menuScreen === "UPDATE" ? screenIdle() : onClickHandleEdit(false)}
                  > Batal </button>
                </Col>
                <Col className="text-end">
                  <button 
                    type="button" 
                    className={formik.values.btnTambahClass} 
                    disabled={formik.values.btnMenuDisable}
                    onClick={() => clickTambah()}
                  > Tambah </button>
                  &nbsp;&nbsp;&nbsp;
                  <button 
                    type="button" 
                    className={formik.values.btnUbahClass} 
                    disabled={formik.values.btnMenuDisable} 
                    onClick={() => clickUbah()}
                  > Ubah </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button 
                    type="button"
                    className='btn btn-outline-secondary btn-sm fst-italic fw-bold'
                    onClick={() => clickExit()}
                  > Keluar </button>
                </Col>
              </Row>
              <hr/>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

export default EditJenisProgram