import React, { useContext } from 'react'
import useAuth from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import useLogout from '../../hooks/useLogout'

import './navbar.scss'
import { DarkModeContext } from '../../context/darkModeContext'
// ganti avatar sesuai dengan user
import avatar from '../../images/user.png'
import Logo from '../../images/Logo'

import { TbBuildingChurch } from 'react-icons/tb'

const imgLogo = Logo()

const Navbar = () => {
  const { dispatch } = useContext(DarkModeContext)
  const { auth } = useAuth()
  const navigate = useNavigate()
  const logout = useLogout()
  
  const signOut = async () => {
    await logout()
    navigate('/login', { replace: true })
  } 

  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="logoGKJ">
          <img src={imgLogo} width="6%" className="shadow rounded-circle img-thumbnail border-primary border-5" alt="GKJ"></img>
          <div className="bako fw-bold text-primary ms-2">
            <TbBuildingChurch size={37} className="text-info" />
            &nbsp; &nbsp;
            GKJ {auth.authNamaGereja}
          </div>
          <div className="bako fw-bold text-primary ms-2">
            <i className="bi bi-diagram-3-fill text-warning" style={{ fontSize: 25 }}></i>
            &nbsp; &nbsp;
            {auth.authNamaBakowi}
          </div>
        </div>
        <div className="items">
          <div className="item">
            <i className="bi bi-moon-fill" onClick={() => dispatch({ type: "TOGGLE" })}></i>
          </div>
          <div className="item">
            <i className="bi bi-door-open" onClick={signOut}></i>
          </div>
          <div className="item">
            <img
              src={avatar}
              alt=""
              className="avatar"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
