import React, { useEffect, useState } from "react"
import useAuth from '../../hooks/useAuth'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

import "./maintakunkasbank.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Table from "../../components/table/Table"
import { usePerkiraanList } from '../../components/data/UseDataComponents'
import EditAkunKasBank from "./EditAkunKasBank"

import { Row, Container } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'

import Logo from '../../images/Logo'
// import { EditProgramValidationSchema } from "../../components/data/ValidationSchema"

const imgLogo = Logo()

toast.configure()

const MaintAkunKasBank = () => {
  
  window.scrollTo(0, 0)
  const { auth } = useAuth()
  
  // const apiUrlAG = "/api/ag-akunkasbank"
  const apiUrlAG = auth.API_URL + "/ag-akunkasbank"

  const idGereja = auth.authIdGereja
  const idBakowi = auth.authIdBakowi
  const idUser = auth.authIdUser
  const [nomorIdAkunKasBank, setNomorIdAkunKasBank] = useState([])
  const [editDetail, setEditDetail] = useState(false)
  const [adaData, setAdaData] = useState(false)
  
  const axiosPrivate = useAxiosPrivate()

  const fetchAkunKasBank = (idGereja, idBakowi) => {
    return axiosPrivate(`${apiUrlAG}/akunkasbank/${idGereja}/${idBakowi}`)
  }

  const useAkunKasBankList = (idGereja, idBakowi, onSuccess, onError) => {
      return useQuery(
          ['akunkasbank', idGereja, idBakowi],
          () => fetchAkunKasBank(idGereja, idBakowi), 
          {
              onSuccess,
              onError: () => {
                toast.error('Tidak dapat meng-akses data !', {
                  position: toast.POSITION.BOTTOM_CENTER,
                  autoClose: 2000
                })
              },
              select: (data) => {
                  const akunKasBankList = data.data[0]
                  return akunKasBankList
              }
          }
      )
  }

  const { data: akunKasBankList, isLoading, isSuccess, isError } = useAkunKasBankList(idGereja, idBakowi)
  const { data: perkiraanList } = usePerkiraanList(idGereja)
  
  useEffect(() => {
    setAdaData
      ( akunKasBankList && akunKasBankList[0]?.idGereja === 'nf' 
        ? false : true
      )
  }, [akunKasBankList])
  
  const akunKasBankTableHead = [
    // posisi judul [L]eft/[C]enter/[R]ight - nama judul
    'C-ID',
    'L-Nama Akun',
    'C-Tipe',
    'C-No. Perkiraan',
    'L-Nama Perkiraan',
    'L-Nama Bank',
    'L-No. Rek.',
    'L-Nama di Rekening',
    'L-Keterangan',
  ]
  const renderHead = (item, index) => (
    <th 
      key={index} 
      className={item.substring(0,1) === 'C' 
        ? 'text-center' 
        : (item.substring(0,1) === 'R' 
          ? 'text-end' 
          : 'text-start')
      }
    >
      {item.substring(2)}
    </th>
  )

  const renderBody = (item, index) => (
    <tr 
      onClick={() => onClickHandleEdit(
        [item],
        true
      )} 
      key={index}
    >
      <td className="text-center">{item.idAkunKasBank}</td>
      <td>{item.namaAkunKasBank}</td>
      <td>{item.tipeAkunKasBank === 'B' ? 'Bank' : (item.tipeAkunKasBank === 'K' ? 'Kas' : 'Tipe akun salah !')}</td>
      <td className="text-center">{item.idPerkiraan}</td>
      <td>{item.namaPerkiraan}</td>
      <td>{item.namaBank}</td>
      <td>{item.nomorRekening}</td>
      <td>{item.namaDiRekening}</td>
      <td>{item.keterangan}</td>
    </tr>
  )

  const onClickEntry = () => {
    onClickHandleEdit('', true)
  }

  const onClickHandleEdit = (noIdAkunKasBank, editDisplay) => {
    setNomorIdAkunKasBank(noIdAkunKasBank)
    setEditDetail(editDisplay)
  }

  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <h3 className="ms-4 my-3"> Maintenance Tabel Akun Kas dan Bank </h3>
        {!editDetail && 
          <button 
            type="button" 
            className="ms-4 mb-2 btn btn-sm btn-outline-primary fst-italic fw-bold" 
            onClick={() => onClickEntry()}
          > 
            <span className="bi bi-plus-circle-dotted mx-3" style={{fontSize: "21px"}}> Mulai Entry </span>
          </button>
        }
        <Container className="w-100">
          { isLoading && !editDetail && (
            <>
              <div className="w-100 d-flex mt-3 justify-content-center">
                <Row className="w-25 justify-content-center">
                  <img 
                    src={imgLogo} 
                    width="15%" 
                    className="rounded-circle img-thumbnail border-danger" 
                    alt="GKJ"
                  ></img>
                </Row>
              </div>
              <div className="w-100 mt-3 justify-content-center">
                <Row className="text-center">
                  <strong> Loading ... </strong>
                </Row>
                <Row className="mb-3 justify-content-center">
                  <div className="spinner-grow mt-2 text-primary" role="status" aria-hidden="true"></div>
                </Row>
              </div>
            </>
          )}
          { isError && (<div> Tabel akun kas bank tidak dapat diakses </div>)}
          { isSuccess && (
            <div>
              <Row>
                {editDetail &&
                  <>
                    <hr />
                    <EditAkunKasBank
                      onClickHandleEdit = {onClickHandleEdit}
                      idGereja = {idGereja}
                      idBakowi = {idBakowi}
                      idUserMaint = {idUser}
                      nomorIdAkunKasBank = {nomorIdAkunKasBank[0]}
                      perkList = {perkiraanList.filter((item) => item.Tipe === 'A')}
                    >
                    </EditAkunKasBank>
                  </>
                }
              </Row>
              <Row> 
                { adaData 
                  ? 
                    <Table 
                      onClickHandleEdit = {onClickHandleEdit}
                      headData={akunKasBankTableHead}
                      renderHead={renderHead}
                      bodyData={akunKasBankList.filter((item) => item.idGereja !== 'nf')}
                      renderBody={renderBody}
                    />
                  : 
                    (<div> Belum ada data </div>)
                }
              </Row>
            </div>
          )}
        </Container>
      </div>
    </div>
  )
}

export default MaintAkunKasBank