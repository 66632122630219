import React, { useEffect, useState } from "react"
import useAuth from '../../hooks/useAuth'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

import "./maintkelompoktransaksi.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Table from "../../components/table/Table"
import { usePerkiraanList } from '../../components/data/UseDataComponents'
import EditKelompokTransaksi from "./EditKelompokTransaksi"

import { Row, Container } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'

import Logo from '../../images/Logo'

const imgLogo = Logo()

toast.configure()

const MaintKelompokTransaksi = () => {
  
  window.scrollTo(0, 0)
  const { auth } = useAuth()

  // const apiUrlAG = "/api/ag-kelompoktransaksi"
  const apiUrlAG = auth.API_URL + "/ag-kelompoktransaksi"

  const idGereja = auth.authIdGereja
  const idBakowi = auth.authIdBakowi
  const idUser = auth.authIdUser
  const [nomorIdKelompokTransaksi, setNomorIdKelompokTransaksi] = useState([])
  const [editDetail, setEditDetail] = useState(false)
  const [adaData, setAdaData] = useState(false)
  
  const axiosPrivate = useAxiosPrivate()

  const fetchKelompokTransaksi = (idGereja, idBakowi) => {
    return axiosPrivate(`${apiUrlAG}/kelompoktransaksi/${idGereja}/${idBakowi}`)
  }

  const useKelompokTransaksiList = (idGereja, idBakowi, onSuccess, onError) => {
    return useQuery(
      ['kelompoktransaksi', idGereja, idBakowi], 
      () => fetchKelompokTransaksi(idGereja, idBakowi), 
      {
        onSuccess,
        onError: () => {
          toast.error('Tidak dapat meng-akses data !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        select: (data) => {
          const kelompokTransaksiList = data.data[0]
          return kelompokTransaksiList
        }
      }
    )
  }

  const { data: kelompokTransaksiList, isLoading, isSuccess, isError } = useKelompokTransaksiList(idGereja, idBakowi)
  const { data: perkiraanList } = usePerkiraanList(idGereja)

  useEffect(() => {
    setAdaData
      ( kelompokTransaksiList && kelompokTransaksiList[0]?.idGereja === 'nf' 
        ? false : true
      )
  }, [kelompokTransaksiList])

  const kelompokTransaksiTableHead = [
    // posisi judul [L]eft/[C]enter/[R]ight - nama judul
    'C-ID',
    'L-Nama Kelompok',
    'L-Tipe',
    'C-No. Perkiraan',
    'L-Nama Perkiraan',
    'L-Keterangan',
  ]
  const renderHead = (item, index) => (
    <th 
      key={index} 
      className={item.substring(0,1) === 'C' 
        ? 'text-center' 
        : (item.substring(0,1) === 'R' 
          ? 'text-end' 
          : 'text-start')
      }
    >
      {item.substring(2)}
    </th>
  )

  const renderBody = (item, index) => (
    <tr 
      onClick={() => onClickHandleEdit(
        [item], 
        true
      )} 
      key={index}
    >
      <td className="text-center">{item.idKelompokTransaksi}</td>
      <td>{item.namaKelompokTransaksi}</td>
      <td>{item.tipe === 'T' ? 'Penerimaan' : (item.tipe === 'K' ? 'Pengeluaran' : 'Tipe salah !')}</td>
      <td className="text-center">{item.idPerkiraan}</td>
      <td>{item.namaPerkiraan}</td>
      <td>{item.keterangan}</td>
    </tr>
  )

  const onClickEntry = () => {
    onClickHandleEdit('', true)
  }

  const onClickHandleEdit = (noIdKelompokTransaksi, editDisplay) => {
    setNomorIdKelompokTransaksi(noIdKelompokTransaksi)
    setEditDetail(editDisplay)
  }

  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <h3 className="ms-4 my-3"> Maintenance Tabel Kelompok Transaksi </h3>
        {!editDetail && 
          <button 
            type="button" 
            className="ms-4 mb-2 btn btn-sm btn-outline-primary fst-italic fw-bold" 
            onClick={() => onClickEntry()}
          > 
            <span className="bi bi-plus-circle-dotted mx-3" style={{fontSize: "21px"}}> Mulai Entry </span>
          </button>
        }
        <Container className="w-100">
          { isLoading && !editDetail && (
            <>
              <div className="w-100 d-flex mt-3 justify-content-center">
                  <Row className="w-25 justify-content-center">
                      <img 
                        src={imgLogo} 
                        width="15%" 
                        className="rounded-circle img-thumbnail border-danger" 
                        alt="GKJ"
                      ></img>
                  </Row>
              </div>
              <div className="w-100 mt-3 justify-content-center">
                  <Row className="text-center">
                      <strong> Loading ... </strong>
                  </Row>
                  <Row className="mb-3 justify-content-center">
                      <div className="spinner-grow mt-2 text-primary" role="status" aria-hidden="true"></div>
                  </Row>
              </div>
            </>
          )}
          { isError && (<div> Tabel kelompok transaksi tidak dapat diakses </div>)}
          { isSuccess && (
            <div>
              <Row>
                {editDetail &&
                  <EditKelompokTransaksi
                    onClickHandleEdit = {onClickHandleEdit}
                    idGereja = {idGereja}
                    idBakowi = {idBakowi}
                    idUserMaint = {idUser}
                    nomorIdKelompokTransaksi = {nomorIdKelompokTransaksi[0]}
                    perkList = {perkiraanList.filter((item) => item.Tipe === 'P' || item.Tipe === 'B')}
                  >
                  </EditKelompokTransaksi>
                }
              </Row>
              <Row> 
                { adaData 
                  ? 
                    <Table 
                      onClickHandleEdit = {onClickHandleEdit}
                      headData={kelompokTransaksiTableHead}
                      renderHead={renderHead}
                      bodyData={kelompokTransaksiList.filter((item) => item.idGereja !== 'nf')}
                      renderBody={renderBody}
                    />
                  : 
                    (<div> Belum ada data </div>)
                }
              </Row>
            </div>
          )}
        </Container>
      </div>
    </div>
  )
}

export default MaintKelompokTransaksi