import React, { useState } from "react"
import useAuth from '../../hooks/useAuth'

import "./anggarankegiatan.scss"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Row, Col, Container, Table } from 'react-bootstrap'

import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import { useAnggaranKegiatanList } from '../../components/data/UseDataLaporan'

import NumberFormat from 'react-number-format'
import Logo from '../../images/Logo'

const imgLogo = Logo()

let thn = 0

const colStyle = {
  backgroundColor: '#004b48',
  color: "#00ffff",
  fontSize: "11px",
}

const AnggaranKegiatan = () => {
  const { auth } = useAuth()
  const idGereja = auth.authIdGereja
  // const namaGereja = auth.authNamaGereja
  const idBakowi = auth.authIdBakowi
  // const namaBadanKomisi = auth.authNamaBakowi
  const tahunMulai = auth.authTahunMulai
  // const idUser = auth.authIdUser

  const [thnTrn, setThnTrn] = useState((new Date()).getFullYear())
  const [terimaKeluar, setTerimaKeluar] = useState('T')
  const [keyFromButton, setKeyFromButton] = useState({tahun: thnTrn, tipeTK: terimaKeluar})

  window.scrollTo(0, 0)

  const onProses = () => {
    setKeyFromButton({ ...keyFromButton, tahun: thnTrn, tipeTK: terimaKeluar})
  }

  const { data: anggaranKegiatanList, isLoading, isSuccess, isError } = useAnggaranKegiatanList(idGereja, idBakowi, thnTrn, terimaKeluar)

  const onMinus = () => {
    thn = thnTrn.valueOf()
    if (thn > tahunMulai) setThnTrn(thn - 1)
  }
  const onPlus = () => {
    thn = thnTrn.valueOf()
    if (thn < (new Date()).getFullYear()) setThnTrn(thn - 1 + 2)
  }

  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <h3 className="ms-4 mt-3"> Anggaran Kegiatan </h3>
        <Container className="w-100">
          <Formik
            initialValues={{ tahun: thnTrn, tipeTK: terimaKeluar }}
            // validationSchema={TahunBulanValidationSchema}
            enableReinitialize
          >
            {formik => {
              return (
                <Container className="w-100">
                  <Form>
                    <hr/>
                    <Row className="text-center h-auto">
                      <Col className='form-group text-end col-4'>
                        <button 
                          type="button" 
                          className="btn btn-outline-secondary text-dark bg-white d-inline mb-1"
                          style={{'width': '75px'}}
                        > 
                          Tahun 
                        </button>
                        <div className="input-group d-inline">
                          <button type="button" 
                            className="btn bi bi-arrow-down-circle mb-1 bg-white" 
                            style={{'width': '40px', 'height': '37px'}}
                            onClick={() => onMinus()}
                          >
                          </button>
                          <Field id="tahun" name="tahun" type="text" 
                            onChange={e => setThnTrn(e.target.value)} 
                            className="text-center d-inline border-2 border-primary" 
                            style={{'width': '65px', 'height': '37px'}}
                            disabled={true}
                          />
                          <ErrorMessage name="tahun" component="div" className="invalid-feedback" />
                          <button type="button" 
                            className="btn bi bi-arrow-up-circle mb-1 bg-white" 
                            style={{'width': '40px', 'height': '37px'}}
                            onClick={() => onPlus()}
                          >
                          </button>
                        </div>
                      </Col>
                      <Col className='form-group text-center col-3'>
                        <span className="small">Pilih salah satu:</span>
                        <div onChange={e => setTerimaKeluar(e.target.value)}>
                          <input className="form-check-input small" type="radio" value="T" name="tipeTK"/> 
                            <span className="small ms-1">Penerimaan</span> &nbsp; &nbsp; 
                          <input className="form-check-input small ms-2" type="radio" value="K" name="tipeTK"/> 
                            <span className="small ms-1">Pengeluaran</span>
                        </div>
                      </Col>
                      <Col className='form-group text-start col-3'>
                        <button 
                          type="button" 
                          onClick={() => onProses()} 
                          className="btn btn-primary d-inline mb-1"
                          style={{'width': '75px', 'height': '37px'}}
                        > 
                          Proses 
                        </button>
                      </Col>
                    </Row>
                  </Form>
                  <hr/>
                  {anggaranKegiatanList ?
                    (anggaranKegiatanList.length === 1 
                      ? "Belum ada data" 
                      : <Row> 
                          <Col>
                            <Row className="justify-content-center mt-0 pt-0">
                              <p className="text-center fw-bold"> ANGGARAN KEGIATAN TAHUN {thnTrn} </p>
                            </Row>
                            { isLoading &&
                              <>
                                <div className="w-100 d-flex mt-3 justify-content-center">
                                  <Row className="w-20 justify-content-center">
                                    <img src={imgLogo} width="15%" className="rounded-circle img-thumbnail border-danger" alt="GKJ"></img>
                                  </Row>
                                </div>
                                <div className="w-100 mt-3 justify-content-center">
                                  <Row className="text-center">
                                    <strong> Loading ... </strong>
                                  </Row>
                                  <Row className="mb-3 justify-content-center">
                                    <div className="spinner-grow mt-2 text-primary" role="status" aria-hidden="true"></div>
                                  </Row>
                                </div>
                                <hr/>
                              </>
                            }
                            { isError && (<div> Data laporan anggaran kegiatan tidak dapat diakses </div>)}
                            { isSuccess &&
                              <>
                                <Table striped bordered hover className="small w-100" size="sm" responsive="lg">
                                  <thead style={colStyle}> 
                                    <tr> 
                                      <th rowSpan='4' className='text-center col-2 align-middle'> Program </th> 
                                      <th rowSpan='4' className='text-center col-2 align-middle'> Kegiatan </th> 
                                      <th rowSpan='4' className='text-end col-1 align-middle'> Anggaran </th> 
                                      <th colSpan='4' className='text-center col-4'> Bulan Pencairan Dana </th> 
                                      <th rowSpan='3' colSpan='2' className='text-center col-2 align-middle'> 
                                        Realisasi 
                                      </th> 
                                      <th rowSpan='4' className='text-end col-1 align-middle'> Sisa Anggaran </th> 
                                    </tr> 
                                    <tr style={colStyle}> 
                                      <th className='text-end'> Januari </th> 
                                      <th className='text-end'> Pebruari </th> 
                                      <th className='text-end'> Maret </th> 
                                      <th className='text-end'> April </th> 
                                    </tr> 
                                    <tr> 
                                      <th className='text-end'> Mei </th> 
                                      <th className='text-end'> Juni </th> 
                                      <th className='text-end'> Juli </th> 
                                      <th className='text-end'> Agustus </th> 
                                    </tr> 
                                    <tr style={colStyle}> 
                                      <th className='text-end'> September </th> 
                                      <th className='text-end'> Oktober </th> 
                                      <th className='text-end'> Nopember </th> 
                                      <th className='text-end'> Desember </th> 
                                      <th className='text-end'> Nilai </th> 
                                      <th className='text-center'> % </th> 
                                    </tr> 
                                  </thead> 
                                  <tbody className="fw-bold dark-background" style={{fontSize: "10px"}}> 
                                    {anggaranKegiatanList && 
                                      anggaranKegiatanList
                                        .filter((item) => item.idGereja !== 'nf')
                                        .map((anggaran, index) => ( 
                                        <> 
                                          <tr key={index}> 
                                            <td rowSpan='3' className="text-start bg-white"> {anggaran.namaProgram} </td> 
                                            <td rowSpan='3' className="text-start bg-white"> {anggaran.namaKegiatan} </td> 
                                            <td rowSpan='3' className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','} 
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={anggaran.anggaran} 
                                              /> 
                                            </td> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','} 
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={anggaran.jan} 
                                              /> 
                                            </td> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','} 
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={anggaran.peb} 
                                              /> 
                                            </td> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','} 
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={anggaran.mar} 
                                              /> 
                                            </td> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','} 
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={anggaran.apr} 
                                              /> 
                                            </td> 
                                            <td rowSpan='3' className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','} 
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={anggaran.realisasi} 
                                              /> 
                                            </td> 
                                            <td rowSpan='3' className="text-center bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','} 
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={anggaran.pctRealisasi} 
                                              /> 
                                            </td> 
                                            <td rowSpan='3' className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','} 
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={anggaran.sisaAnggaran} 
                                              /> 
                                            </td> 
                                          </tr> 
                                          <tr key={index}> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','} 
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={anggaran.mei} 
                                              /> 
                                            </td> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','} 
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={anggaran.jun} 
                                              /> 
                                            </td> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','} 
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={anggaran.jul} 
                                              /> 
                                            </td> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','} 
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={anggaran.agu} 
                                              /> 
                                            </td> 
                                          </tr> 
                                          <tr key={index}> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','} 
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={anggaran.sep} 
                                              /> 
                                            </td> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','} 
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={anggaran.okt} 
                                              /> 
                                            </td> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','} 
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={anggaran.nop} 
                                              /> 
                                            </td> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','} 
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={anggaran.des} 
                                              /> 
                                            </td> 
                                          </tr> 
                                        </>
                                    ))}
                                  </tbody>
                                </Table>
                              </>
                            }
                          </Col>
                          <Row className="justify-content-start tglfootnote footnote mt-0">
                              <div className="fst-italic text-start text-muted small"> gkj-ag-wr-2022 </div>
                          </Row>                                
                        </Row>
                    )
                  :
                    <Row>
                      <div className="w-100 mt-5 text-center">
                        <img 
                          src={imgLogo} 
                          width="15%" 
                          className="rounded-circle img-thumbnail border-danger" 
                          alt="GKJ"
                        ></img>
                      </div>
                    </Row>
                  }
                </Container>
              )
            }} 
          </Formik> 
        </Container>
      </div>
    </div>
  )
}

export default AnggaranKegiatan