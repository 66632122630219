import React, { useEffect, useState } from "react"
import useAuth from '../../hooks/useAuth'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

import "./maintprogram.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Table from "../../components/table/Table"
import { useJnsPrgList, useWargaList } from '../../components/data/UseDataComponents'
import EditProgram from "./EditProgram"

import { Row, Container } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'

import NumberFormat from 'react-number-format'
import Logo from '../../images/Logo'
import moment from 'moment'

const imgLogo = Logo()

// ================= auth:
// accessToken:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyRW1haWwiOiJuQHIuY29tIiwiaWF0IjoxNjYzMTc2OTU1LCJleHAiOjE2NjMxNzcyNTV9.qpAw6HmrCzP8y8a6WkRFAKD4_oM0fOWrTsA4CHpdC0M"
// authAuthenticated: true
// authEmail: "n@r.com"
// authIdBakowi: undefined
// authIdGereja: 159
// authIdUser: 1
// authInisial: "NS"
// authLevelUser: 1
// authNamaBakowi: undefined
// authNamaGereja: "Ebenhaezer"
// authNamaUser: "nomat seti"
// authTahunAkhir: 2022
// authTahunMulai: 2021
// authRoles: null
// authLevelAksesAG
// authRoleAksesAG
// -----------------

toast.configure()

const MaintProgram = () => {
  window.scrollTo(0, 0)
  const { auth } = useAuth()
  
  // const apiUrlAG = "/api/ag-program"
  const apiUrlAG = auth.API_URL + "/ag-program"

  const idGereja = auth.authIdGereja
  const idBakowi = auth.authIdBakowi
  const idUser = auth.authIdUser
  const [nomorIdProgram, setNomorIdProgram] = useState([])
  const [editDetail, setEditDetail] = useState(false)
  const [adaData, setAdaData] = useState(false)
  
  const axiosPrivate = useAxiosPrivate()

  const fetchProgram = (idGereja, idBakowi) => {
    return axiosPrivate.get(`${apiUrlAG}/program/${idGereja}/${idBakowi}`)
  }

  const useProgramList = (idGereja, idBakowi, onSuccess, onError) => {
    return useQuery(
      ['program', idGereja, idBakowi], 
      () => fetchProgram(idGereja, idBakowi), 
      {
        onSuccess,
        onError: () => {
          toast.error('Tidak dapat meng-akses data !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        select: (data) => {
          const programList = data.data[0]
          return programList
        }
      }
    )
  }

  const { data: programList, isLoading, isSuccess, isError } = useProgramList(idGereja, idBakowi)
  const { data: wargaList } = useWargaList(idGereja)
  const { data: jnsPrgList } = useJnsPrgList(idGereja)
  
  useEffect(() => {
    setAdaData
      ( programList && programList[0]?.idGereja === 'nf' 
        ? false : true
      )
  }, [programList])
  
  const programTableHead = [
    // posisi judul [L]eft/[C]enter/[R]ight - nama judul
    'C-ID',
    'L-Nama Program',
    'L-Jenis',
    'L-Kategori',
    'R-Dana Dukungan',
    'R-# Anggaran',
    'R-# Kegiatan',
    'R-# Bobot',
    'L-Rencana Mulai',
    'L-Penanggungjawab',
    'L-Status',
  ]
  const renderHead = (item, index) => (
    <th 
      key={index} 
      className={item.substring(0,1) === 'C' 
        ? 'text-center' 
        : (item.substring(0,1) === 'R' 
          ? 'text-end' 
          : 'text-start')
      }
    >
      {item.substring(2)}
    </th>
  )

  const renderBody = (item, index) => (
    <tr 
      onClick={() => onClickHandleEdit(
        [item],
        true
      )} 
      key={index}
    >
      <td className="text-center">{item.idProgram}</td>
      <td>{item.namaProgram}</td>
      <td>{item.jenisProgram}</td>
      <td>{item.kategori === 'R' ? 'Rutin' : (item.kategori === 'N' ? 'Non Rutin' : 'Kategori salah !')}</td>
      <td className="text-end">
        <NumberFormat
          displayType={"text"}
          thousandSeparator={"."}
          decimalSeparator={","}
          decimalScale={2}
          fixedDecimalScale={true}
          value={item.danaDukunganMajelis}
        />
      </td>
      <td className="text-end">
        <NumberFormat
          displayType={"text"}
          thousandSeparator={"."}
          decimalSeparator={","}
          decimalScale={2}
          fixedDecimalScale={true}
          value={item.jumlahAnggaran}
        />
      </td>
      <td className="text-end">
        <NumberFormat
          displayType={"text"}
          thousandSeparator={"."}
          decimalSeparator={","}
          decimalScale={0}
          fixedDecimalScale={true}
          value={item.jumlahKegiatan}
        />
      </td>
      <td className="text-end">
        <NumberFormat
          displayType={"text"}
          thousandSeparator={"."}
          decimalSeparator={","}
          decimalScale={0}
          fixedDecimalScale={true}
          value={item.jumlahBobot}
        />
      </td>
      <td>{item.tanggalRencanaMulai && moment(item.tanggalRencanaMulai).format('DD-MMMM-YYYY')}</td>
      <td>{item.namaPenanggungjawab}</td>
      <td>{item.statusProgram}</td>
    </tr>
  )

  const onClickEntry = () => {
    onClickHandleEdit('', true)
  }

  const onClickHandleEdit = (noIdProgram, editDisplay) => {
    setNomorIdProgram(noIdProgram)
    setEditDetail(editDisplay)
  }

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <h3 className="ms-4 my-3"> Maintenance Program </h3>
        {!editDetail && 
          <button 
            type="button" 
            className="ms-4 mb-2 btn btn-sm btn-outline-primary fst-italic fw-bold" 
            onClick={() => onClickEntry()}
          > 
            <span className="bi bi-plus-circle-dotted mx-3" style={{fontSize: "21px"}}> Mulai Entry </span>
          </button>
        }
        <Container className="w-100">
          { isLoading && !editDetail && (
            <>
              <div className="w-100 d-flex mt-3 justify-content-center">
                  <Row className="w-25 justify-content-center">
                      <img 
                        src={imgLogo} 
                        width="15%" 
                        className="rounded-circle img-thumbnail border-danger" 
                        alt="GKJ"
                      ></img>
                  </Row>
              </div>
              <div className="w-100 mt-3 justify-content-center">
                  <Row className="text-center">
                      <strong> Loading ... </strong>
                  </Row>
                  <Row className="mb-3 justify-content-center">
                      <div className="spinner-grow mt-2 text-primary" role="status" aria-hidden="true"></div>
                  </Row>
              </div>
            </>
          )}
          { isError && (<div> Tabel program tidak dapat diakses </div>)}
          { isSuccess && (
            <div>
              <Row>
                {editDetail &&
                  <>
                    <hr />
                    <h1>{editDetail}</h1>
                    <EditProgram
                      onClickHandleEdit = {onClickHandleEdit}
                      idGereja = {idGereja}
                      idBakowi = {idBakowi}
                      idUserMaint = {idUser}
                      nomorIdProgram = {nomorIdProgram[0]}
                      jnsPrgList = {jnsPrgList}
                      wrgList = {wargaList}
                    >
                    </EditProgram>
                  </>
                }
              </Row>
              <Row> 
                { adaData 
                  ? 
                    <Table 
                      onClickHandleEdit = {onClickHandleEdit}
                      headData={programTableHead}
                      renderHead={renderHead}
                      bodyData={programList.filter((item) => item.idGereja !== 'nf')}
                      renderBody={renderBody}
                    />
                  : 
                    (<div> Belum ada data </div>)
                }
              </Row>
            </div>
          )}
        </Container>
      </div>
    </div>
  )
}

export default MaintProgram