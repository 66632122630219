import axios from "../api/axios"
import useAuth from "./useAuth"

const useLogout = () => {
    const { setAuth } = useAuth()

    const logout = async () => {
        setAuth({})
        try {
            // const response = await axios('/api/auth/logout', {
            const response = await axios('https://ag.gkj.web.id/api/auth/logout', {
                withCredentials: true
            })
            console.log('res: ', response)
        } catch (err) {
            console.error('err: ', err)
        }
    }
    return logout
}

export default useLogout