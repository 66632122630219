import React from 'react'

import NumberFormat from "react-number-format"

import "./featured.scss"

import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"

const Featured = ({pctProgram, amtProgram, pctKegiatan, amtKegiatan, pctAnggaran, amtAnggaran, pctDukungan, amtDukungan}) => {

  return (
    <div className="featured">
      <div className="top">
        <h1 className="title">Program Pelayanan</h1>
        <i className="bi bi-three-dots-vertical"></i>
      </div>
      <div className="bottom">
        <div className="featuredChart">
          <div className="featuredChart1">
            <p className="title text-center">Program</p>
            <CircularProgressbar value={pctProgram} text={`${pctProgram}%`} strokeWidth={15} />
            <p className="amount text-center mt-1">
              <NumberFormat
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={0}
                fixedDecimalScale={true}
                value={amtProgram}
              />
            </p>
          </div>
          <div className="featuredChart2">
            <p className="title text-center">Kegiatan</p>
            <CircularProgressbar value={pctKegiatan} text={`${pctKegiatan}%`} strokeWidth={15} />
            <p className="amount text-center mt-1">
              <NumberFormat
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={0}
                fixedDecimalScale={true}
                value={amtKegiatan}
              />
            </p>
          </div>
        </div>
        <hr />
        <div className="featuredChart mt-4">
          <div className="featuredChart1">
            <p className="title text-center">Anggaran Program</p>
            <CircularProgressbar 
              value={pctAnggaran} 
              text={`${pctAnggaran}%`} 
              strokeWidth={15}
              styles={buildStyles({
                textColor: "red",
                pathColor: "turquoise",
                trailColor: "gold"
              })}
            />
            <p className="amount text-center mt-1">
              {"Rp. "}
              <NumberFormat
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={0}
                fixedDecimalScale={true}
                value={amtAnggaran}
              />
            </p>
          </div>
          <div className="featuredChart2">
            <p className="title text-center px-0 mx-0">Dukungan Majelis</p>
            <CircularProgressbar 
              value={pctDukungan} 
              text={`${pctDukungan}%`} 
              strokeWidth={15}
              styles={buildStyles({
                textColor: "red",
                pathColor: "green",
                trailColor: "gold"
              })}
            />
            <p className="amount text-center mt-1">
              {"Rp. "}
              <NumberFormat
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                decimalScale={0}
                fixedDecimalScale={true}
                value={amtDukungan}
              />
            </p>
          </div>
        </div>
        {/* <div className="summary">
          <div className="item">
            <div className="itemTitle">Target</div>
            <div className="itemResult negative">
              <i className="bi bi-caret-down"></i>
              <div className="resultAmount">$12.4k</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Last Week</div>
            <div className="itemResult positive">
              <i className="bi bi-caret-up"></i>
              <div className="resultAmount">$12.4k</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Last Month</div>
            <div className="itemResult positive">
              <i className="bi bi-caret-up"></i>
              <div className="resultAmount">$12.4k</div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Featured;
