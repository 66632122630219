import React from "react"
import NumberFormat from "react-number-format"
import { Link } from "react-router-dom"
import "./widget.scss"

const Widget = ({ type, nilai, diff }) => {
  let data

  switch (type) {
    case "program":
      data = {
        title: "PROGRAM",
        isMoney: false,
        link: (
          <Link to="/maintprogram" style={{ textDecoration: "none" }}>
              <span>Lihat semua program</span>
          </Link>
        ),
        icon: (
          <i className="bi bi-vinyl text-warning text-center" style={{ fontSize: 35 }}></i>
        ),
      }
      break
    case "kegiatan":
      data = {
        title: "KEGIATAN",
        isMoney: false,
        link: (
          <Link to="/maintkegiatan" style={{ textDecoration: "none" }}>
              <span>Lihat semua kegiatan</span>
          </Link>
        ),
        icon: (
          <i className="bi bi-command text-warning text-center" style={{ fontSize: 35 }}></i>
        ),
      }
      break
    case "anggaran":
      data = {
        title: "ANGGARAN",
        isMoney: true,
        link: (
          <Link to="/anggarankegiatan" style={{ textDecoration: "none" }}>
              <span>Lihat daftar anggaran</span>
          </Link>
        ),
        icon: (
          <i className="bi bi-coin text-success text-center" style={{ fontSize: 35 }}></i>
        ),
      }
      break
    case "dukungan":
      data = {
        title: "DUKUNGAN MAJELIS",
        isMoney: true,
        link: (
          <Link to="/danadukungan" style={{ textDecoration: "none" }}>
              <span>Lihat daftar dukungan majelis</span>
          </Link>
        ),
        icon: (
          <i className="bi bi-intersect text-success text-center" style={{ fontSize: 35 }}></i>
        ),
      }
      break
    default:
      break
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.isMoney && "Rp. "} 
          <NumberFormat
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={0}
            fixedDecimalScale={true}
            value={nilai}
          />
        </span>
        <span className="link">{data.link}</span>
      </div>
      <div className="right">
        <div className={diff < 0 ? "percentage negative" : "percentage positive"}>
          <i className={
            diff < 0 
              ? "bi bi-chevron-down text-danger fw-bold-5" 
              : (diff > 0 
                  ? "bi bi-chevron-up text-success fw-bold-5" 
                  : "bi bi-arrows-collapse text-secondary fw-bold-5" 
                )
          }
          ></i>
          &nbsp;
          {diff} %
        </div>
        {data.icon}
      </div>
    </div>
  )
}

export default Widget
