import { useQuery } from 'react-query'
import { toast } from 'react-toastify'

import useAxiosPrivate from '../../hooks/useAxiosPrivate'

// const apiUrlAG = "/api/ag-laporan"
const apiUrlAG = "https://ag.gkj.web.id/api/ag-laporan"

toast.configure()

// Form pengajuan begin

export const useFormPengajuanList = (nomorIdGereja, nomorIdBakowi, tahun, bulan, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate()
  return useQuery(
    ['formpengajuan', nomorIdGereja, nomorIdBakowi, tahun, bulan], 
    () => (axiosPrivate(`${apiUrlAG}/formpengajuan/${nomorIdGereja}/${nomorIdBakowi}/${tahun}/${bulan}`)),
    {
      onSuccess,
      onError,
      select: (data) => {
        const formPengajuanList = data.data[0]
        return formPengajuanList
      }
    }
  )
}

// Form pengajuan end

// Buku kas begin

export const useBukuKasList = (nomorIdGereja, nomorIdBakowi, tahun, bulan, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate()
  return useQuery(
    ['bukukas', nomorIdGereja, nomorIdBakowi, tahun, bulan], 
    () => (axiosPrivate.get(`${apiUrlAG}/bukukas/${nomorIdGereja}/${nomorIdBakowi}/${tahun}/${bulan}`)), 
    {
      onSuccess,
      onError,
      select: (data) => {
        const bukuKasList = data.data[0]
        return bukuKasList
      }
    }
  )
}

// Buku kas end

// Laporan keuangan begin

export const useLaporanKeuanganList = (nomorIdGereja, nomorIdBakowi, tahun, bulan, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate()
  return useQuery(['laporankeuangan', nomorIdGereja, nomorIdBakowi, tahun, bulan], 
    () => (axiosPrivate(`${apiUrlAG}/laporankeuangan/${nomorIdGereja}/${nomorIdBakowi}/${tahun}/${bulan}`)), 
    {
      onSuccess,
      onError,
      select: (data) => {
        const laporanKeuanganList = data.data[0]
        return laporanKeuanganList
      }
    }
  )
}

// Laporan keuangan end

// Dana dukungan begin

export const useDanaDukunganList = (nomorIdGereja, nomorIdBakowi, tahun, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate()
  return useQuery(['danadukungan', nomorIdGereja, nomorIdBakowi, tahun], 
    () => (axiosPrivate.get(`${apiUrlAG}/danadukungan/${nomorIdGereja}/${nomorIdBakowi}/${tahun}`)), 
    {
      onSuccess,
      onError,
      select: (data) => {
        const danaDukunganList = data.data[0]
        return danaDukunganList
      }
    }
  )
}

// Dana dukungan end

// Anggaran kegiatan begin

export const useAnggaranKegiatanList = (nomorIdGereja, nomorIdBakowi, tahun, tipeTK, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate()
  return useQuery(['anggarankegiatan', nomorIdGereja, nomorIdBakowi, tahun, tipeTK], 
    () => (axiosPrivate.get(`${apiUrlAG}/anggarankegiatan/${nomorIdGereja}/${nomorIdBakowi}/${tahun}/${tipeTK}`)), 
    {
      onSuccess,
      onError,
      select: (data) => {
        const anggaranKegiatanList = data.data[0]
        return anggaranKegiatanList
      }
    }
  )
}

// Anggaran kegiatan end