import React, { useState } from 'react'
import { useAsyncDebounce } from 'react-table'

export const TransaksiListTableGlobalFilter = ({ filter, setFilter }) => {
  const [value, setValue] = useState(filter)
  const onChange = useAsyncDebounce(value => {
    setFilter(value || undefined)
  }, 1000)
  return (
    <div className=" d-inline-flex input-group input-group-sm w-50 mb-1">
      <div className="input-group-prepend me-2">
        <span className="input-group-text btn-outline-info" id="inputGroup-sizing-sm">Cari di tabel: {' '}</span>
      </div>
      <input className="form-control bg-white" aria-describedby="inputGroup-sizing-sm"
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
      />
    </div>
  )
}
