import React, { useState, useEffect } from 'react'
import useAuth from '../../hooks/useAuth'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

// import "./transaksiedit.scss"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
// import { EditTransaksiValidationSchema } from './components/edit/ValidationSchema'
import TextError from '../../components/form/TextError'
import CustomSelect from '../../components/form/CustomSelect'
import NumberFormat from 'react-number-format'
import DatePickerField from '../../components/form/DatePickerField'

require('moment/locale/id')

const jenisTransaksiList = [
  { value: 'A', label: 'A | Mutasi masuk kas/bank' },
  { value: 'B', label: 'B | Mutasi keluar kas/bank' },
  { value: 'X', label: 'X | Penerimaan dana dukungan' },
  { value: 'Y', label: 'Y | Rencana program/kegiatan' },
  { value: 'Z', label: 'Z | Lainnya' }
]

let config = ""
toast.configure()

const TransaksiEdit = ({onClickHandleEdit, idGereja, idBakowi, idUserMaint, nomorIdTransaksi, klmpkList, aknList, prgList, kegList}) => {
  
  window.scrollTo(0, 0)
  const { auth } = useAuth()
  
  // const apiUrlAG = "/api/ag-transaksi"
  const apiUrlAG = auth.API_URL + "/ag-transaksi"

  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  // const fetchTransaksi = (idGereja, idBakowi) => {
  //   return axiosPrivate.get(`${apiUrlAG}/transaksi/${idGereja}/${idBakowi}`)
  // }

  // const useTransaksiList = (idGereja, idBakowi, onSuccess, onError) => {
  //     return useQuery(
  //         ['transaksi', idGereja, idBakowi], 
  //         () => fetchTransaksi(idGereja, idBakowi), 
  //         {
  //             onSuccess,
  //             onError,
  //             select: (data) => {
  //                 const transaksiList = data.data[0]
  //                 return transaksiList
  //             }
  //         }
  //     )
  // }

  const addTransaksi = (input) => {
    config = {
      method : "post",
      url : `${apiUrlAG}/transaksi/`,
      headers : {
        "Content-Type":"application/json"
      },
      data : {
        idGereja: input.idGereja,
        idBakowi: input.idBakowi,
        jenisTransaksi: input.jenisTransaksi,
        idKelompokTransaksi: input.idKelompokTransaksi,
        idAkunKasBank: input.idAkunKasBank,
        idProgram: input.idProgram,
        idKegiatan: input.idKegiatan,
        nomorVoucher: input.nomorVoucher,
        tanggalTransaksi: input.tanggalTransaksi,
        nilai: input.nilai,
        keterangan: input.keterangan,
        idUser: input.idUserMaint,
        userActivity: 'Add'
      }
    }
    return axiosPrivate(config) 
  }

  const useAddTransaksi = () => {
    return useMutation(
      addTransaksi, 
      {
        onError: () => {
          toast.error('Data transaksi gagal ditambah !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: () => {
          toast.success('Data transaksi berhasil ditambah ...', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
          queryClient.invalidateQueries()
        },
      }
    )
  }

  const updateTransaksi = (input) => {
    const config = {
      method : "patch",
      url : `${apiUrlAG}/transaksi/${input.idTransaksi}`,
      headers : {
        "Content-Type":"application/json"
      },
      data : {
        idGereja: input.idGereja,
        idBakowi: input.idBakowi,
        jenisTransaksi: input.jenisTransaksi,
        idKelompokTransaksi: input.idKelompokTransaksi,
        idAkunKasBank: input.idAkunKasBank,
        idProgram: input.idProgram,
        idKegiatan: input.idKegiatan,
        nomorVoucher: input.nomorVoucher,
        tanggalTransaksi: input.tanggalTransaksi,
        nilai: input.nilai,
        keterangan: input.keterangan,
        idUser: input.idUserMaint,
        userActivity: 'Update'
      }
    }
    return axiosPrivate(config)
  }

  const useUpdateTransaksi = () => {
    return useMutation(
      updateTransaksi, 
      {
        onError: () => {
          toast.error('Data transaksi gagal diubah !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: () => {
          toast.success('Data transaksi berhasil diubah ...', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
          queryClient.invalidateQueries()
        },
      }
    )
  }

  const deleteTransaksi = (input) => {
    const config = {
      method : "patch",
      url : `${apiUrlAG}/transaksi/delete/${input.idTransaksi}`,
      headers : {
        "Content-Type":"application/json"
      },
      data : {
        idUser: input.idUserMaint,
        userActivity: 'Delete'
      }
    }
    return axiosPrivate(config)
  }

  const useDeleteTransaksi = () => {
    return useMutation(
      deleteTransaksi, 
      {
        onError: () => {
          toast.error('Data transaksi gagal dihapus !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: () => {
          toast.success('Data transaksi berhasil dihapus ...', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
          queryClient.invalidateQueries()
        },
      }
    )
  }

  const verifyTransaksi = (input) => {
    const config = {
      method : "patch",
      url : `${apiUrlAG}/transaksi/verifikasi/${input.idTransaksi}`,
      headers : {
        "Content-Type":"application/json"
      },
      data : {
        userVerifikasi: input.idUserMaint
      }
    }
    return axiosPrivate(config) 
  }

  const useVerifyTransaksi = () => {
    return useMutation(verifyTransaksi, 
      {
        onError: () => {
          toast.error('Data transaksi gagal diverifikasi !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: () => {
          toast.success('Data transaksi berhasil diverifikasi ...', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
          queryClient.invalidateQueries()
        },
      }
    )
  }

  // const postingTransaksi = (input) => {
  //   const config = {
  //     method : "patch",
  //     url : `${apiUrlAG}/transaksi/posting/${input.idTransaksi}`, 
  //     headers : {
  //       "Content-Type":"application/json"
  //     },
  //     data : {
  //       userPosting: input.idUserMaint
  //     }
  //   }
  //   return axiosPrivate(config)
  // }

  // const usePostingTransaksi = () => {
  //   return useMutation(
  //     postingTransaksi, 
  //     {
  //       onError: () => {
  //         toast.error('Data transaksi gagal diposting !', {
  //           position: toast.POSITION.BOTTOM_CENTER,
  //           autoClose: 2000
  //         })
  //       },
  //       onSuccess: () => {
  //         toast.success('Data transaksi berhasil diposting ...', {
  //           position: toast.POSITION.BOTTOM_CENTER,
  //           autoClose: 2000
  //         })
  //         queryClient.invalidateQueries()
  //       },
  //     }
  //   )
  // }

  const [klpList, setKlpList] = useState([])
  const [akunKasBankList, setAkunKasBankList] = useState([])
  const [programList, setProgramList] = useState([])
  const [kegiatanList, setKegiatanList] = useState([])

  useEffect(() => {
    klmpkList && setKlpList(klmpkList)
    aknList && setAkunKasBankList(aknList)
    prgList && setProgramList(prgList)
    kegList && setKegiatanList(kegList)
  }, [klmpkList, aknList, prgList, kegList])

  let menuScreenClassName = 'w-100 justify-content-end text-center border-0 fw-bold text-light'
  let btnTambahClassName = 'btn btn-outline-primary btn-sm fst-italic fw-bold'
  let btnUbahClassName = 'btn btn-outline-success btn-sm fst-italic fw-bold'
  let btnHapusClassName = 'btn btn-outline-danger btn-sm fst-italic fw-bold'
  let btnVerifikasiClassName = 'btn btn-outline-warning btn-sm fst-italic fw-bold'
  let btnConfirmClassName = 'btn btn-sm fst-italic fw-bold text-light'
  let btnCancelClassName = 'btn btn-dark btn-sm fst-italic fw-bold text-light'

  const initialValues = {
    idUserMaint: idUserMaint,
    idTransaksi: nomorIdTransaksi ? nomorIdTransaksi.idTransaksi : '',
    idGereja: idGereja,
    idBakowi: idBakowi,
    jenisTransaksi: nomorIdTransaksi ? nomorIdTransaksi.jenisTransaksi : '',
    idKelompokTransaksi: nomorIdTransaksi ? nomorIdTransaksi.idKelompokTransaksi : '',
    namaKelompokTransaksi: nomorIdTransaksi ? nomorIdTransaksi.namaKelompokTransaksi : '',
    idAkunKasBank: nomorIdTransaksi ? nomorIdTransaksi.idAkunKasBank : '',
    namaAkunKasBank: nomorIdTransaksi ? nomorIdTransaksi.namaAkunKasBank : '',
    idProgram: nomorIdTransaksi ? nomorIdTransaksi.idProgram : '',
    namaProgram: nomorIdTransaksi ? nomorIdTransaksi.namaProgram : '',
    idKegiatan: nomorIdTransaksi ? nomorIdTransaksi.idKegiatan : '',
    namaKegiatan: nomorIdTransaksi ? nomorIdTransaksi.namaKegiatan : '',
    nomorVoucher: nomorIdTransaksi && nomorIdTransaksi.nomorVoucher ? nomorIdTransaksi.nomorVoucher : '',
    tanggalTransaksi: nomorIdTransaksi ? nomorIdTransaksi.tanggalTransaksi : '',
    nilai: nomorIdTransaksi ? nomorIdTransaksi.nilaiTransaksi : '',
    keterangan: nomorIdTransaksi ? nomorIdTransaksi.keterangan : '',
    idUser: nomorIdTransaksi ? (nomorIdTransaksi.idUser ? nomorIdTransaksi.idUser : '*') : idUserMaint,
    namaUser: nomorIdTransaksi && nomorIdTransaksi.namaUser ? nomorIdTransaksi.namaUser : '*',
    userActivity: nomorIdTransaksi && nomorIdTransaksi.userActivity ? nomorIdTransaksi.userActivity : '*',
    tanggalSystem: nomorIdTransaksi && nomorIdTransaksi.tanggalSystem ? nomorIdTransaksi.tanggalSystem : '*',
    idUserVerifikasi: nomorIdTransaksi && nomorIdTransaksi.idUserVerifikasi ? nomorIdTransaksi.idUserVerifikasi : '*',
    namaUserVerifikasi: nomorIdTransaksi && nomorIdTransaksi.namaUserVerifikasi ? nomorIdTransaksi.namaUserVerifikasi : '*',
    tanggalVerifikasi: nomorIdTransaksi && nomorIdTransaksi.tanggalVerifikasi ? nomorIdTransaksi.tanggalVerifikasi : '*',
    idUserPostingToGL: nomorIdTransaksi && nomorIdTransaksi.idUserPostingToGL ? nomorIdTransaksi.idUserPostingToGL : '*',
    namaUserPostingToGL: nomorIdTransaksi && nomorIdTransaksi.namaUserPostingToGL ? nomorIdTransaksi.namaUserPostingToGL : '*',
    tanggalPostingToGL: nomorIdTransaksi && nomorIdTransaksi.tanggalPostingToGL ? nomorIdTransaksi.tanggalPostingToGL : '*',
    menuScreen: nomorIdTransaksi ? 'Idle' : 'A D D',
    menuClass: menuScreenClassName.concat(nomorIdTransaksi ? ' bg-info' : ' bg-primary'),
    fieldDisableKey: nomorIdTransaksi ? true : false,
    fieldDisable: nomorIdTransaksi ? true : false,
    btnMenuDisable: false,
    btnTambahClass: btnTambahClassName.concat(nomorIdTransaksi ? '' : ' invisible'),
    btnUbahClass: btnUbahClassName.concat(nomorIdTransaksi ? '' : ' invisible'),
    btnHapusClass: btnHapusClassName.concat(nomorIdTransaksi ? '' : ' invisible'),
    btnVerifikasiClass: btnVerifikasiClassName.concat(nomorIdTransaksi ? '' : ' invisible'),
    btnConfirmClass: btnConfirmClassName.concat(nomorIdTransaksi ? ' invisible' : ' bg-primary visible'),
    btnCancelClass: btnCancelClassName.concat(nomorIdTransaksi ? ' invisible' : ' bg-primary visible'),
    btnConfirmText: nomorIdTransaksi ? '' : 'Tambahkan data ...'
  }

  const { mutate: addTrn } = useAddTransaksi()
  const { mutate: updateTrn } = useUpdateTransaksi()
  const { mutate: deleteTrn } = useDeleteTransaksi()
  const { mutate: verifyTrn } = useVerifyTransaksi()
  const onClickConfirm = (input) => {
    if (input.menuScreen === "A D D") {
      addTrn(input)
      onClickHandleEdit(false)
    }
    if (input.menuScreen === "UPDATE") {
      updateTrn(input)
      onClickHandleEdit(false)
    }
    if (input.menuScreen === "DELETE") {
      deleteTrn(input)
      onClickHandleEdit(false)
    }
    if (input.menuScreen === "VERIFY") {
      verifyTrn(input)
      onClickHandleEdit(false)
    }
  }

  const clickExit = () => {
    onClickHandleEdit(false)
  }

  const renderNilai = ({
    field: { name, value, onChange, onBlur },
    form: { touched, errors, values, setFieldValue, setFieldTouched },
    ...custom
  }) => {
    return (
      <NumberFormat
        name={name}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        value={value}
        onValueChange={val =>
          setFieldValue(name, val.floatValue)
        }
        {...custom}
      />
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={EditTransaksiValidationSchema}
      enableReinitialize
    >
      {formik => {
        // function screenIdle
        const screenIdle = () => {
          formik.setFieldValue('menuScreen', 'Idle')
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnHapusClass', btnHapusClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnVerifikasiClass', btnVerifikasiClassName.concat(' ', 'visible'))
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-primary'))
          // show buttonTU => 'FF'
          formik.setFieldValue('btnMenuDisable', false)
          // hidden button konfirmasi
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'invisible'))
          // disable semua field
          formik.setFieldValue('fieldDisableKey', true)
          formik.setFieldValue('fieldDisable', true)
        }
        // function clickTambah
        const clickTambah = () => {
          formik.setFieldValue('idTransaksi', '')
          formik.setFieldValue('jenisTransaksi', '')
          formik.setFieldValue('idKelompokTransaksi', '')
          formik.setFieldValue('namaKelompokTransaksi', '')
          formik.setFieldValue('idAkunKasBank', '')
          formik.setFieldValue('namaAkunKasBank', '')
          formik.setFieldValue('idProgram', '')
          formik.setFieldValue('namaProgram', '')
          formik.setFieldValue('idKegiatan', '')
          formik.setFieldValue('namaKegiatan', '')
          formik.setFieldValue('nomorVoucher', '')
          formik.setFieldValue('tanggalTransaksi', '')
          formik.setFieldValue('nilai', '')
          formik.setFieldValue('keterangan', '')
          formik.setFieldValue('idUser', idUserMaint)
          formik.setFieldValue('menuScreen', 'A D D')
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-primary'))
          // hidden buttonTUHV => [T]ambah - [U]bah - [H]apus - [V]erifikasi
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnHapusClass', btnHapusClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnVerifikasiClass', btnVerifikasiClassName.concat(' ', 'invisible'))
          // show button konfirmasi (Tambahkan data ... & Batal)
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'bg-primary', ' ', 'visible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnConfirmText', 'Tambahkan data ...')
          // enable semua field
          formik.setFieldValue('fieldDisableKey', false)
          formik.setFieldValue('fieldDisable', false)
        }
        // function clickUbah
        const clickUbah = () => {
          formik.setFieldValue('menuScreen', 'UPDATE')
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-success'))
          // hidden buttonTUHV
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnHapusClass', btnHapusClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnVerifikasiClass', btnVerifikasiClassName.concat(' ', 'invisible'))
          // show button konfirmasi (Ubah data ... & Batal)
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'bg-primary', ' ', 'visible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnConfirmText', 'Ubah data ...')
          // enable semua field
          formik.setFieldValue('fieldDisable', false)
        }
        // function clickHapus
        const clickHapus = () => {
          formik.setFieldValue('menuScreen', 'DELETE')
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-success'))
          // hidden buttonTUHV
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnHapusClass', btnHapusClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnVerifikasiClass', btnVerifikasiClassName.concat(' ', 'invisible'))
          // show button konfirmasi (Hapus data ... & Batal)
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'bg-danger', ' ', 'visible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnConfirmText', 'Hapus data ...')
          // disable semua field
          formik.setFieldValue('fieldDisable', true)
        }
        // function clickVerifikasi
        const clickVerifikasi = () => {
          formik.setFieldValue('menuScreen', 'VERIFY')
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-success'))
          // hidden buttonTU
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnHapusClass', btnHapusClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnVerifikasiClass', btnVerifikasiClassName.concat(' ', 'invisible'))
          // show button konfirmasi (Verifikasi data ... & Batal)
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'bg-warning', ' ', 'visible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnConfirmText', 'Verifikasi data ...')
          // disable semua field
          formik.setFieldValue('fieldDisable', true)
        }
        return (
          <div className="container">
            <Form className="w-100">
              <Row className="fw-bold mb-1">
                <Field name="idUserMaint" type="hidden"/>
                <Field name="idGereja" type="hidden"/>
                <Field name="idBakowi" type="hidden"/>
                <Field name="fieldDisableKey" type="hidden"/>
                <Field name="fieldDisable" type="hidden"/>
                <Field name="menuClass" type="hidden"/>
                <Field name="btnTambahClass" type="hidden"/>
                <Field name="btnUbahClass" type="hidden"/>
                <Field name="btnHapusClass" type="hidden"/>
                <Field name="btnVerifikasiClass" type="hidden"/>
                <Field name="btnMenuDisable" type="hidden"/>
                <Field name="btnConfirmClass" type="hidden"/>
                <Field name="btnCancelClass" type="hidden"/>
                <Field name="btnConfirmText" type="hidden"/>
                <Col md={9} sm={6} xs={6}>
                  <p className="mb-1">
                    <span className="text-primary fw-bold"> <i className="bi bi-arrow-right-square-fill"></i> </span> &nbsp; 
                    Id. Transaksi &nbsp; : &nbsp; <strong>{formik.values.idTransaksi}</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Keterangan &nbsp; : &nbsp; <strong>{formik.values.keterangan}</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                  </p>
                </Col>
                <Col md={3} sm={3} xs={3}>
                  <Field name="menuScreen" disabled={true} className={formik.values.menuClass}/>
                </Col>
              </Row>
              <hr className='mb-2'/>
              <Row>
                <Col className="form-group col-3 justify-content-start">
                  <label className="text-primary w-50" htmlFor="jenisTransaksi"> Jenis Transaksi </label>
                </Col>
                <Col className="form-group col-5 justify-content-start">
                  <label className="text-primary w-50" htmlFor="idKelompokTransaksi"> Kelompok </label>
                </Col>
                <Col className="form-group col-4 justify-content-start">
                  <label className="text-primary w-50" htmlFor="idAkunKasBank"> Akun </label>
                </Col>
              </Row>
              <Row>
                <Col className="form-group col-3 justify-content-start">
                  <CustomSelect
                    options={jenisTransaksiList}
                    value={formik.values.jenisTransaksi}
                    placeholder="Ketik / Pilih => Jenis Transaksi"
                    onChange={value => value ? formik.setFieldValue('jenisTransaksi', value.value) : null}
                    disableSelect={formik.values.fieldDisable}
                    clearable={false}
                    className="mb-1 w-100"
                  />
                  <ErrorMessage name="idKelompokTransaksi" component={TextError} className="invalid-feedback" />
                </Col>
                <Col className="form-group col-5 justify-content-start">
                  <CustomSelect
                    options={klpList}
                    value={formik.values.idKelompokTransaksi}
                    placeholder="Ketik / Pilih => id / Nama Kelompok Transaksi"
                    onChange={value => value ? formik.setFieldValue('idKelompokTransaksi', value.value) : null}
                    disableSelect={formik.values.fieldDisable || ["A", "B"].includes(formik.values.jenisTransaksi)}
                    clearable={false}
                    className="mb-1 w-100"
                  />
                  <ErrorMessage name="idKelompokTransaksi" component={TextError} className="invalid-feedback" />
                </Col>
                <Col className="form-group col-4 justify-content-start">
                  <CustomSelect
                    options={akunKasBankList}
                    value={formik.values.idAkunKasBank}
                    placeholder="Ketik / Pilih => id / Nama Akun"
                    onChange={value => value ? formik.setFieldValue('idAkunKasBank', value.value) : null}
                    disableSelect={formik.values.fieldDisable}
                    clearable={false}
                    className="mb-1 w-100"
                  />
                  <ErrorMessage name="idAkunKasBank" component={TextError} className="invalid-feedback" />
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col className="form-group col-6 justify-content-start">
                  <label className="text-primary" htmlFor="idProgram"> Program </label>
                </Col>
                <Col className="form-group col-6 justify-content-start">
                  <label className="text-primary w-15" htmlFor="idKegiatan"> Kegiatan </label>
                </Col>
              </Row>
              <Row>
                <Col className="form-group col-6 justify-content-start">
                  <CustomSelect
                    options={programList}
                    value={formik.values.idProgram}
                    placeholder="Ketik / Pilih => Id / Nama Program"
                    onChange={value => value ? formik.setFieldValue('idProgram', value.value) : null}
                    disableSelect={formik.values.fieldDisable || ["A", "B"].includes(formik.values.jenisTransaksi)}
                    clearable={false}
                    className="mb-1 w-100"
                  />
                  <ErrorMessage name="idProgram" component={TextError} className="invalid-feedback" />
                </Col>
                <Col className="form-group col-6 justify-content-start">
                  <CustomSelect
                    options={kegiatanList.filter(kegiatanList => kegiatanList.idProgram === formik.values.idProgram)}
                    value={formik.values.idKegiatan}
                    placeholder="Ketik / Pilih => Id / Nama Kegiatan"
                    onChange={value => value ? formik.setFieldValue('idKegiatan', value.value) : null}
                    disableSelect={formik.values.fieldDisable || ["A", "B", "X"].includes(formik.values.jenisTransaksi)}
                    clearable={false}
                    className="mb-1 w-100"
                  />
                  <ErrorMessage name="idKegiatan" component={TextError} className="invalid-feedback" />
                </Col>
              </Row>
              <hr className='mb-1'/>
              <Row>
                <Col className="form-group col-4">
                  <label className="text-primary w-75" htmlFor="nomorVoucher"> No. Voucher </label>
                </Col>
                <Col className="form-group col-4">
                  <label className="text-primary w-75" htmlFor="tanggalTransaksi"> Tanggal Transaksi </label>
                </Col>
                <Col className="form-group col-4">
                  <label className="text-primary w-75" htmlFor="nilai"> Nilai Transaksi </label>
                </Col>
              </Row>
              <Row>
                <Col className="form-group col-4">
                  <Field className="text-start w-100" id="nomorVoucher" name="nomorVoucher" disabled={formik.values.fieldDisable} />
                </Col>
                {/* min dan max date nya perlu dibatasi !!! */}
                <Col className="form-group col-4">
                  <DatePickerField 
                    className="w-100 text-center" 
                    name="tanggalTransaksi" 
                    disabled={formik.values.fieldDisable}
                  />
                </Col>
                <Col className="form-group col-4">
                  <Field className="text-end w-100" id="nilai" name="nilai"  component={renderNilai} disabled={formik.values.fieldDisable} />
                </Col>
              </Row>
              <hr className='mb-1'/>
              <Row>
                <Col className="form-group col-12">
                  <label className="text-primary w-10" htmlFor="keterangan"> Keterangan </label>
                  <Field className="text-start w-100" id="keterangan" name="keterangan" disabled={formik.values.fieldDisable} />
                </Col>
              </Row>
              <hr />
              <Row className="fw-bold small mb-3">
                <Row className='mb-0'>
                  <Col className='col-3 pe-0'>
                    <span className="text-warning"> <i className="bi bi-x-circle-fill"></i> </span> &nbsp; 
                    {'Maintenance'} : {formik.values.userActivity} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                  </Col>
                  <Col className='col-5'>
                    <span className="text-warning fw-bold"> <i className="bi bi-arrow-right-circle"></i> </span> &nbsp; 
                    Id User : {formik.values.idUser} - {formik.values.namaUser} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Col>
                  <Col className='col-4'>
                    <span className="text-warning fw-bold"> <i className="bi bi-arrow-right-circle"></i> </span> &nbsp; 
                    Tanggal : {
                      formik.values.tanggalSystem === '*' 
                        ? formik.values.tanggalSystem 
                        : moment(formik.values.tanggalSystem).format('LLLL')
                    } &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Col>
                </Row>
                <Row className='mb-0'>
                  <Col className='col-3 pe-0'> 
                    <span className="text-primary"> <i className="bi bi-x-circle-fill"></i> </span> &nbsp; 
                    {formik.values.tanggalVerifikasi !== '*' ? 'Sudah ' : 'Belum '} diverifikasi &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                  </Col>
                  <Col className='col-5'>
                    <span className="text-primary fw-bold"> <i className="bi bi-arrow-right-circle"></i> </span> &nbsp; 
                    Id User : {formik.values.idUserVerifikasi} - {formik.values.namaUserVerifikasi} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Col>
                  <Col className='col-4'>
                    <span className="text-primary fw-bold"> <i className="bi bi-arrow-right-circle"></i> </span> &nbsp; 
                    Tanggal : {
                      formik.values.tanggalVerifikasi === '*' 
                        ? formik.values.tanggalVerifikasi 
                        : moment(formik.values.tanggalVerifikasi).format('LLLL')
                    } &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Col>
                </Row>
                <Row>
                  <Col className='col-3 pe-0'> 
                    <span className="text-success"> <i className="bi bi-x-circle-fill"></i> </span> &nbsp; 
                    {formik.values.tanggalPostingToGL !== '*' ? 'Sudah ' : 'Belum '} diposting &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                  </Col>
                  <Col className='col-5'>
                    <span className="text-success fw-bold"> <i className="bi bi-arrow-right-circle"></i> </span> &nbsp; 
                    Id User : {formik.values.idUserPostingToGL} - {formik.values.namaUserPostingToGL} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Col>
                  <Col className='col-4'>
                    <span className="text-success fw-bold"> <i className="bi bi-arrow-right-circle"></i> </span> &nbsp; 
                    Tanggal : {
                      formik.values.tanggalPostingToGL === '*' 
                        ? formik.values.tanggalPostingToGL 
                        : moment(formik.values.tanggalPostingToGL).format('LLLL')
                    } &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Col>
                </Row>
              </Row>
              <Row className="fw-bold small mt-0">
                <Col className="justify-content-start">
                  <button 
                    type="button" 
                    className={formik.values.btnConfirmClass}
                    disabled={
                      (
                        (
                          (["A", "B"].includes(formik.values.jenisTransaksi)) ||
                          (["X", "Y", "Z"].includes(formik.values.jenisTransaksi) && 
                            formik.values.idKelompokTransaksi &&
                            formik.values.idKelompokTransaksi && 
                            formik.values.idProgram) || 
                          (["Y", "Z"].includes(formik.values.jenisTransaksi) && 
                            formik.values.idKelompokTransaksi &&
                            formik.values.idKelompokTransaksi && 
                            formik.values.idKegiatan)
                        ) && 
                        formik.values.idAkunKasBank && 
                        formik.values.tanggalTransaksi && 
                        formik.values.nilai > 0 && 
                        formik.values.keterangan
                      ) 
                        ? false : true}
                    onClick={() => onClickConfirm(formik.values)}
                  > {formik.values.btnConfirmText} </button>
                  &nbsp;&nbsp;&nbsp;
                  <button 
                    type="button" 
                    className={formik.values.btnCancelClass} 
                    onClick={() => formik.values.menuScreen === "UPDATE" ? screenIdle() : onClickHandleEdit(false)}
                  > Batal </button>
                </Col>
                <Col className="text-end">
                  <button 
                    type="button" 
                    className={formik.values.btnTambahClass} 
                    disabled={formik.values.btnMenuDisable}
                    onClick={() => clickTambah()}
                  > Tambah </button>
                  &nbsp;&nbsp;&nbsp;
                  <button 
                    type="button" 
                    className={
                      (formik.values.idUserVerifikasi !== '*' || formik.values.idUserPostingToGL !== '*') 
                        ? 'btn btn-sm fst-italic fw-bold bg-secondary' 
                        : formik.values.btnUbahClass
                      } 
                    disabled={formik.values.btnMenuDisable || formik.values.idUserVerifikasi !== '*' || formik.values.idUserPostingToGL !== '*'} 
                    onClick={() => clickUbah()}
                  > Ubah </button>
                  &nbsp;&nbsp;&nbsp;
                  <button 
                    type="button" 
                    className={
                      (formik.values.idUserVerifikasi !== '*' || formik.values.idUserPostingToGL !== '*') 
                        ? 'btn btn-sm fst-italic fw-bold bg-secondary' 
                        : formik.values.btnHapusClass
                      } 
                    disabled={formik.values.btnMenuDisable || formik.values.idUserVerifikasi !== '*' || formik.values.idUserPostingToGL !== '*'} 
                    onClick={() => clickHapus()}
                  > Hapus </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button 
                    type="button" 
                    className={
                      (formik.values.idUserVerifikasi !== '*' || formik.values.idUserPostingToGL !== '*') 
                        ? 'btn btn-sm fst-italic fw-bold bg-secondary' 
                        : formik.values.btnVerifikasiClass
                      } 
                    disabled={formik.values.btnMenuDisable || formik.values.idUserVerifikasi !== '*' || formik.values.idUserPostingToGL !== '*'} 
                    onClick={() => clickVerifikasi()}
                  > Verifikasi </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button 
                    type="button"
                    className='btn btn-outline-secondary btn-sm fst-italic fw-bold'
                    onClick={() => clickExit()}
                  > Keluar </button>
                </Col>                                
              </Row>
              <hr/>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

export default TransaksiEdit