import { useLocation, Navigate, Outlet } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
// import jwt_decode from 'jwt-decode'

// const RequiredAuth = ({ allowedRoles }) => {
const RequiredAuth = () => {
    const { auth } = useAuth()
    const location = useLocation()
    // const decoded = auth?.accessToken
    //     ? jwt_decode(auth.accessToken)
    //     : undefined
    // const roles = decoded?.UserInfo.roles || []

    return (
        // roles.find(role => allowedRoles?.included(role)) 
        auth?.authIdUser
            ? <Outlet />
            : auth?.accessToken //changed form user to accessToken to persist
                ? <Outlet />
                : <Navigate to="/unathorized" state={{ from: location }} replace />
    )
}

export default RequiredAuth