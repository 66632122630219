import React, { useState } from 'react'
import useAuth from '../../hooks/useAuth'

import "./bukukas.scss"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Row, Col, Container, Table } from 'react-bootstrap'

import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import { useBukuKasList } from '../../components/data/UseDataLaporan'

import moment from 'moment'
import NumberFormat from 'react-number-format'
// import CetakBukuKas from './CetakBukuKas'
import Logo from '../../images/Logo'

const imgLogo = Logo()

const namaBulan = [
  'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 
  'Juli', 'Agustus', 'September', 'Oktober', 'Nopember', 'Desember'
]
let thn = 0
let tglPeriode = ""

const colStyle = {
  backgroundColor: '#004b48',
  color: "#00ffff",
  fontSize: "12px",
}

const BukuKas = () => {
  const { auth } = useAuth()
  const idGereja = auth.authIdGereja
  // const namaGereja = auth.authNamaGereja
  const idBakowi = auth.authIdBakowi
  // const namaBadanKomisi = auth.authNamaBakowi
  const tahunMulai = auth.authTahunMulai
  // const idUser = auth.authIdUser

  const [thnTrn, setThnTrn] = useState((new Date()).getFullYear())
  const [blnTrn, setBlnTrn] = useState(new Date().getMonth()+1)
  const [keyFromButton, setKeyFromButton] = useState({tahun: thnTrn, bulan: blnTrn})

  tglPeriode = thnTrn > 0 ? moment([thnTrn,blnTrn-1,1]).endOf("month").format('DD-MMMM-YYYY') : ""

  window.scrollTo(0, 0)

  const onProses = () => {
    setKeyFromButton({ ...keyFromButton, tahun: thnTrn, bulan: blnTrn})
  }

  const { data: bukuKasList, isLoading, isSuccess, isError } = useBukuKasList(idGereja, idBakowi, thnTrn, blnTrn)

  const onMinus = () => {
    thn = thnTrn.valueOf()
    if (thn > tahunMulai) setThnTrn(thn - 1)
  }
  const onPlus = () => {
    thn = thnTrn.valueOf()
    if (thn < (new Date()).getFullYear()) setThnTrn(thn - 1 + 2)
  }

  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <h3 className="ms-4 mt-3"> Laporan Buku Kas </h3>
        <Container className="w-100">
          <Formik
            initialValues={{ idGereja: idGereja, tahun: thnTrn, bulan: blnTrn }}
            // validationSchema={TahunBulanValidationSchema}
            enableReinitialize
          >
            {formik => {
              return (
                <Container className="w-100">
                  <Form>
                    <Row className="text-center">
                      <h5 className="fw-bold"> Laporan Buku Kas </h5>
                      <hr/>
                    </Row>
                    <Row className="text-center d-block h-auto">
                      <button 
                        type="button" 
                        className="btn btn-outline-secondary text-dark bg-white d-inline mb-1"
                        style={{'width': '75px'}}
                      > 
                        Tahun 
                      </button>
                      <div className="input-group d-inline">
                        {/* <!-- aria-label="..." on the control -->
                        <button ... aria-label="Mute">
                          <svg class="bi bi-volume-mute-fill" aria-hidden="true" ...>
                          ...
                          </svg>
                        </button> */}
                        {/* <i class="bi-alarm" style="font-size: 2rem; color: cornflowerblue;"></i> */}
                        {/* <img src="/assets/img/bootstrap.svg" alt="Bootstrap" width="32" height="32"></img> */}
                        <button type="button" 
                          className="btn bi bi-arrow-down-circle bg-white mb-1" 
                          style={{'width': '40px', 'height': '37px'}}
                          onClick={() => onMinus()}
                        >
                        </button>
                        <Field id="tahun" name="tahun" type="text" 
                          onChange={e => setThnTrn(e.target.value)} 
                          className="text-center d-inline mt-2 border-2 border-primary mx-1" 
                          style={{'width': '70px', 'height': '37px'}}
                          disabled={true}
                        />
                        <ErrorMessage name="tahun" component="div" className="invalid-feedback" />
                        <button type="button" 
                          className="btn bi bi-arrow-up-circle bg-white mb-1" 
                          style={{'width': '40px', 'height': '37px'}}
                          onClick={() => onPlus()}
                        >
                        </button>
                      </div>
                        <button 
                          type="button" 
                          className="btn btn-outline-secondary text-dark bg-white ms-3 mb-1"
                          style={{'width': '75px'}}
                        > 
                          Bulan 
                        </button>
                        <Field as='select' name="bulan" value={blnTrn} 
                          onChange={e => setBlnTrn(e.target.value)} 
                          className="d-inline ms-2 border-2 border-primary" 
                          style={{'width': '130px', 'height': '37px'}}
                        >
                          {namaBulan.map((namaBulan, index) => {
                            return (
                              <option key={index} value={index+1}>
                                {namaBulan}
                              </option>
                            )
                          })}
                        </Field>
                        <ErrorMessage name="bulan" component="div" className="invalid-feedback" />
                        <button 
                          type="button" 
                          onClick={() => onProses()} 
                          className="btn btn-primary d-inline ms-5 mb-1"
                          style={{'width': '75px', 'height': '37px'}}
                        > 
                          Proses 
                        </button>
                    </Row>
                  </Form>
                  <hr/>
                  {bukuKasList ?
                    (bukuKasList.length === 1 
                      ? "Belum ada data" 
                      :
                        <Row> 
                          <Col>
                            <Row className="justify-content-center mt-0 pt-0">
                              <Col className="text-start col-3">
                                <p className="text-start small"> Periode: {tglPeriode} </p>
                              </Col>
                              <Col className="text-start col-6">
                                <p className="text-center fw-bold my-0"> BUKU KAS TAHUN {thnTrn} </p>
                              </Col>
                              <Col className="text-end col-3 mb-1">
                                <button type="button"
                                  className = "btn btn-labeled btn-outline-success fw-bold btn-sm"
                                  // onClick={() => CetakNeraca(
                                  //   namaGereja, 
                                  //   tglPeriode, 
                                  //   neraca.filter(neraca => neraca.Level <= lvlTrn)
                                  // )}
                                > 
                                  <span className="btn-label me-2"><i className="bi bi-printer-fill"></i></span>
                                  Cetak
                                </button>
                              </Col>
                            </Row>
                            { isLoading &&
                              <>
                                <div className="w-100 d-flex mt-3 justify-content-center">
                                  <Row className="w-20 justify-content-center">
                                    <img 
                                      src={imgLogo} 
                                      width="15%" 
                                      className="rounded-circle img-thumbnail border-danger" 
                                      alt="GKJ"
                                    ></img>
                                  </Row>
                                </div>
                                <div className="w-100 mt-3 justify-content-center">
                                  <Row className="text-center">
                                    <strong> Loading ... </strong>
                                  </Row>
                                  <Row className="mb-3 justify-content-center">
                                    <div 
                                      className="spinner-grow mt-2 text-primary" 
                                      role="status" 
                                      aria-hidden="true"
                                    >
                                    </div>
                                  </Row>
                                </div>
                                <hr/>
                              </>
                            }
                            { isError && (<div> Data buku kas tidak dapat diakses </div>)}
                            { isSuccess &&
                              <>
                                {bukuKasList && 
                                  <Table striped bordered hover className="small w-100" size="sm" responsive="lg">
                                    <thead style={colStyle}> 
                                      <tr>
                                        <th rowSpan="3" className='text-center align-middle w-10'> Tanggal </th>
                                        <th rowSpan="3" className='text-center align-middle w-10'> Nomor Bukti </th>
                                        <th rowSpan="3" className='text-start align-middle w-30'> Uraian Transaksi </th>
                                        <th rowSpan="3" className='text-center align-middle w-5'> Kelompok </th>
                                        <th className='text-center w-15'> Penerimaan </th>
                                        <th className='text-center w-15'> Pengeluaran </th>
                                        <th rowSpan="2" className='text-center align-middle w-15'> Saldo </th>
                                      </tr>
                                      <tr style={colStyle}>
                                        <th className='text-center'> (Debet) </th>
                                        <th className='text-center'> (Kredit) </th>
                                      </tr>
                                      <tr>
                                        <th className='text-center'> (Rp.) </th>
                                        <th className='text-center'> (Rp.) </th>
                                        <th className='text-center'> (Rp.) </th>
                                      </tr>
                                    </thead> 
                                    <tbody className="fw-bold dark-background" style={{fontSize: "12px"}}>
                                      {bukuKasList && 
                                        bukuKasList.filter((item) => item.idGereja !== 'nf').map((buku, index) => (
                                          <tr key={index}>
                                            <td className="text-center bg-white"> 
                                              {buku.tanggal === '' ? '' : moment(buku.tanggal).format('DD-MMM-YYYY')} 
                                            </td>
                                            <td className="text-center bg-white"> 
                                              {buku.uraianTransaksi === 'bariskosong' ? '\u00A0' : buku.nomorBukti} 
                                            </td> 
                                            <td className="text-start bg-white"> 
                                              {buku.uraianTransaksi === 'bariskosong' ? '' : buku.uraianTransaksi} 
                                            </td> 
                                            <td className="text-center bg-white"> 
                                              {buku.uraianTransaksi === 'bariskosong' ? '' : buku.kelompok} 
                                            </td> 
                                            <td className="text-end bg-white"> 
                                              {
                                                buku.uraianTransaksi.match('Saldo Awal') != null || 
                                                  buku.uraianTransaksi === 'bariskosong' ? '' : 
                                                  (
                                                    (buku.uraianTransaksi.match('Jumlah s.d. Bulan') === null 
                                                      && buku.uraianTransaksi.match('Jumlah Bulan') === null 
                                                      && buku.penerimaan === 0) 
                                                      ? '' 
                                                      : <NumberFormat 
                                                        displayType={'text'} 
                                                        thousandSeparator={'.'} 
                                                        decimalSeparator={','} 
                                                        decimalScale={2} 
                                                        fixedDecimalScale={true} 
                                                        allowNegative={true} 
                                                        value={buku.penerimaan} 
                                                      /> 
                                                  )
                                              }
                                            </td> 
                                            <td className="text-end bg-white"> 
                                              {
                                                buku.uraianTransaksi.match('Saldo Awal') != null || 
                                                  buku.uraianTransaksi === 'bariskosong' 
                                                    ? '' 
                                                    : ( 
                                                      (buku.uraianTransaksi.match('Jumlah s.d. Bulan') === null 
                                                        && buku.uraianTransaksi.match('Jumlah sulan') === null 
                                                        && buku.pengeluaran === 0) 
                                                        ? '' 
                                                        : <NumberFormat 
                                                          displayType={'text'} 
                                                          thousandSeparator={'.'} 
                                                          decimalSeparator={','} 
                                                          decimalScale={2} 
                                                          fixedDecimalScale={true} 
                                                          allowNegative={true} 
                                                          value={buku.pengeluaran} 
                                                        /> 
                                                    )
                                              }                                    
                                            </td> 
                                            <td className="text-end bg-white"> 
                                              {
                                                buku.uraianTransaksi === 'bariskosong' ? '' : 
                                                <NumberFormat 
                                                  displayType={'text'} 
                                                  thousandSeparator={'.'} 
                                                  decimalSeparator={','} 
                                                  decimalScale={2} 
                                                  fixedDecimalScale={true} 
                                                  allowNegative={true} 
                                                  value={buku.saldo} 
                                                /> 
                                              }
                                            </td> 
                                          </tr> 
                                        ))
                                      }
                                    </tbody>
                                  </Table>
                                }
                              </>
                            }
                          </Col>
                          <Row className="justify-content-start tglfootnote footnote mt-0">
                            <div className="fst-italic text-start text-muted small"> gkj-ag-wr-2022 </div>
                          </Row>                                
                        </Row>
                    )
                  :
                    <Row>
                      <div className="w-100 mt-5 text-center">
                        <img 
                          src={imgLogo} 
                          width="15%" 
                          className="rounded-circle img-thumbnail border-danger" 
                          alt="GKJ"
                        ></img>
                      </div>
                    </Row>
                  }
                </Container>
              )
            }} 
          </Formik> 
        </Container>
      </div>
    </div>
  )
}

export default BukuKas