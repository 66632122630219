import React from 'react'
import useAuth from '../../hooks/useAuth'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

import "./edituser.scss"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Row, Col } from 'react-bootstrap'

import moment from 'moment'
import DatePickerField from '../../components/form/DatePickerField'
// import { EditUserValidationSchema } from './EditUserValidationSchema'
import TextError from '../../components/form/TextError'
import { axiosPrivate } from '../../api/axios'

require('moment/locale/id')
let config = ""
toast.configure()

const EditUser = ({onClickHandleEdit, idGereja, idUserMaint, nomorIdUser}) => {
  
  window.scrollTo(0, 0)
  const { auth } = useAuth()

  // const apiUrlUser = "/api/user"
  const apiUrlUser = auth.API_URL + "/user"

  let menuScreenClassName = 'w-100 justify-content-end text-center border-0 fw-bold text-light'
  let btnTambahClassName = 'btn btn-outline-primary btn-sm fst-italic fw-bold'
  let btnUbahClassName = 'btn btn-outline-success btn-sm fst-italic fw-bold'
  let btnConfirmClassName = 'btn btn-sm fst-italic fw-bold text-light'
  let btnCancelClassName = 'btn btn-dark btn-sm fst-italic fw-bold text-light'
  let btnResetClassName = 'btn btn-outline-danger btn-sm fst-italic fw-bold text-dark'

  const statusUserOpt = [
    {key: 'Pilih status', value: ''},
    {key: 'Aktif', value: 'Aktif'},
    {key: 'Tunda', value: 'Tunda'},
    {key: 'Keluar', value: 'Keluar'},
    {key: 'Lainnya', value: 'Lainnya'},
  ]

  const initialValues = {
    idUser: 0,
    userEmail: '',
    namaDepan: '',
    namaBelakang: '',
    namaUser: '',
    idGereja: idGereja,
    alamat: '',
    ijinAplikasi: '',
    levelUser: 0,
    mobileNo: '',
    statusUser: '',
    tanggalStatus: '',
    keterangan: '',
    userAdd: 0,
    userActivity: '',
    tanggalSystem: moment(),
    menuScreen: 'Idle',
    menuClass: 'w-100 justify-content-end text-center border-0 fw-bold text-light bg-info',
    fieldDisable: true,
    btnMenuDisable: false,
    btnTambahClass: 'btn btn-outline-primary btn-sm fst-italic fw-bold',
    btnUbahClass: 'btn btn-outline-success btn-sm fst-italic fw-bold',
    btnConfirmClass: 'btn btn-sm fst-italic fw-bold text-light invisible',
    btnCancelClass: 'btn btn-dark btn-sm fst-italic fw-bold text-light invisible',
    btnResetClass: 'btn btn-outline-danger btn-sm fst-italic text-dark fw-bold invisible',
    btnConfirmText: ''
  }

  const isiData = () => {
    initialValues.menuScreen = 'Idle'
    initialValues.idUser = userDetail.idUser
    initialValues.userEmail = userDetail.userEmail
    initialValues.namaDepan = userDetail.namaDepan
    initialValues.namaBelakang = userDetail.namaBelakang
    initialValues.namaUser = userDetail.namaDepan.concat(' ', userDetail.namaBelakang ? userDetail.namaBelakang : '')
    initialValues.idGereja = idGereja
    initialValues.alamat = userDetail.alamat
    initialValues.ijinAplikasi = userDetail.ijinAplikasi
    initialValues.levelUser = userDetail.levelUser
    initialValues.mobileNo = userDetail.mobileNo
    initialValues.statusUser = userDetail.status
    initialValues.tanggalStatus = userDetail.tanggalStatus
    initialValues.keterangan = userDetail.keterangan
    initialValues.userAdd = userDetail.userAdd
    initialValues.userActivity = userDetail.userActivity
    initialValues.tanggalSystem = userDetail.tanggalSystem
    initialValues.fieldDisable = true
    initialValues.btnMenuDisable = false
  }

  // import { useUserDetail, useAddUser, useUpdateUser, useResetUser }

  const axiosPrivateQ = useAxiosPrivate()
  const queryClient = useQueryClient()

  const fetchUser = (nomorIdUser) => {
    return axiosPrivate.get(`${apiUrlUser}/${nomorIdUser}`)
  }

  const useUserDetail = (nomorIdUser, onSuccess, onError) => {
    return useQuery(
      ['user', nomorIdUser], 
      () => fetchUser(nomorIdUser), 
      {
        onSuccess,
        onError,
        select: (data) => {
          const userDetail = data.data[0]
          return userDetail
        }
      }
    )
  }

  const addUser = (input) => {
    config = {
      method : "post",
      url : `${apiUrlUser}/`,
      headers : {
        "Content-Type":"application/json"
      },
      data : {
        userEmail: input.userEmail,
        namaDepan: input.namaDepan,
        namaBelakang: input.namaBelakang,
        idGereja: input.idGereja,
        alamat: input.alamat,
        ijinAplikasi: input.ijinAplikasi,
        levelUser: input.levelUser,
        mobileNo: input.mobileNo,
        statusUser: input.statusUser,
        tanggalStatus: input.tanggalStatus,
        keterangan: input.keterangan,
        foto: input.foto,
        userAdd: input.userAdd
      }
    }
    return axiosPrivateQ(config)
  }

  const useAddUser = () => {
    return useMutation(
      addUser, 
      {
        onError: () => {
          toast.error('Data user gagal ditambah !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: () => {
          toast.success('Data user berhasil ditambah ...', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
          queryClient.invalidateQueries()
        },
      }
    )
  }

  const updateUser = (input) => {
    config = {
      method : "patch",
      url : `${apiUrlUser}/${input.idUser}`,
      headers : {
        "Content-Type":"application/json"
      },
      data : {
        userEmail: input.userEmail,
        namaDepan: input.namaDepan,
        namaBelakang: input.namaBelakang,
        alamat: input.alamat,
        ijinAplikasi: input.ijinAplikasi,
        levelUser: input.levelUser,
        mobileNo: input.mobileNo,
        statusUser: input.statusUser,
        tanggalStatus: input.tanggalStatus,
        keterangan: input.keterangan,
        foto: input.foto,
        userAdd: input.userAdd
      }
    }
    return axiosPrivateQ(config)
  }

  const useUpdateUser = () => {
    return useMutation(
      updateUser, 
      {
        onError: () => {
          toast.error('Data user gagal diubah !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: () => {
          toast.success('Data user berhasil diubah ...', {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 2000
          })
          queryClient.invalidateQueries()
        },
      }
    )
  }

  const resetUser = (input) => {
    config = {
      method : "patch",
      url : `${apiUrlUser}/reset/${input.idUser}`,
      headers : {
        "Content-Type":"application/json"
      },
      data : {
        userAdd: input.userAdd
      }
    }
    return axiosPrivateQ(config)
  }

  const useResetUser = () => {
    return useMutation(
      resetUser, 
      {
        onError: () => {
          toast.error('Password gagal direset !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: () => {
            toast.success('Password user berhasil direset ...', {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000
            })
            queryClient.invalidateQueries()
        },
      }
    )
  }

  const { isLoading, data: userDetail } = useUserDetail(nomorIdUser)
  userDetail && isiData()

  const { mutate: addUsr } = useAddUser()
  const { mutate: updateUsr } = useUpdateUser()
  const { mutate: resetUsr } = useResetUser()
  const onClickConfirm = (input) => {
    if (input.menuScreen === "A D D") {
        addUsr(input)
        onClickHandleEdit(false)
    }
    if (input.menuScreen === "UPDATE") {
        updateUsr(input)
        onClickHandleEdit(false)
    }
  }

  const onClickReset = (input) => {
    resetUsr(input)
    onClickHandleEdit(false)
  }

  const clickExit = () => {
    onClickHandleEdit(false)
  }

  if (isLoading) {
    return (
      <div>
        <button className="btn btn-primary mb-3" type="button" disabled>
          <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          <span className="sr-only ms-3">Loading...</span>
        </button>
      </div>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={EditUserValidationSchema}
      enableReinitialize
    >
      {formik => {
        // function screenIdle
        const screenIdle = () => {
          formik.setFieldValue('menuScreen', 'Idle')
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'visible'))
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-primary'))
          // show buttonTU => 'FF'
          formik.setFieldValue('btnMenuDisable', false)
          // hidden button konfirmasi
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnResetClass', btnResetClassName.concat(' ', 'invisible'))
          // disable semua field
          formik.setFieldValue('fieldDisable', true)
        }
        // function clickTambah
        const clickTambah = () => {
          formik.setFieldValue('userEmail', '')
          formik.setFieldValue('namaDepan', '')
          formik.setFieldValue('namaBelakang', '')
          formik.setFieldValue('alamat', '')
          formik.setFieldValue('ijinAplikasi', '')
          formik.setFieldValue('levelUser', 0)
          formik.setFieldValue('mobileNo', '')
          formik.setFieldValue('statusUser', '')
          formik.setFieldValue('tanggalStatus', moment().format("YYYY-MM-DD"))
          formik.setFieldValue('keterangan', '')
          formik.setFieldValue('userAdd', idUserMaint)
          formik.setFieldValue('menuScreen', 'A D D')
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-primary'))
          // hidden buttonTU => [T]ambah - [U]bah
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'invisible'))
          // show button konfirmasi (Tambahkan data ... & Batal)
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'bg-primary', ' ', 'visible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnConfirmText', 'Tambahkan data ...')
          // enable semua field
          formik.setFieldValue('fieldDisable', false)
        }
        // function clickUbah
        const clickUbah = () => {
          formik.setFieldValue('userAdd', idUserMaint)
          formik.setFieldValue('menuScreen', 'UPDATE')
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-success'))
          // hidden buttonTU
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'invisible'))
          // show button konfirmasi (Ubah data ... & Batal)
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'bg-primary', ' ', 'visible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnResetClass', btnResetClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnConfirmText', 'Ubah data ...')
          // enable semua field
          formik.setFieldValue('fieldDisable', false)
        }
        return (
          <div className="container">
            <Form className="w-100 ms-2">
              <Row className="fw-bold mb-2">
                <Field name="fieldDisable" type="hidden"/>
                <Field name="menuClass" type="hidden"/>
                <Field name="btnTambahClass" type="hidden"/>
                <Field name="btnUbahClass" type="hidden"/>
                <Field name="btnMenuDisable" type="hidden"/>
                <Field name="btnConfirmClass" type="hidden"/>
                <Field name="btnCancelClass" type="hidden"/>
                <Field name="btnResetClass" type="hidden"/>
                <Field name="btnConfirmText" type="hidden"/>
                <Col md={9} sm={6} xs={6}>
                  <p className="mb-2">
                    <span className="text-primary fw-bold"> <i className="bi bi-person-circle"></i> </span> &nbsp; 
                    Nama User &nbsp; : &nbsp; <strong>{formik.values.namaUser}</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                    ID# &nbsp; : &nbsp; {formik.values.idUser}
                  </p>
                </Col>
                <Col md={3} sm={3} xs={3}>
                  <Field name="menuScreen" disabled={true} className={formik.values.menuClass}/>
                </Col>
              </Row>
              <hr/>
              <Row className="fw-bold">
                <Col className="form-group mt-2 col-8 justify-content-start">
                  <label className="text-primary w-10" htmlFor="namaDepan"> Nama </label>
                  <Field className="text-start w-40" id="namaDepan" name="namaDepan" disabled={formik.values.fieldDisable} placeholder="Nama Depan"/>
                  <Field className="text-start w-40 ms-1" id="namaBelakang" name="namaBelakang" disabled={formik.values.fieldDisable} placeholder="Nama Belakang"/>
                  <ErrorMessage name="namaDepan" component={TextError} className="invalid-feedback" />
                </Col>
                <Col className="form-group mt-2 col-4 justify-content-end">
                  <label className="text-primary w-20" htmlFor="userEmail"> Email </label>
                  <Field className="text-start w-80" id="userEmail" name="userEmail" disabled={formik.values.fieldDisable}/>
                  <ErrorMessage name="userEmail" component={TextError} className="invalid-feedback" />
                </Col>
              </Row>
              <hr/>
              <Row className="justify-content-between">
                <Col className="form-group mt-2 col-2">
                  <label className="text-primary w-40" htmlFor="levelUser"> Level </label>
                  <Field className="text-center w-40" id="levelUser" name="levelUser" disabled={formik.values.fieldDisable}/>
                  <ErrorMessage name="levelUser" component={TextError} className="invalid-feedback" />
                </Col>
                <Col className="form-group mt-2 col-3">
                  <label className="text-primary w-40" htmlFor="statusUser"> Status </label>
                  <Field as='select' className="w-50" id="statusUser" name="statusUser" disabled={formik.values.fieldDisable}>
                    {statusUserOpt.map(option => {
                      return (
                        <option key={option.value} value={option.value}>
                          {option.key}
                        </option>
                      )
                    })}
                  </Field>
                  <ErrorMessage name="statusUser" component={TextError} className="invalid-feedback" />
                </Col>
                <Col className="form-group mt-2 col-4">
                  <Row>
                    <Col className="col-3">
                      <label className="text-primary" htmlFor="tanggalStatus"> Tanggal </label>
                    </Col>
                    <Col className="col-7">
                      <DatePickerField className="text-center" id="tanggalStatus" name="tanggalStatus" disabled={formik.values.fieldDisable}/>
                    </Col>
                  </Row>
                </Col>
                <Col className="form-group mt-2 col-3">
                  <label className="text-primary w-40" htmlFor="mobileNo"> No. HP </label>
                  <Field className="text-center w-60" id="mobileNo" name="mobileNo" disabled={formik.values.fieldDisable}/>
                  <ErrorMessage name="mobileNo" component={TextError} className="invalid-feedback" />
                </Col>
              </Row>
              <Row className="justify-content-between">
                <Col className="form-group mt-3 col-12">
                  <label className="text-primary w-15" htmlFor="alamat"> Alamat </label>
                  <Field className="text-start w-80" id="alamat" name="alamat" disabled={formik.values.fieldDisable}/>
                </Col>
              </Row>
              <Row>
                <Col className="form-group mt-3 col-12">
                  <label className="text-primary w-15" htmlFor="keterangan"> Keterangan </label>
                  <Field className="text-start w-80" id="keterangan" name="keterangan" disabled={formik.values.fieldDisable}/>
                </Col>
              </Row>
              <hr/>
              <Row className="fw-bold small">
                <p>
                  <span className="text-warning"> <i className="bi bi-x-circle-fill"></i> </span> &nbsp; 
                    Maintenance : {formik.values.userActivity} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                  <span className="text-warning fw-bold"> <i className="bi bi-arrow-right-circle"></i> </span> &nbsp; 
                    Id User Maint : {formik.values.userAdd} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="text-warning fw-bold"> <i className="bi bi-arrow-right-circle"></i> </span> &nbsp; 
                    Tanggal Maintenance : {moment(formik.values.tanggalSystem).format('LLLL')} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
              </Row>
              <Row className="fw-bold small">
                <Col className="justify-content-start">
                  <button 
                    type="button" 
                    className={formik.values.btnConfirmClass}
                    disabled={
                      (formik.values.namaDepan && formik.values.userEmail &&
                        formik.values.levelUser > 0 && formik.values.levelUser <= 5) 
                      ? false : true}
                    onClick={() => onClickConfirm(formik.values)}
                  > {formik.values.btnConfirmText} </button>
                  &nbsp;&nbsp;&nbsp;
                  <button 
                    type="button" 
                    className={formik.values.btnCancelClass} 
                    onClick={() => formik.values.menuScreen === "UPDATE" ? screenIdle() : onClickHandleEdit(false)}
                  > Batal </button>
                  &nbsp;&nbsp;&nbsp;
                  <button 
                    type="button" 
                    className={formik.values.btnResetClass} 
                    onClick={() => onClickReset(formik.values)}
                  > Reset Password </button>
                </Col>
                <Col className="text-end">
                  <button 
                    type="button" 
                    className={formik.values.btnTambahClass} 
                    disabled={formik.values.btnMenuDisable}
                    onClick={() => clickTambah()}
                  > Tambah </button>
                  &nbsp;&nbsp;&nbsp;
                  <button 
                    type="button" 
                    className={formik.values.btnUbahClass} 
                    disabled={formik.values.btnMenuDisable} 
                    onClick={() => clickUbah()}
                  > Ubah </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button 
                    type="button"
                    className='btn btn-outline-secondary btn-sm fst-italic fw-bold'
                    onClick={() => clickExit()}
                  > Keluar </button>
                </Col>                                
              </Row>
              <hr/>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

export default EditUser