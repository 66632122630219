import React, { useState } from "react"
import useAuth from '../../hooks/useAuth'

import "./danadukungan.scss"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Row, Col, Container, Table } from 'react-bootstrap'

import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import { useDanaDukunganList } from '../../components/data/UseDataLaporan'

import NumberFormat from 'react-number-format'
import Logo from '../../images/Logo'

const imgLogo = Logo()

let thn = 0

const colStyle = {
  backgroundColor: '#004b48',
  color: "#00ffff",
  fontSize: "11px",
}

const DanaDukungan = () => {
  const { auth } = useAuth()
  const idGereja = auth.authIdGereja
  // const namaGereja = auth.authNamaGereja
  const idBakowi = auth.authIdBakowi
  // const namaBadanKomisi = auth.authNamaBakowi
  const tahunMulai = auth.authTahunMulai
  // const idUser = auth.authIdUser

  const [thnTrn, setThnTrn] = useState((new Date()).getFullYear())
  const [keyFromButton, setKeyFromButton] = useState({tahun: thnTrn})

  window.scrollTo(0, 0)

  const onProses = () => {
    setKeyFromButton({ ...keyFromButton, tahun: thnTrn })
  }

  const { data: danaDukunganList, isLoading, isSuccess, isError } = useDanaDukunganList(idGereja, idBakowi, thnTrn)

  const onMinus = () => {
    thn = thnTrn.valueOf()
    if (thn > tahunMulai) setThnTrn(thn - 1)
  }
  const onPlus = () => {
    thn = thnTrn.valueOf()
    if (thn < (new Date()).getFullYear()) setThnTrn(thn - 1 + 2)
  }

  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <h3 className="ms-4 mt-3"> Dana Dukungan Majelis </h3>
        <Container className="w-100">
          <Formik
            initialValues={{ tahun: thnTrn }}
            // validationSchema={TahunBulanValidationSchema}
            enableReinitialize
          >
            {formik => {
              return (
                <Container className="w-100">
                  <Form>
                    <hr/>
                    <Row className="text-center d-block h-auto">
                      <button 
                        type="button" 
                        className="btn btn-outline-secondary text-dark bg-white d-inline mb-1"
                        style={{'width': '75px'}}
                      > 
                        Tahun 
                      </button>
                      <div className="input-group d-inline">
                        <button type="button" 
                          className="btn bi bi-arrow-down-circle mb-1 bg-white" 
                          style={{'width': '40px', 'height': '37px'}}
                          onClick={() => onMinus()}
                        >
                        </button>
                        <Field id="tahun" name="tahun" type="text" 
                          onChange={e => setThnTrn(e.target.value)} 
                          className="text-center d-inline mt-2 border-2 border-primary mx-1" 
                          style={{'width': '70px', 'height': '37px'}}
                          disabled={true}
                        />
                        <ErrorMessage name="tahun" component="div" className="invalid-feedback" />
                        <button type="button" 
                          className="btn bi bi-arrow-up-circle mb-1 bg-white" 
                          style={{'width': '40px', 'height': '37px'}}
                          onClick={() => onPlus()}
                        >
                        </button>
                      </div>
                      <button 
                          type="button" 
                          onClick={() => onProses()} 
                          className="btn btn-primary d-inline ms-5 mb-1"
                          style={{'width': '75px', 'height': '37px'}}
                      > 
                        Proses 
                      </button>
                    </Row>
                  </Form>
                  <hr/>
                  {danaDukunganList ?
                    (danaDukunganList.length === 1 
                      ? "Belum ada data" 
                      :
                        <Row> 
                          <Col>
                            <Row className="justify-content-center mt-0 pt-0">
                              <p className="text-center fw-bold"> DANA DUKUNGAN MAJELIS TAHUN {thnTrn} </p>
                            </Row>
                            { isLoading &&
                              <>
                                <div className="w-100 d-flex mt-3 justify-content-center">
                                  <Row className="w-20 justify-content-center">
                                    <img 
                                      src={imgLogo} 
                                      width="15%" 
                                      className="rounded-circle img-thumbnail border-danger" 
                                      alt="GKJ"
                                    ></img>
                                  </Row>
                                </div>
                                <div className="w-100 mt-3 justify-content-center">
                                  <Row className="text-center">
                                    <strong> Loading ... </strong>
                                  </Row>
                                  <Row className="mb-3 justify-content-center">
                                    <div 
                                      className="spinner-grow mt-2 text-primary" 
                                      role="status" 
                                      aria-hidden="true"></div>
                                  </Row>
                                </div>
                                <hr/>
                              </>
                            }
                            { isError && (<div> Data laporan dana dukungan tidak dapat diakses </div>)}
                            { isSuccess &&
                              <>
                                <Table striped bordered hover className="small w-100" size="sm" responsive="lg">
                                  <thead style={colStyle}> 
                                    <tr> 
                                      <th rowSpan='3' className='text-center col-2 align-middle'> Program </th> 
                                      <th rowSpan='3' className='text-end col-1 align-middle'> Anggaran </th> 
                                      <th colSpan='6' className='text-center col-6'> Bulan Pencairan Dana </th> 
                                      <th rowSpan='2' colSpan='2' className='text-center col-2 align-middle'> 
                                        Realisasi 
                                      </th> 
                                      <th rowSpan='3' className='text-end col-1 align-middle'> Sisa Anggaran </th> 
                                    </tr> 
                                    <tr style={colStyle}> 
                                      <th className='text-end'> Januari </th> 
                                      <th className='text-end'> Pebruari </th> 
                                      <th className='text-end'> Maret </th> 
                                      <th className='text-end'> April </th> 
                                      <th className='text-end'> Mei </th> 
                                      <th className='text-end'> Juni </th> 
                                    </tr> 
                                    <tr> 
                                      <th className='text-end'> Juli</th> 
                                      <th className='text-end'> Agustus </th> 
                                      <th className='text-end'> September </th> 
                                      <th className='text-end'> Oktober </th> 
                                      <th className='text-end'> Nopember </th> 
                                      <th className='text-end'> Desember </th> 
                                      <th className='text-end'> Nilai </th> 
                                      <th className='text-center'> % </th> 
                                    </tr> 
                                  </thead> 
                                  <tbody className="fw-bold dark-background" style={{fontSize: "10px"}}> 
                                    {danaDukunganList && 
                                      danaDukunganList.filter((item) => item.idGereja !== 'nf').map((dana, index) => ( 
                                        <> 
                                          <tr key={index}> 
                                            <td rowSpan='2' className="text-start bg-white"> {dana.namaProgram} </td> 
                                            <td rowSpan='2' className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','} 
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={dana.anggaran} 
                                              /> 
                                            </td> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','} 
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={dana.jan} 
                                              /> 
                                            </td> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','}
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={dana.peb} 
                                              /> 
                                            </td> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','}
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={dana.mar} 
                                              /> 
                                              </td> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','}
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={dana.apr} 
                                              /> 
                                            </td> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','}
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={dana.mei} 
                                              /> 
                                            </td> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','}
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={dana.jun} 
                                        /> 
                                            </td> 
                                            <td rowSpan='2' className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','}
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={dana.realisasi} 
                                              /> 
                                            </td> 
                                            <td rowSpan='2' className="text-center bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','}
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={dana.pctRealisasi} 
                                              /> 
                                            </td> 
                                            <td rowSpan='2' className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','}
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={dana.sisaAnggaran} 
                                              /> 
                                            </td> 
                                          </tr> 
                                          <tr key={index}> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','}
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={dana.jul} 
                                              /> 
                                            </td> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','}
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={dana.agu} 
                                              /> 
                                            </td> 
                                            <td className="text-end bg-white"> 
                                            <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','}
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                    value={dana.sep} 
                                              /> 
                                            </td> 
                                            <td className="text-end bg-white"> 
                                            <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','}
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 

                                                    value={dana.okt} 
                                              /> 
                                            </td> 
                                            <td className="text-end bg-white"> 
                                            <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','}
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 

                                                    value={dana.nop} 
                                              /> 
                                          </td> 
                                            <td className="text-end bg-white"> 
                                              <NumberFormat 
                                                displayType={'text'} 
                                                thousandSeparator={'.'} 
                                                decimalSeparator={','}
                                                decimalScale={2} 
                                                fixedDecimalScale={true} 
                                                allowNegative={true} 
                                                value={dana.des} 
                                              /> 
                                            </td> 
                                          </tr> 
                                        </> 
                                    ))}
                                  </tbody>
                                </Table>
                              </>
                            }
                          </Col>
                          <Row className="justify-content-start tglfootnote footnote mt-0">
                              <div className="fst-italic text-start text-muted small"> gkj-ag-wr-2022 </div>
                          </Row>                                
                        </Row>
                    )
                  :
                    <Row>
                      <div className="w-100 mt-5 text-center">
                        <img 
                          src={imgLogo} 
                          width="15%" 
                          className="rounded-circle img-thumbnail border-danger" 
                          alt="GKJ"
                        ></img>
                      </div>
                    </Row>
                  }
                </Container>
              )
            }} 
          </Formik> 
        </Container>
      </div>
    </div>
  )
}

export default DanaDukungan