import React, { useEffect, useState } from "react"
import useAuth from '../../hooks/useAuth'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

import "./maintsaldoawal.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Table from "../../components/table/Table"
import EditSaldoAwal from "./EditSaldoAwal"

import { Row, Container } from "react-bootstrap"
import "react-toastify/dist/ReactToastify.css"

import NumberFormat from "react-number-format"
import Logo from "../../images/Logo"

const imgLogo = Logo()

toast.configure()

const MaintSaldoAwal = () => {

  window.scrollTo(0, 0);
  const { auth } = useAuth()
  
  // const apiUrlAG = "/api/ag-saldoawal"
  const apiUrlAG = auth.API_URL + "/ag-saldoawal"

  const idGereja = auth.authIdGereja
  const idBakowi = auth.authIdBakowi
  const idUser = auth.authIdUser
  const [nomorAkunSaldoAwal, setNomorAkunSaldoAwal] = useState([])
  const [editDetail, setEditDetail] = useState(false)
  const [adaData, setAdaData] = useState(false)

  const axiosPrivate = useAxiosPrivate()

  const fetchSaldoAwal = (idGereja, idBakowi) => {
    return axiosPrivate.get(`${apiUrlAG}/saldoawal/${idGereja}/${idBakowi}`)
  }

  const useSaldoAwalList = (idGereja, idBakowi, onSuccess, onError) => {
    return useQuery(
      ['saldoawal', idGereja, idBakowi], 
      () => fetchSaldoAwal(idGereja, idBakowi), 
      {
        onSuccess,
        onError: () => {
          toast.error('Tidak dapat meng-akses data !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        select: (data) => {
          const saldoAwalList = data.data[0]
          return saldoAwalList
        }
      }
    )
  }

  const {data: saldoAwalList, isLoading, isSuccess, isError} = useSaldoAwalList(idGereja, idBakowi)

  useEffect(() => {
    setAdaData
      ( saldoAwalList && saldoAwalList[0]?.idGereja === 'nf' 
        ? false : true
      )
  }, [saldoAwalList])

  const saldoAwalTableHead = [
    // posisi judul [L]eft/[C]enter/[R]ight - nama judul
    "C-ID",
    "L-Nama Akun",
    "L-Tipe Akun",
    "L-Nama Bank",
    "C-Tahun Buku",
    "R-Nilai",
    "L-Keterangan",
  ];
  const renderHead = (item, index) => (
    <th
      key={index}
      className={
        item.substring(0, 1) === "C"
          ? "text-center"
          : item.substring(0, 1) === "R"
          ? "text-end"
          : "text-start"
      }
    >
      {item.substring(2)}
    </th>
  );

  const renderBody = (item, index) => (
    <tr onClick={() => onClickHandleEdit([item], true)} key={index}>
      <td className="text-center">{item.idAkunKasBank}</td>
      <td>{item.namaAkunKasBank}</td>
      <td>
        {item.tipeAkunKasBank === "K"
          ? "Kas"
          : item.tipeAkunKasBank === "B"
          ? "Bank"
          : "Tipe akun salah !"}
      </td>
      <td>{item.namaBank}</td>
      <td className="text-center">{item.tahunBuku ? item.tahunBuku : "N/A"}</td>
      <td className="text-end">
        {item.nilai != null ? (
          <NumberFormat
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={2}
            fixedDecimalScale={true}
            value={item.nilai}
          />
        ) : (
          "N/A"
        )}
      </td>
      <td>
        <span className={`status ${item.userActivity}`}>
          {item.userActivity ? item.userActivity : "N/A"}
        </span>
      </td>
    </tr>
  );

  const onClickEntry = () => {
    onClickHandleEdit("", true)
  };

  const onClickHandleEdit = (noAkunSaldoAwal, editDisplay) => {
    setNomorAkunSaldoAwal(noAkunSaldoAwal)
    setEditDetail(editDisplay)
  };

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <h3 className="ms-4 my-3"> Maintenance Saldo Awal </h3>
        {!editDetail && (
          <button
            type="button"
            className="ms-4 mb-2 btn btn-sm btn-outline-primary fst-italic fw-bold"
            onClick={() => onClickEntry()}
          >
            <span
              className="bi bi-plus-circle-dotted mx-3"
              style={{ fontSize: "21px" }}
            >
              {" "}
              Mulai Entry{" "}
            </span>
          </button>
        )}
        <Container className="w-100">
          {isLoading && !editDetail && (
            <>
              <div className="w-100 d-flex mt-3 justify-content-center">
                <Row className="w-25 justify-content-center">
                  <img
                    src={imgLogo}
                    width="15%"
                    className="rounded-circle img-thumbnail border-danger"
                    alt="GKJ"
                  ></img>
                </Row>
              </div>
              <div className="w-100 mt-3 justify-content-center">
                <Row className="text-center">
                  <strong> Loading ... </strong>
                </Row>
                <Row className="mb-3 justify-content-center">
                  <div
                    className="spinner-grow mt-2 text-primary"
                    role="status"
                    aria-hidden="true"
                  ></div>
                </Row>
              </div>
            </>
          )}
          {isError && <div> Data saldo awal tidak dapat diakses </div>}
          {isSuccess && (
            <div>
              <Row>
                {editDetail && (
                  <EditSaldoAwal
                    onClickHandleEdit={onClickHandleEdit}
                    idGereja={idGereja}
                    idBakowi={idBakowi}
                    idUserMaint={idUser}
                    nomorAkunSaldoAwal={nomorAkunSaldoAwal[0]}
                  ></EditSaldoAwal>
                )}
              </Row>
              <Row>
                { adaData 
                  ? 
                    <Table
                      onClickHandleEdit={onClickHandleEdit}
                      headData={saldoAwalTableHead}
                      renderHead={renderHead}
                      bodyData={saldoAwalList.filter((item) => item.idGereja !== 'nf')}
                      renderBody={renderBody}
                    />
                  : 
                    (<div> Belum ada data </div>)
                }
              </Row>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

export default MaintSaldoAwal
