import React from 'react'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import { TransaksiListTableColumnFilter } from './TransaksiListTableColumnFilter'
require('moment/locale/id')

export const TLCOLUMNS = [
  {
    Header: 'ID',
    accessor: 'idTransaksi',
    Cell: ({ value }) => <div style={{textAlign:"center"}}> <NumberFormat displayType={"text"} thousandSeparator={"."} decimalSeparator={","} decimalScale={0} fixedDecimalScale={true} value={value} /> </div>,
    Filter: TransaksiListTableColumnFilter,
    width: 60
  },
  {
    Header: 'Keterangan',
    accessor: 'keterangan',
    Filter: TransaksiListTableColumnFilter,
    width: 150
  },
  {
    Header: 'Kelompok Transaksi',
    accessor: 'namaKelompokTransaksi',
    Filter: TransaksiListTableColumnFilter,
    width: 150
  },
  {
    Header: 'Nama Akun',
    accessor: 'namaAkunKasBank',
    Filter: TransaksiListTableColumnFilter,
    width: 100
  },
  {
    Header: 'Program',
    accessor: 'namaProgram',
    Filter: TransaksiListTableColumnFilter,
    width: 150
  },
  {
    Header: 'Kegiatan',
    accessor: 'namaKegiatan',
    Filter: TransaksiListTableColumnFilter,
    width: 150
  },
  {
    Header: 'Voucher',
    accessor: 'nomorVoucher',
    Cell: ({value}) => <div style={{textAlign:"center"}}>{value}</div>,
    Filter: TransaksiListTableColumnFilter,
    width: 100
  },
  {
    Header: 'Tanggal',
    accessor: 'tanggalTransaksi',
    // moment(value).format('DD-MMMM-YYYY')
    // moment(value).format('LL')
    Cell: ({ value }) => { return (value.length>1) ? moment(value).format('LL') : ''},
    Filter: TransaksiListTableColumnFilter,
    width: 125
  },
  {
    Header: 'Nilai',
    accessor: 'nilaiTransaksi',
    Cell: ({value}) => <div style={{textAlign:"right"}}> <NumberFormat displayType={"text"} thousandSeparator={"."} decimalSeparator={","} decimalScale={2} fixedDecimalScale={true} value={value} /> </div>,
    Filter: TransaksiListTableColumnFilter,
    width: 115
  }
]