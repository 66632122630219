import React, { useContext } from "react"

import Login from "./pages/login/Login"
import Home from "./pages/home/Home"
import MaintProgram from "./pages/program/MaintProgram"
import EditProgram from "./pages/program/EditProgram"
import MaintKegiatan from "./pages/kegiatan/MaintKegiatan"
// import MaintBerita from './pages/berita/MaintBerita'
import TransaksiAdd from "./pages/transaksiadd/TransaksiAdd"
import TransaksiList from "./pages/transaksilist/TransaksiList"
import FormPengajuan from "./pages/formpengajuan/FormPengajuan"
import BukuKas from "./pages/bukukas/BukuKas"
import LaporanKeuangan from "./pages/laporankeuangan/LaporanKeuangan"
import DanaDukungan from "./pages/danadukungan/DanaDukungan"
import AnggaranKegiatan from "./pages/anggarankegiatan/AnggaranKegiatan"
// import InfoProgram from './pages/infoprogram/InfoProgram'
import MaintSaldoAwal from "./pages/saldoawal/MaintSaldoAwal"
import MaintAkunKasBank from "./pages/akunkasbank/MaintAkunKasBank"
import MaintKelompokTransaksi from "./pages/kelompoktransaksi/MaintKelompokTransaksi"
import MaintJenisProgram from "./pages/jenisprogram/MaintJenisProgram"
import MaintUser from "./pages/user/MaintUser"
import ProfileUser from "./pages/profileuser/ProfileUser"
import MaintUserAkses from "./pages/userakses/MaintUserAkses"
import RequiredAuth from "./components/RequireAuth"
import PersistLogin from "./components/PersistLogin"
import Missing from "./components/Missing"

import { BrowserRouter, Routes, Route } from "react-router-dom"

import "./style/dark.scss"

import { DarkModeContext } from "./context/darkModeContext"

// let host = window.location.host
// let host = 'ag.gkj.web.id'
// let protocol = window.location.protocol
// let parts = host.split(".")
// let subdomain = ""
// If we get more than 3 parts, then we have a subdomain
// INFO: This could be 4, if you have a co.uk TLD or something like that.
// if (parts.length >= 3) {
//     subdomain = parts[0]
//     // Remove the subdomain from the parts list
//     parts.splice(0, 1)
//     // Set the location to the new url
//     // window.location = protocol + "//" + parts.join(".") + "/" + subdomain
// }

// contoh penggunaan roles dibuat kodenya
// const ROLES = {
//     'User': 2001,
//     'Editor': 1984,
//     'Admin': 5150
// }


function App() {
  const { darkMode } = useContext(DarkModeContext)

  return (
    <BrowserRouter>
      <div className={darkMode ? "app dark bg-container" : "app bg-container"}>
        <Routes>
          {/* public routes */}
          <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />

          {/* protect routes */}
          <Route element={<PersistLogin />}>
            {/* contoh penggunaan roles */}
            {/* <Route element={<RequiredAuth allowerdRoles={[ROLES.User]} />}> */}
            <Route element={<RequiredAuth />}>
              <Route path="home" element={<Home />} />
              <Route path="maintprogram" element={<MaintProgram />} />
              <Route path="editprogram" element={<EditProgram />} />
              <Route path="maintkegiatan" element={<MaintKegiatan />} />
              {/* <Route path="maintberita" element={<MaintBerita />} /> */}
              <Route path="transaksiadd" element={<TransaksiAdd />} />
              <Route path="transaksilist" element={<TransaksiList />} />
              <Route path="formpengajuan" element={<FormPengajuan />} />
              <Route path="bukukas" element={<BukuKas />} />
              <Route path="laporankeuangan" element={<LaporanKeuangan />} />
              <Route path="danadukungan" element={<DanaDukungan />} />
              <Route path="anggarankegiatan" element={<AnggaranKegiatan />} />
              {/* <Route path="infoprogram" element={<InfoProgram />} /> */}
              <Route path="maintsaldoawal" element={<MaintSaldoAwal />} />
              <Route path="maintakunkasbank" element={<MaintAkunKasBank />} />
              <Route path="maintkelompoktransaksi" element={<MaintKelompokTransaksi />} />
              <Route path="maintjenisprogram" element={<MaintJenisProgram />} />
              <Route path="maintuser" element={<MaintUser />} />
              <Route path="profileuser" element={<ProfileUser />} />
              <Route path="maintuserakses" element={<MaintUserAkses />} />
            </Route>
          </Route>

          {/* catch all */}
          <Route path="*" element={<Missing />} />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App