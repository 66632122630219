import React, { useEffect, useState } from "react"
import useAuth from '../../hooks/useAuth'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

import "./maintkegiatan.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Table from "../../components/table/Table"
import { usePrgList, useWargaList } from '../../components/data/UseDataComponents'
import EditKegiatan from "./EditKegiatan"

import { Row, Container } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'

import NumberFormat from 'react-number-format'
import Logo from '../../images/Logo'
import moment from 'moment'

const imgLogo = Logo()

toast.configure()

const MaintKegiatan = () => {
  
  window.scrollTo(0, 0)
  const { auth } = useAuth()

  // const apiUrlAG = "/api/ag-kegiatan"
  const apiUrlAG = auth.API_URL + "/ag-kegiatan"

  const idGereja = auth.authIdGereja
  const idBakowi = auth.authIdBakowi
  const idUser = auth.authIdUser
  const [nomorIdKegiatan, setNomorIdKegiatan] = useState([])
  const [editDetail, setEditDetail] = useState(false)
  const [adaData, setAdaData] = useState(false)

  const axiosPrivate = useAxiosPrivate()

  const fetchKegiatan = (idGereja, idBakowi) => {
    return axiosPrivate.get(`${apiUrlAG}/kegiatan/${idGereja}/${idBakowi}`)
  }

  const useKegiatanList = (idGereja, idBakowi, onSuccess, onError) => {
    return useQuery(
      ['kegiatan', idGereja, idBakowi], 
      () => fetchKegiatan(idGereja, idBakowi), 
      {
        onSuccess,
        onError: () => {
          toast.error('Tidak dapat meng-akses data !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        select: (data) => {
          const kegiatanList = data.data[0]
          return kegiatanList
        }
      }
    )
  }

  const { data: kegiatanList, isLoading, isSuccess, isError } = useKegiatanList(idGereja, idBakowi)
  const { data: wargaList } = useWargaList(idGereja)
  const { data: prgList } = usePrgList(idGereja, idBakowi)

  useEffect(() => {
    setAdaData
      ( kegiatanList && kegiatanList[0]?.idGereja === 'nf' 
        ? false : true
      )
  }, [kegiatanList])


  const kegiatanTableHead = [
    // posisi judul [L]eft/[C]enter/[R]ight - nama judul
    'C-ID',
    'L-Nama Kegiatan',
    'L-Nama Program',
    'L-Tanggal Mulai',
    'L-Tanggal Selesai',
    'L-Prioritas',
    'C-Bobot',
    'L-Anggaran',
    'L-Penanggungjawab',
    'L-Status',
  ]
  const renderHead = (item, index) => (
    <th 
      key={index} 
      className={item.substring(0,1) === 'C' 
        ? 'text-center' 
        : (item.substring(0,1) === 'R' 
          ? 'text-end' 
          : 'text-start')
      }
    >
      {item.substring(2)}
    </th>
  )

  const renderBody = (item, index) => (
    <tr 
      onClick={() => onClickHandleEdit(
        [item],
        true
      )} 
      key={index}
    >
      <td className="text-center">{item.idKegiatan}</td>
      <td>{item.namaKegiatan}</td>
      <td>{item.namaProgram}</td>
      <td>{item.tanggalMulai && moment(item.tanggalMulai).format('DD-MMMM-YYYY')}</td>
      <td>{item.tanggalSelesai && moment(item.tanggalSelesai).format('DD-MMMM-YYYY')}</td>
      <td>
        {item.prioritas === null
          ? 'N/A' 
          : (item.prioritas === 'T' 
            ? 'Tinggi' 
            : (item.prioritas === 'S' 
              ? 'Sedang' 
              : (item.prioritas === 'R' 
                  ? 'Rendah'
                  : 'Kategori salah'
              )
            )
          )
        }
      </td>
      <td className="text-end">
        <NumberFormat
          displayType={"text"}
          thousandSeparator={"."}
          decimalSeparator={","}
          decimalScale={0}
          fixedDecimalScale={true}
          value={item.bobot}
        />
      </td>
      <td className="text-end">
        <NumberFormat
          displayType={"text"}
          thousandSeparator={"."}
          decimalSeparator={","}
          decimalScale={2}
          fixedDecimalScale={true}
          value={item.anggaran}
        />
      </td>
      <td>{item.namaPenanggungjawab}</td>
      <td>{item.statusKegiatan}</td>
    </tr>
  )

  const onClickEntry = () => {
    onClickHandleEdit('', true)
  }

  const onClickHandleEdit = (noIdKegiatan, editDisplay) => {
    setNomorIdKegiatan(noIdKegiatan)
    setEditDetail(editDisplay)
  }

  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <h3 className="ms-4 my-3"> Maintenance Kegiatan </h3>
        {!editDetail && 
          <button 
            type="button" 
            className="ms-4 mb-2 btn btn-sm btn-outline-primary fst-italic fw-bold" 
            onClick={() => onClickEntry()}
          > 
            <span className="bi bi-plus-circle-dotted mx-3" style={{fontSize: "21px"}}> Mulai Entry </span>
          </button>
        }
        <Container className="w-100">
          { isLoading && !editDetail && (
            <>
              <div className="w-100 d-flex mt-3 justify-content-center">
                <Row className="w-25 justify-content-center">
                  <img 
                    src={imgLogo} 
                    width="15%" 
                    className="rounded-circle img-thumbnail border-danger" 
                    alt="GKJ"
                  ></img>
                </Row>
              </div>
              <div className="w-100 mt-3 justify-content-center">
                <Row className="text-center">
                  <strong> Loading ... </strong>
                </Row>
                <Row className="mb-3 justify-content-center">
                  <div className="spinner-grow mt-2 text-primary" role="status" aria-hidden="true"></div>
                </Row>
              </div>
            </>
          )}
          { isError && (<div> Tabel kegiatan tidak dapat diakses </div>)}
          { isSuccess && (
            <div>
              <Row>
                {editDetail &&
                  <EditKegiatan
                    onClickHandleEdit = {onClickHandleEdit}
                    idGereja = {idGereja}
                    idBakowi = {idBakowi}
                    idUserMaint = {idUser}
                    nomorIdKegiatan = {nomorIdKegiatan[0]}
                    prgList = {prgList}
                    wrgList = {wargaList}
                  >
                  </EditKegiatan>
                }
              </Row>
              <Row> 
              { adaData 
                  ? 
                    <Table 
                      onClickHandleEdit = {onClickHandleEdit}
                      headData={kegiatanTableHead}
                      renderHead={renderHead}
                      bodyData={kegiatanList.filter((item) => item.idGereja !== 'nf')}
                      renderBody={renderBody}
                    />
                  :
                    (<div> Belum ada data </div>)
              }
              </Row>
            </div>
          )}
        </Container>
      </div>
    </div>
  )
}

export default MaintKegiatan