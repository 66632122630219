import React, { useState, useEffect } from 'react'
import useAuth from '../../hooks/useAuth'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

// import "./editkelompoktransaksi.scss"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import { EditKelompokTransaksiValidationSchema } from '../../components/data/ValidationSchema'
import TextError from '../../components/form/TextError'
import CustomSelect from '../../components/form/CustomSelect'

require('moment/locale/id')
let config = ""
toast.configure()

const EditKelompokTransaksi = (
  {onClickHandleEdit, idGereja, idBakowi, idUserMaint, nomorIdKelompokTransaksi, perkList}
) => {
    
  window.scrollTo(0, 0)
  const { auth } = useAuth()

  // const apiUrlAG = "/api/ag-program"
  const apiUrlAG = auth.API_URL + "/ag-program"

  const [perkiraanList, setPerkiraanList] = useState([])
  
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const addKelompokTransaksi = (input) => {
    config = {
      method : "post",
      url : `${apiUrlAG}/kelompoktransaksi/`, 
      headers : {
        "Content-Type":"application/json"
      },
    data: {
        idGereja: input.idGereja,
        idBakowi: input.idBakowi,
        idKelompokTransaksi: input.idKelompokTransaksi,
        namaKelompokTransaksi: input.namaKelompokTransaksi,
        inisial: input.inisial,
        tipe: input.tipe,
        idPerkiraan: input.idPerkiraan,
        keterangan: input.keterangan,
        idUser: input.idUserMaint,
        userActivity: 'Add'
      }
    }
    return axiosPrivate(config)
  }

  const useAddKelompokTransaksi = () => {
    return useMutation(
      addKelompokTransaksi, 
      {
        onError: () => {
          toast.error('Data kelompok transaksi gagal ditambah !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: () => {
          toast.success('Data kelompok transaksi berhasil ditambah ...', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
          queryClient.invalidateQueries()
        },
      }
    )
  }

  const updateKelompokTransaksi = (input) => {
    config = {
      method : "post",
      url : `${apiUrlAG}/kelompoktransaksi/${input.idGereja}/${input.idBakowi}/${input.idKelompokTransaksi}`, 
      headers : {
        "Content-Type":"application/json"
      },
    data: {
        namaKelompokTransaksi: input.namaKelompokTransaksi,
        inisial: input.inisial,
        tipe: input.tipe,
        idPerkiraan: input.idPerkiraan,
        keterangan: input.keterangan,
        idUser: input.idUserMaint,
        userActivity: 'Update'
      }
    }
    return axiosPrivate(config)
  }

  const useUpdateKelompokTransaksi = () => {
    return useMutation(
      updateKelompokTransaksi, 
      {
        onError: () => {
          toast.error('Data kelompok transaksi gagal diubah !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: () => {
          toast.success('Data kelompok transaksi berhasil diubah ...', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
          queryClient.invalidateQueries()
        },
      }
    )
  }

  useEffect(() => {
    perkList && setPerkiraanList(perkList)
  }, [perkList])

  console.log('perkiraanList: ', perkiraanList, typeof(perkiraanList))
  let menuScreenClassName = 'w-100 justify-content-end text-center border-0 fw-bold text-light'
  let btnTambahClassName = 'btn btn-outline-primary btn-sm fst-italic fw-bold'
  let btnUbahClassName = 'btn btn-outline-success btn-sm fst-italic fw-bold'
  let btnConfirmClassName = 'btn btn-sm fst-italic fw-bold text-light'
  let btnCancelClassName = 'btn btn-dark btn-sm fst-italic fw-bold text-light'
  const initialValues = {
    idUserMaint: idUserMaint,
    idGereja: idGereja,
    idBakowi: idBakowi,
    idKelompokTransaksi: nomorIdKelompokTransaksi ? nomorIdKelompokTransaksi.idKelompokTransaksi : '',
    namaKelompokTransaksi: nomorIdKelompokTransaksi ? nomorIdKelompokTransaksi.namaKelompokTransaksi : '',
    inisial: nomorIdKelompokTransaksi ? nomorIdKelompokTransaksi.inisial : '',
    tipe: nomorIdKelompokTransaksi ? nomorIdKelompokTransaksi.tipe : '',
    tipeNama: nomorIdKelompokTransaksi 
      ? (nomorIdKelompokTransaksi.tipe === 'T' 
        ? 'Penerimaan' 
        : (nomorIdKelompokTransaksi.tipe === 'K' 
          ? 'Pengeluaran' 
          : 'Tipe salah')) 
      : '',
    idPerkiraan: nomorIdKelompokTransaksi ? nomorIdKelompokTransaksi.idPerkiraan : '',
    namaPerkiraan: nomorIdKelompokTransaksi ? nomorIdKelompokTransaksi.namaPerkiraan : '',
    keterangan: nomorIdKelompokTransaksi ? nomorIdKelompokTransaksi.keterangan : '',
    idUser: nomorIdKelompokTransaksi 
      ? (nomorIdKelompokTransaksi.idUser 
        ? nomorIdKelompokTransaksi.idUser 
        : '*') 
      : idUserMaint,
    namaUser: nomorIdKelompokTransaksi && nomorIdKelompokTransaksi.namaUser ? nomorIdKelompokTransaksi.namaUser : '*',
    userActivity: nomorIdKelompokTransaksi && nomorIdKelompokTransaksi.userActivity 
      ? nomorIdKelompokTransaksi.userActivity 
      : '*',
    tanggalSystem: nomorIdKelompokTransaksi && nomorIdKelompokTransaksi.tanggalSystem 
      ? nomorIdKelompokTransaksi.tanggalSystem 
      : '*',
    menuScreen: nomorIdKelompokTransaksi ? 'Idle' : 'A D D',
    menuClass: menuScreenClassName.concat(nomorIdKelompokTransaksi ? ' bg-info' : ' bg-primary'),
    fieldDisableKey: nomorIdKelompokTransaksi ? true : false,
    fieldDisable: nomorIdKelompokTransaksi ? true : false,
    btnMenuDisable: false,
    btnTambahClass: btnTambahClassName.concat(nomorIdKelompokTransaksi ? '' : ' invisible'),
    btnUbahClass: btnUbahClassName.concat(nomorIdKelompokTransaksi ? '' : ' invisible'),
    btnConfirmClass: btnConfirmClassName.concat(nomorIdKelompokTransaksi ? ' invisible' : ' bg-primary visible'),
    btnCancelClass: btnCancelClassName.concat(nomorIdKelompokTransaksi ? ' invisible' : ' bg-primary visible'),
    btnConfirmText: nomorIdKelompokTransaksi ? '' : 'Tambahkan data ...'
  }

  const { mutate: addKlmpkTrn } = useAddKelompokTransaksi()
  const { mutate: updateKlmpkTrn } = useUpdateKelompokTransaksi()
  const onClickConfirm = (input) => {
    if (input.menuScreen === "A D D") {
      addKlmpkTrn(input)
      onClickHandleEdit(false)
    }
    if (input.menuScreen === "UPDATE") {
      updateKlmpkTrn(input)
      onClickHandleEdit(false)
    }
  }

  const clickExit = () => {
    onClickHandleEdit(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EditKelompokTransaksiValidationSchema}
      enableReinitialize
    >
      {formik => {
        // function screenIdle
        const screenIdle = () => {
          formik.setFieldValue('menuScreen', 'Idle')
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'visible'))
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-primary'))
          // show buttonTU => 'FF'
          formik.setFieldValue('btnMenuDisable', false)
          // hidden button konfirmasi
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'invisible'))
          // disable semua field
          formik.setFieldValue('fieldDisableKey', true)
          formik.setFieldValue('fieldDisable', true)
        }
        // function clickTambah
        const clickTambah = () => {
          formik.setFieldValue('idKelompokTransaksi', '')
          formik.setFieldValue('namaKelompokTransaksi', '')
          formik.setFieldValue('inisial', '')
          formik.setFieldValue('tipe', '')
          formik.setFieldValue('idPerkiraan', '')
          formik.setFieldValue('namaPerkiraan', '')
          formik.setFieldValue('keterangan', '')
          formik.setFieldValue('idUser', idUserMaint)
          formik.setFieldValue('menuScreen', 'A D D')
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-primary'))
          // hidden buttonTU => [T]ambah - [U]bah
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'invisible'))
          // show button konfirmasi (Tambahkan data ... & Batal)
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'bg-primary', ' ', 'visible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnConfirmText', 'Tambahkan data ...')
          // enable semua field
          formik.setFieldValue('fieldDisableKey', false)
          formik.setFieldValue('fieldDisable', false)
        }
        // function clickUbah
        const clickUbah = () => {
          formik.setFieldValue('menuScreen', 'UPDATE')
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-success'))
          // hidden buttonTU
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'invisible'))
          // show button konfirmasi (Ubah data ... & Batal)
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'bg-primary', ' ', 'visible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnConfirmText', 'Ubah data ...')
          // enable semua field
          formik.setFieldValue('fieldDisable', false)
        }
        function renderTipe(e) {
          const tipeNm = e.target.value
          formik.handleChange(e)
          switch (tipeNm.toUpperCase()) {
            case "T":
              formik.setFieldValue('tipeNama', 'Penerimaan')
              break
            case "K":
              formik.setFieldValue('tipeNama', 'Pengeluaran')
              break
            default:
              formik.setFieldValue('tipeNama', 'Tipe salah')
              break
          }
        }
        return (
          <div className="container">
            <Form className="w-100">
              <Row className="fw-bold mb-2">
                <Field name="idUserMaint" type="hidden"/>
                <Field name="idGereja" type="hidden"/> 
                <Field name="idBakowi" type="hidden"/> 
                <Field name="fieldDisableKey" type="hidden"/>
                <Field name="fieldDisable" type="hidden"/>
                <Field name="menuClass" type="hidden"/>
                <Field name="btnTambahClass" type="hidden"/>
                <Field name="btnUbahClass" type="hidden"/>
                <Field name="btnMenuDisable" type="hidden"/>
                <Field name="btnConfirmClass" type="hidden"/>
                <Field name="btnCancelClass" type="hidden"/>
                <Field name="btnConfirmText" type="hidden"/>
                <Col md={9} sm={6} xs={6}>
                  <p className="mb-2">
                    <span className="text-primary fw-bold"> <i className="bi bi-arrow-right-square-fill"></i> </span> &nbsp; 
                    Nama Jenis Kelompok Transaksi &nbsp; : &nbsp; 
                    <strong>{formik.values.namaKelompokTransaksi}</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                  </p>
                </Col>
                <Col md={3} sm={3} xs={3}>
                  <Field name="menuScreen" disabled={true} className={formik.values.menuClass}/>
                </Col>
              </Row>
              <hr/>
              <Row className="fw-bold">
                <Col className="form-group mt-2 col-3 justify-content-start">
                  <label className="text-primary w-20" htmlFor="idKelompokTransaksi"> Id </label>
                  <Field 
                    className="text-center w-30" 
                    id="idKelompokTransaksi" 
                    name="idKelompokTransaksi" 
                    disabled={formik.values.fieldDisableKey} 
                    placeholder="id" 
                  />
                  <ErrorMessage 
                    name="idKelompokTransaksi" 
                    component={TextError} 
                    className="invalid-feedback text-warning" 
                  />
                </Col>
                <Col className="form-group mt-2 ps-0 col-7 justify-content-start">
                  <label className="text-primary w-15" htmlFor="namaKelompokTransaksi"> Nama </label>
                  <Field 
                    className="text-start ms-1 w-75" 
                    id="namaKelompokTransaksi" 
                    name="namaKelompokTransaksi" 
                    disabled={formik.values.fieldDisable} 
                    placeholder="Nama kelompok transaksi" 
                  />
                  <ErrorMessage 
                    name="namaKelompokTransaksi" 
                    component={TextError} 
                    className="invalid-feedback text-warning" 
                  />
                </Col>
                <Col className="form-group mt-2 ps-0 col-2 justify-content-start">
                  <label className="text-primary w-50" htmlFor="inisial"> Inisial </label>
                  <Field 
                    className="text-center ms-1 w-25"  
                    id="inisial" 
                    name="inisial" 
                    disabled={formik.values.fieldDisable} 
                  />
                  <ErrorMessage name="inisial" component={TextError} className="invalid-feedback text-warning" />
                </Col>
              </Row>
              <hr/>
              <Row className="fw-bold">
                <Col className="form-group mt-2 col-3 justify-content-end">
                  <label className="text-primary w-20" htmlFor="tipe"> Tipe </label>
                  <Field 
                    className="text-center w-15" id="tipe" name="tipe" 
                    onChange={renderTipe}
                    disabled={formik.values.fieldDisable} 
                  />
                  <Field className={
                    "text-center w-50 fw-bold ms-1".concat(
                      ['T','K'].includes(formik.values.tipe) 
                        ? ' text-success border-success' 
                        : ' text-warning border-danger'
                    )
                  } 
                    id="tipeNama" name="tipeNama" disabled={true} 
                  />
                  <ErrorMessage name="tipe" component={TextError} className="invalid-feedback text-warning" />
                </Col>
                <Col className="form-group mt-2 ps-0 col-1 justify-content-start">
                  <label className="text-primary" htmlFor="idPerkiraan"> Perkiraan </label>
                </Col>
                <Col className="form-group mt-2 ms-4 col-7 justify-content-start"> 
                  <CustomSelect
                    options={perkiraanList}
                    value={formik.values.idPerkiraan}
                    placeholder="Ketik / Pilih => Nomor / Nama Perkiraan"
                    onChange={value => value ? formik.setFieldValue('idPerkiraan', value.value) : null}
                    disableOpt={(perkiraanList) => perkiraanList.GD === 'G'}
                    disableSelect={formik.values.fieldDisable}
                    clearable={false}
                    className="mb-1"
                  />
                  <ErrorMessage name="idPerkiraan" component={TextError} className="invalid-feedback" />
                </Col>
              </Row>
              <hr/>
              <Row>
                <Col className="form-group mt-3 col-12">
                  <label className="text-primary w-15" htmlFor="keterangan"> Keterangan </label>
                  <Field 
                    className="text-start w-70" 
                    id="keterangan" 
                    name="keterangan" 
                    disabled={formik.values.fieldDisable} 
                  />
                </Col>
              </Row>
              <hr/>
              <Row className="fw-bold small">
                <p>
                  <span className="text-warning"> <i className="bi bi-x-circle-fill"></i> </span> &nbsp; 
                    Maintenance : {formik.values.userActivity} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                  <span className="text-warning fw-bold"> <i className="bi bi-arrow-right-circle"></i> </span> &nbsp; 
                    Id User Maint : {formik.values.idUser} - {formik.values.namaUser} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="text-warning fw-bold"> <i className="bi bi-arrow-right-circle"></i> </span> &nbsp; 
                    Tanggal Maintenance : {moment(formik.values.tanggalSystem).format('LLLL')} 
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
              </Row>
              <Row className="fw-bold small">
                <Col className="justify-content-start">
                  <button 
                    type="button" 
                    className={formik.values.btnConfirmClass}
                    disabled={
                      (
                        parseInt(formik.values.idKelompokTransaksi, 10) > 0 && 
                        parseInt(formik.values.idKelompokTransaksi, 10) < 256 &&
                        formik.values.namaKelompokTransaksi &&
                        formik.values.inisial && formik.values.inisial.length < 3 && 
                        formik.values.tipe && 
                        'TK'.includes(formik.values.tipe) &&
                        formik.values.idPerkiraan
                      ) 
                        ? false : true}
                    onClick={() => onClickConfirm(formik.values)}
                  > {formik.values.btnConfirmText} </button>
                  &nbsp;&nbsp;&nbsp;
                  <button 
                    type="button" 
                    className={formik.values.btnCancelClass} 
                    onClick={() => formik.values.menuScreen === "UPDATE" ? screenIdle() : onClickHandleEdit(false)}
                  > Batal </button>
                </Col>
                <Col className="text-end">
                  <button 
                    type="button" 
                    className={formik.values.btnTambahClass} 
                    disabled={formik.values.btnMenuDisable}
                    onClick={() => clickTambah()}
                  > Tambah </button>
                  &nbsp;&nbsp;&nbsp;
                  <button 
                    type="button" 
                    className={formik.values.btnUbahClass} 
                    disabled={formik.values.btnMenuDisable} 
                    onClick={() => clickUbah()}
                  > Ubah </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button 
                    type="button"
                    className='btn btn-outline-secondary btn-sm fst-italic fw-bold'
                    onClick={() => clickExit()}
                  > Keluar </button>
                </Col>
              </Row>
              <hr/>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

export default EditKelompokTransaksi