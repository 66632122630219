import React from 'react'
import useAuth from '../../hooks/useAuth'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

// import "./editsaldoawal.scss"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { Row, Col } from "react-bootstrap"
import moment from "moment"
import TextError from "../../components/form/TextError"
import NumberFormat from "react-number-format"

require("moment/locale/id")
let config = ""
toast.configure()

const EditSaldoAwal = ({onClickHandleEdit, idGereja, idBakowi, idUserMaint, nomorAkunSaldoAwal}) => {
  
  window.scrollTo(0, 0)
  const { auth } = useAuth()
  
  // const apiUrlAG = "/api/ag-saldoawal"
  const apiUrlAG = auth.API_URL + "/ag-saldoawal"

  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const addSaldoAwal = (input) => {
    config = {
      method : "post",
      url : `${apiUrlAG}/saldoawal/}`,
      headers : {
        "Content-Type":"application/json"
      },
      data: {
        idGereja: input.idGereja,
        idBakowi: input.idBakowi,
        idAkunKasBank: input.idAkunKasBank,
        tahunBuku: input.tahunBuku,
        nilai: input.nilai,
        keterangan: input.keterangan,
        idUser: input.idUserMaint,
        userActivity: 'Setup'
      }
    }
    return axiosPrivate(config)
  }

  const useAddSaldoAwal = () => {
    return useMutation(
      addSaldoAwal, 
      {
        onError: () => {
          toast.error('Data akun saldo awal gagal ditambah !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: () => {
          toast.success('Data akun saldo awal berhasil ditambah ...', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
          queryClient.invalidateQueries()
        },
      }
    )
  }

  const updateSaldoAwal = (input) => {
    const config = {
      method : "patch",
      url : `${apiUrlAG}/saldoawal/${input.idGereja}/${input.idBakowi}/${input.idAkunKasBank}/${input.tahunBuku}`,
      headers : {
        "Content-Type":"application/json"
      },
      data: {
        nilai: input.nilai,
        idUser: input.idUserMaint,
        userActivity: 'Update'
      }
    }
    return axiosPrivate(config) 
  }

  const useUpdateSaldoAwal = () => {
    return useMutation(
      updateSaldoAwal, 
      {
        onError: () => {
          toast.error('Data akun saldo awal gagal diubah !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: () => {
          toast.success('Data akun saldo awal berhasil diubah ...', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
          queryClient.invalidateQueries()
        },
      }
    )
  }

  let menuScreenClassName = "w-100 justify-content-end text-center border-0 fw-bold text-light"
  let btnTambahClassName = "btn btn-outline-primary btn-sm fst-italic fw-bold"
  let btnUbahClassName = "btn btn-outline-success btn-sm fst-italic fw-bold"
  let btnConfirmClassName = "btn btn-sm fst-italic fw-bold text-light"
  let btnCancelClassName = "btn btn-dark btn-sm fst-italic fw-bold text-light"
  const initialValues = {
    idUserMaint: idUserMaint,
    idGereja: idGereja,
    idBakowi: idBakowi,
    idAkunKasBank: nomorAkunSaldoAwal ? nomorAkunSaldoAwal.idAkunKasBank : '',
    namaAkunKasBank: nomorAkunSaldoAwal ? nomorAkunSaldoAwal.namaAkunKasBank : '',
    tipeAkunKasBank: nomorAkunSaldoAwal 
      ? (nomorAkunSaldoAwal.tipeAkunKasBank === "K" 
          ? "Kas" 
          : nomorAkunSaldoAwal.tipeAkunKasBank === "B" 
            ? "Bank" 
            : "*?*"
        ) 
      : '',
    tahunBuku: nomorAkunSaldoAwal && nomorAkunSaldoAwal.tahunBuku ? nomorAkunSaldoAwal.tahunBuku : "",
    nilai: nomorAkunSaldoAwal && nomorAkunSaldoAwal.nilai ? nomorAkunSaldoAwal.nilai : "",
    namaBank: nomorAkunSaldoAwal ? nomorAkunSaldoAwal.namaBank : '',
    nomorRekening: nomorAkunSaldoAwal ? nomorAkunSaldoAwal.nomorRekening : '',
    namaDiRekening: nomorAkunSaldoAwal ? nomorAkunSaldoAwal.namaDiRekening : '',
    idUser: nomorAkunSaldoAwal ? (nomorAkunSaldoAwal.idUser ? nomorAkunSaldoAwal.idUser : '*') : idUserMaint,
    namaUser: nomorAkunSaldoAwal && nomorAkunSaldoAwal.namaUser ? nomorAkunSaldoAwal.namaUser : '*',
    userActivity: nomorAkunSaldoAwal && nomorAkunSaldoAwal.userActivity ? nomorAkunSaldoAwal.userActivity : '*',
    tanggalSystem: nomorAkunSaldoAwal && nomorAkunSaldoAwal.tanggalSystem ? nomorAkunSaldoAwal.tanggalSystem : '*',
    menuScreen: nomorAkunSaldoAwal ? 'Idle' : 'A D D',
    menuClass: menuScreenClassName.concat(nomorAkunSaldoAwal ? ' bg-info' : ' bg-primary'),
    fieldDisableKey: nomorAkunSaldoAwal ? true : false,
    fieldDisable: nomorAkunSaldoAwal ? true : false,
    btnMenuDisable: false,
    btnTambahClass: btnTambahClassName.concat(nomorAkunSaldoAwal ? '' : ' invisible'),
    btnUbahClass: btnUbahClassName.concat(nomorAkunSaldoAwal ? '' : ' invisible'),
    btnConfirmClass: btnConfirmClassName.concat(nomorAkunSaldoAwal ? ' invisible' : ' bg-primary visible'),
    btnCancelClass: btnCancelClassName.concat(nomorAkunSaldoAwal ? ' invisible' : ' bg-primary visible'),
    btnConfirmText: nomorAkunSaldoAwal ? '' : 'Tambahkan data ...'
  }

  const { mutate: addSldAwl } = useAddSaldoAwal()
  const { mutate: updateSldAwl } = useUpdateSaldoAwal()
  const onClickConfirm = (input) => {
    if (input.menuScreen === "A D D") {
      addSldAwl(input)
      onClickHandleEdit(false)
    }
    if (input.menuScreen === "UPDATE") {
      updateSldAwl(input)
      onClickHandleEdit(false)
    }
  }

  const clickExit = () => {
    onClickHandleEdit(false)
  }

  const renderNilai = ({
    field: { name, value, onChange, onBlur },
    form: { touched, errors, values, setFieldValue, setFieldTouched },
    ...custom
  }) => {
    return (
      <NumberFormat
        name={name}
        thousandSeparator={"."}
        decimalSeparator={","}
        decimalScale={2}
        fixedDecimalScale={true}
        value={value}
        onValueChange={(val) => setFieldValue(name, val.floatValue)}
        {...custom}
      />
    )
  }
  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={EditSaldoAwalValidationSchema}
      enableReinitialize
    >
      {(formik) => {
        // function screenIdle
        const screenIdle = () => {
          formik.setFieldValue("menuScreen", "Idle")
          formik.setFieldValue("btnTambahClass", btnTambahClassName.concat(" ", "visible"))
          formik.setFieldValue("btnUbahClass", btnUbahClassName.concat(" ", "visible"))
          formik.setFieldValue("menuClass", menuScreenClassName.concat(" ", "bg-primary"))
          // hidden button konfirmasi
          formik.setFieldValue("btnConfirmClass", btnConfirmClassName.concat(" ", "invisible"))
          formik.setFieldValue("btnCancelClass", btnCancelClassName.concat(" ", "invisible"))
          // disable semua field
          formik.setFieldValue("fieldDisableKey", true)
          formik.setFieldValue("fieldDisable", true)
        }
        // function clickTambah
        const clickTambah = () => {
          formik.setFieldValue("idUser", idUserMaint)
          formik.setFieldValue("menuScreen", "A D D")
          formik.setFieldValue("menuClass", menuScreenClassName.concat(" ", "bg-primary"))
          // hidden buttonTU => [T]ambah - [U]bah
          formik.setFieldValue("btnTambahClass", btnTambahClassName.concat(" ", "invisible"))
          formik.setFieldValue("btnUbahClass", btnUbahClassName.concat(" ", "invisible"))
          // show button konfirmasi (Tambahkan data ... & Batal)
          formik.setFieldValue("btnConfirmClass", btnConfirmClassName.concat(" ", "bg-primary", " ", "visible"))
          formik.setFieldValue("btnCancelClass", btnCancelClassName.concat(" ", "visible"))
          formik.setFieldValue("btnConfirmText", "Tambahkan data ...")
          // enable semua field
          formik.setFieldValue("fieldDisableKey", false)
          formik.setFieldValue("fieldDisable", false)
        }
        // function clickUbah
        const clickUbah = () => {
          formik.setFieldValue("menuScreen", "UPDATE")
          formik.setFieldValue("menuClass", menuScreenClassName.concat(" ", "bg-success"))
          // hidden buttonTU
          formik.setFieldValue("btnTambahClass", btnTambahClassName.concat(" ", "invisible"))
          formik.setFieldValue("btnUbahClass", btnUbahClassName.concat(" ", "invisible"))
          // show button konfirmasi (Ubah data ... & Batal)
          formik.setFieldValue("btnConfirmClass", btnConfirmClassName.concat(" ", "bg-primary", " ", "visible"))
          formik.setFieldValue("btnCancelClass", btnCancelClassName.concat(" ", "visible"))
          formik.setFieldValue("btnConfirmText", "Ubah data ...")
          // enable semua field
          formik.setFieldValue("fieldDisable", false)
        }
        return (
          <div className="container">
            <Form className="w-100">
              <Row className="fw-bold mb-2">
                <Field name="idUserMaint" type="hidden" />
                <Field name="idGereja" type="hidden" />
                <Field name="idBakowi" type="hidden" />
                <Field name="fieldDisableKey" type="hidden" />
                <Field name="fieldDisable" type="hidden" />
                <Field name="menuClass" type="hidden" />
                <Field name="btnTambahClass" type="hidden" />
                <Field name="btnUbahClass" type="hidden" />
                <Field name="btnConfirmClass" type="hidden" />
                <Field name="btnCancelClass" type="hidden" />
                <Field name="btnConfirmText" type="hidden" />
                <Col md={9} sm={6} xs={6}>
                  <p className="mb-2">
                    <span className="text-primary fw-bold">
                      {" "}
                      <i className="bi bi-arrow-right-square-fill"></i>
                    </span>{" "} 
                    &nbsp; ID &nbsp; : &nbsp;
                    <strong>{formik.values.idAkunKasBank}</strong>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Nama Akun &nbsp; : &nbsp;
                    <strong>{formik.values.namaAkunKasBank}</strong>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Tipe Akun &nbsp; : &nbsp;
                    <strong>{formik.values.tipeAkunKasBank}</strong>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </p>
                </Col>
                <Col md={3} sm={3} xs={3}>
                  <Field name="menuScreen" disabled={true} className={formik.values.menuClass}/>
                </Col>
              </Row>
              <hr />
              <Row className="fw-bold">
                <Col className="form-group mt-2 col-2 me-5 justify-content-start">
                  <label className="text-primary w-50" htmlFor="tahunBuku"> Tahun </label>
                  <Field 
                    className="text-center w-50" 
                    id="tahunBuku" 
                    name="tahunBuku" 
                    disabled={formik.values.fieldDisableKey}
                  />
                  <ErrorMessage name="tahunBuku" component={TextError} className="invalid-feedback text-warning"/>
                </Col>
                <Col className="form-group mt-2 col-4">
                  <label className="text-primary w-40" htmlFor="nilai"> Saldo Awal </label>
                  <Field 
                    className="text-end w-50" 
                    id="nilai" 
                    name="nilai" 
                    component={renderNilai} 
                    disabled={formik.values.fieldDisable}
                  />
                  <ErrorMessage name="nilai" component={TextError} className="invalid-feedback text-warning"/>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col className="form-group col-1 pe-0 w-5">
                  <span className="text-primary fw-bold">
                    <i className="bi bi-building text-success" style={{ fontSize: 30 }}></i>
                  </span>
                  &nbsp;
                </Col>
                <Col className="form-group mt-2 col-5 ps-0">
                  <label className="text-primary w-20" htmlFor="namaBank"> Nama Bank </label>
                  &nbsp; : &nbsp; <strong>{formik.values.namaBank}</strong>
                </Col>
                <Col className="form-group mt-2 col-2">
                  <label className="text-primary w-20" htmlFor="nomorRekening"> Rek. </label>
                  &nbsp; : &nbsp; <strong>{formik.values.nomorRekening}</strong>
                </Col>
                <Col className="form-group mt-2 col-4">
                  <label className="text-primary w-35" htmlFor="namaDiRekening"> Nama di Rek. </label>
                  &nbsp; : &nbsp; <strong>{formik.values.namaDiRekening}</strong>
                </Col>
              </Row>
              <hr />
              <Row className="fw-bold small">
                <p>
                  <span className="text-warning"> <i className="bi bi-x-circle-fill"></i> </span> &nbsp; 
                  Maintenance : {formik.values.userActivity} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="text-warning fw-bold"> <i className="bi bi-arrow-right-circle"></i> </span> &nbsp; 
                  Id User Maint : {formik.values.idUser} - {formik.values.namaUser} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="text-warning fw-bold"> <i className="bi bi-arrow-right-circle"></i> </span> &nbsp; 
                  Tanggal Maintenance : {formik.values.tanggalSystem === "*"
                    ? formik.values.tanggalSystem
                    : moment(formik.values.tanggalSystem).format("LLLL")}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
              </Row>
              <Row className="fw-bold small">
                <Col className="justify-content-start">
                  <button
                    type="button"
                    className={formik.values.btnConfirmClass}
                    disabled={
                      Number.isFinite(formik.values.nilai) &&
                      formik.values.nilai >= 0
                        ? false
                        : true
                    }
                    onClick={() => onClickConfirm(formik.values)}
                  >
                    {formik.values.btnConfirmText}
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    className={formik.values.btnCancelClass}
                    onClick={() =>
                      formik.values.menuScreen === "UPDATE"
                        ? screenIdle()
                        : onClickHandleEdit(false)
                    }
                  > Batal </button>
                </Col>
                <Col className="text-end">
                  <button
                    type="button"
                    className={formik.values.btnTambahClass}
                    disabled={formik.values.tahunBuku ? true : false}
                    onClick={() => clickTambah()}
                  > Tambah </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    className={formik.values.btnUbahClass}
                    disabled={
                      ["Setup", "Update"].includes(formik.values.userActivity)
                        ? false
                        : true
                    }
                    onClick={() => clickUbah()}
                  > Ubah </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm fst-italic fw-bold"
                    onClick={() => clickExit()}
                  > Keluar </button>
                </Col>
              </Row>
              <hr />
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

export default EditSaldoAwal
