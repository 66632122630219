import React, { useState } from "react"
import useAuth from '../../hooks/useAuth'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

import "./maintuser.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Table from "../../components/table/Table"
import EditUser from "./EditUser"

import { Row, Container } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'

import moment from 'moment'
import Logo from '../../images/Logo'

const imgLogo = Logo()
require('moment/locale/id')

toast.configure()

const MaintUser = () => {
  
  window.scrollTo(0, 0)
  const { auth } = useAuth()
  
  // const apiUrlUser = "/api/user"
  const apiUrlUser = auth.API_URL + "/user"

  const idGereja = auth.authIdGereja
  const idUser = auth.authIdUser
  const [nomorIdUser, setNomorIdUser] = useState(0)
  const [editDetail, setEditDetail] = useState(false)

  const axiosPrivate = useAxiosPrivate()

  const fetchUserList = (nomorIdGereja) => {
    return axiosPrivate.get(`${apiUrlUser}/userlist/${nomorIdGereja}`)
  }

  const useUserList = (nomorIdGereja, onSuccess, onError) => {
    return useQuery(
      ['userlist', nomorIdGereja], 
      () => fetchUserList(nomorIdGereja), 
      {
        onSuccess,
        onError: () => {
          toast.error('Tidak dapat meng-akses data !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        select: (data) => {
          const userList = data.data
          return userList
        }
      }
    )
  }

  const { data: userList, isLoading, isSuccess, isError } = useUserList(idGereja)
  const userTableHead = [
    // posisi judul [L]eft/[C]enter/[R]ight - nama judul
    'C-ID',
    'L-Email',
    'L-Nama',
    'C-Level',
    'L-No. HP',
    'L-Status',
    'L-Tgl Status',
    'L-User Add',
    'L-User Activity',
  ]
  const renderHead = (item, index) => (
    <th key={index} className={item.substring(0,1) === 'C' ? 'text-center' : (item.substring(0,1) === 'R' ? 'text-end' : 'text-start')}>
      {item.substring(2)}
    </th>
  )

  const renderBody = (item, index) => (
    <tr onClick={() => onClickHandleEdit(item.idUser, true)} key={index}>
      <td className="text-center">{item.idUser}</td>
      <td>{item.userEmail}</td>
      <td>{item.namaUser}</td>
      <td className="text-center">{item.levelUser}</td>
      <td>{item.mobileNo}</td>
      <td>
        <span className={`status ${item.statusUser}`}>{item.statusUser}</span>
      </td>
      <td>{moment(item.tanggalStatus).format('LL')}</td>
      <td>{item.namaUserAdd}</td>
      <td>{item.userActivity}</td>
    </tr>
  )

  const onClickHandleEdit = (noIdUser, editDisplay) => {
    setNomorIdUser(noIdUser)
    setEditDetail(editDisplay)
  }

  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <h3 className="ms-4 my-3"> Create - Update User </h3>
        <Container className="w-100">
          { isLoading && (
            <>
              <div className="w-100 d-flex mt-3 justify-content-center">
                  <Row className="w-25 justify-content-center">
                      <img src={imgLogo} width="15%" className="rounded-circle img-thumbnail border-danger" alt="GKJ"></img>
                  </Row>
              </div>
              <div className="w-100 mt-3 justify-content-center">
                  <Row className="text-center">
                      <strong> Loading ... </strong>
                  </Row>
                  <Row className="mb-3 justify-content-center">
                      <div className="spinner-grow mt-2 text-primary" role="status" aria-hidden="true"></div>
                  </Row>
              </div>
            </>
          )}
          { isError && (<div> Data user tidak dapat diakses </div>)}
          { isSuccess && (
            <div>
              <Row>
                {editDetail &&
                  <EditUser
                    onClickHandleEdit = {onClickHandleEdit}
                    idGereja = {idGereja}
                    idUserMaint = {idUser}
                    nomorIdUser = {nomorIdUser}
                  >
                  </EditUser>
                }
              </Row>
              <Row> 
                <Table 
                  onClickHandleEdit = {onClickHandleEdit}
                  headData={userTableHead}
                  renderHead={renderHead}
                  bodyData={userList}
                  renderBody={renderBody}
                />
              </Row>
            </div>
          )}
        </Container>
      </div>
    </div>
  )
}

export default MaintUser