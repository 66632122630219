import React from 'react'
import ReactDOM from 'react-dom'
// eslint-disable-next-line
import bootstrap from 'bootstrap'
import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './css/custom.min.css'
import App from './App'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { DarkModeContextProvider } from './context/darkModeContext'
import { AuthProvider } from './context/AuthProvider'
import { disableReactDevTools } from '@fvilers/disable-react-devtools'

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools()
}

const queryClient = new QueryClient()

document.title = 'GKJ-Apps'

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <DarkModeContextProvider>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </DarkModeContextProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
