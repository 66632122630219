import { useQuery } from 'react-query'
import { toast } from 'react-toastify'

import useAxiosPrivate from '../../hooks/useAxiosPrivate'

// const apiUrlAG = "/api/ag-components"
const apiUrlAG = "https://ag.gkj.web.id/api/ag-components"

toast.configure()

export const useWidgetsFeatured = (nomorIdGereja, nomorIdBakowi, tahun, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate()
  return useQuery(
    ['widgetsfeatured', nomorIdGereja, nomorIdBakowi, tahun], 
    () => (axiosPrivate.get(`${apiUrlAG}/widgetsfeatured/${nomorIdGereja}/${nomorIdBakowi}/${tahun}`)), 
    {
      onSuccess,
      onError,
      select: (data) => {
        const dataWidgetsFeatured = data.data[0]
        return dataWidgetsFeatured
      }
    }
  )
}

export const useChart = (nomorIdGereja, nomorIdBakowi, tahun, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate()
  return useQuery(
    ['chart', nomorIdGereja, nomorIdBakowi, tahun], 
    () => (axiosPrivate.get(`${apiUrlAG}/chart/${nomorIdGereja}/${nomorIdBakowi}/${tahun}`)), 
    {
      onSuccess,
      onError,
      select: (data) => {
        const dataChart = data.data[0]
        return dataChart
      }
    }
  )
}

export const useTable = (nomorIdGereja, nomorIdBakowi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate()
  return useQuery(
    ['table', nomorIdGereja, nomorIdBakowi], 
    () => (axiosPrivate.get(`${apiUrlAG}/table/${nomorIdGereja}/${nomorIdBakowi}`)), 
    {
      onSuccess,
      onError,
      select: (data) => {
        const dataTable = data.data[0]
        return dataTable
      }
    }
  )
}

export const usePerkiraanList = (nomorIdGereja, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate()
  return useQuery(
    ['perkiraanlist', nomorIdGereja], 
    () => (axiosPrivate.get(`${apiUrlAG}/perkiraanlist/${nomorIdGereja}`)), 
    {
      onSuccess,
      onError,
      select: (data) => {
        const perkiraanList = data.data[0]
        return perkiraanList
      }
    }
  )
}

export const useWargaList = (nomorIdGereja, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate()
  return useQuery(
    ['wargalist', nomorIdGereja], 
    () => (axiosPrivate.get(`${apiUrlAG}/wargalist/${nomorIdGereja}`)), 
    {
      onSuccess,
      onError,
      select: (data) => {
        const wargaList = data.data[0]
        return wargaList
      }
    }
  )
}

export const useJnsPrgList = (nomorIdGereja, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate()
  return useQuery(
    ['jenisprogramlist', nomorIdGereja], 
    () => (axiosPrivate.get(`${apiUrlAG}/jenisprogramlist/${nomorIdGereja}`)), 
    {
      onSuccess,
      onError,
      select: (data) => {
        const jnsKegList = data.data[0]
        return jnsKegList
      }
    }
  )
}

export const useKlpList = (nomorIdGereja, nomorIdBakowi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate()
  return useQuery(
    ['klplist', nomorIdGereja, nomorIdBakowi], 
    () => (axiosPrivate.get(`${apiUrlAG}/klplist/${nomorIdGereja}/${nomorIdBakowi}`)), 
    {
      onSuccess,
      onError,
      select: (data) => {
        const klpList = data.data[0]
        return klpList
      }
    }
  )
}

export const useAknList = (nomorIdGereja, nomorIdBakowi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate()
  return useQuery(
    ['akunkasbanklist', nomorIdGereja, nomorIdBakowi], 
    () => (axiosPrivate.get(`${apiUrlAG}/akunkasbanklist/${nomorIdGereja}/${nomorIdBakowi}`)), 
    {
      onSuccess,
      onError,
      select: (data) => {
        const aknList = data.data[0]
        return aknList
      }
    }
  )
}

export const usePrgList = (nomorIdGereja, nomorIdBakowi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate()
  return useQuery(
    ['programlist', nomorIdGereja, nomorIdBakowi], 
    () => (axiosPrivate.get(`${apiUrlAG}/programlist/${nomorIdGereja}/${nomorIdBakowi}`)), 
    {
      onSuccess,
      onError,
      select: (data) => {
        const prgList = data.data[0]
        return prgList
      }
    }
  )
}

export const useKegList = (nomorIdGereja, nomorIdBakowi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate()
  return useQuery(
    ['kegiatanlist', nomorIdGereja, nomorIdBakowi], 
    () => (axiosPrivate.get(`${apiUrlAG}/kegiatanlist/${nomorIdGereja}/${nomorIdBakowi}`)), 
    {
      onSuccess,
      onError,
      select: (data) => {
        const kegList = data.data[0]
        return kegList
      }
    }
  )
}

export const useKetuaBadanKomisiList = (nomorIdGereja, nomorIdBakowi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate()
  return useQuery(
    ['ketuabadankomisilist', nomorIdGereja, nomorIdBakowi], 
    () => (axiosPrivate.get(`${apiUrlAG}/ketuabadankomisilist/${nomorIdGereja}/${nomorIdBakowi}`)), 
    {
      onSuccess,
      onError,
      select: (data) => {
        const ketuaBadanKomisiList = data.data[0]
        return ketuaBadanKomisiList
      }
    }
  )
}

export const usePenanggungjawabList = (nomorIdGereja, nomorIdBakowi, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate()
  return useQuery(
    ['penanggungjawablist', nomorIdGereja, nomorIdBakowi], 
    () => (axiosPrivate.get(`${apiUrlAG}/penanggungjawablist/${nomorIdGereja}/${nomorIdBakowi}`)), 
    {
      onSuccess,
      onError,
      select: (data) => {
        const penanggungjawabList = data.data[0]
        return penanggungjawabList
      }
    }
  )
}

export const useBendaharaList = (nomorIdGereja, onSuccess, onError) => {
  const axiosPrivate = useAxiosPrivate()
  return useQuery(
    ['bendaharalist', nomorIdGereja], 
    () => (axiosPrivate.get(`${apiUrlAG}/bendaharalist/${nomorIdGereja}`)), 
    {
      onSuccess,
      onError,
      select: (data) => {
        const bendaharaList = data.data[0]
        return bendaharaList
      }
    }
  )
}
