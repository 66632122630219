import React, { useEffect, useState } from "react"
import useAuth from '../../hooks/useAuth'
import { useQuery } from 'react-query'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

import "./transaksilist.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
// import Table from "../../components/table/Table"
import { useKlpList, useAknList, usePrgList, useKegList } 
  from '../../components/data/UseDataComponents'
import TransaksiEdit from "../transaksiadd/TransaksiEdit"

import { Formik, Form, ErrorMessage } from 'formik'
import { Row, Col, Container } from 'react-bootstrap'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import TransaksiListTable from './TransaksiListTable'

import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import id from "date-fns/locale/id"
import TextError from '../../components/form/TextError'
import Logo from '../../images/Logo'

const imgLogo = Logo()
registerLocale("id", id)

toast.configure()

const TransaksiList = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { auth } = useAuth()
  
  // const apiUrlAG = "/api/ag-transaksi"
  const apiUrlAG = auth.API_URL + "/ag-transaksi"

  const idGereja = auth.authIdGereja
  const idBakowi = auth.authIdBakowi
  const idUser = auth.authIdUser
  const tglMulai = auth.authTahunMulai

  const [editDetail, setEditDetail] = useState(false)
  const [noIdTransaksi, setNoIdTransaksi] = useState()
  const [tglAwal, setTglAwal] = useState(new Date(tglMulai, 1, 1))
  const [tglAkhir, setTglAkhir] = useState(new Date())
  const [keyFromButton, setKeyFromButton] = useState({
    tanggalAwal: tglAwal, 
    tanggalAkhir: tglAkhir 
  })
  const onProses = () => {
    setKeyFromButton({ ...keyFromButton, 
      tanggalAwal: moment(tglAwal, "YYYY-MM-DD"), 
      tanggalAkhir: moment(tglAkhir, "YYYY-MM-DD")
    })
  }

  const axiosPrivate = useAxiosPrivate()

  const fetchTransaksiListList = (idGereja, idBakowi, tanggalAwal, tanggalAkhir) => {
    return axiosPrivate.get(`${apiUrlAG}/transaksilist/${idGereja}/${idBakowi}/${tanggalAwal}/${tanggalAkhir}`)
  }

  const useTransaksiListList = (idGereja, idBakowi, tanggalAwal, tanggalAkhir, onSuccess, onError) => {
      return useQuery(
          ['transaksilist', idGereja, idBakowi, tanggalAwal, tanggalAkhir], 
          () => fetchTransaksiListList(idGereja, idBakowi, tanggalAwal, tanggalAkhir), 
          {
              onSuccess,
              onError: () => {
                toast.error('Tidak dapat meng-akses data !', {
                  position: toast.POSITION.BOTTOM_CENTER,
                  autoClose: 2000
                })
              },
              select: (data) => {
                  const transaksiListList = data.data[0]
                  return transaksiListList
              }
          }
      )
  }

  const { data: transaksiList, isLoading, isSuccess, isError } = 
    useTransaksiListList(
      idGereja, 
      idBakowi, 
      moment(keyFromButton.tanggalAwal).format("YYYY-MM-DD"), 
      moment(keyFromButton.tanggalAkhir).format("YYYY-MM-DD")
    )
  const { data: klpList } = useKlpList(idGereja, idBakowi)
  const { data: aknList } = useAknList(idGereja, idBakowi)
  const { data: prgList } = usePrgList(idGereja, idBakowi)
  const { data: kegList } = useKegList(idGereja, idBakowi)

  const onClickHandleEdit = (noIdTrn, editDisplay) => {
    setNoIdTransaksi(noIdTrn)
    setEditDetail(editDisplay)
  }

  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <h3 className="ms-4 mt-3"> List Transaksi </h3>
        <Container className="w-100">
        <Formik
            initialValues={{ tanggalAwal: tglAwal, tanggalAkhir: tglAkhir }}
            // validationSchema={TahunBulanValidationSchema}
            enableReinitialize
          >
            {formik => {
              return (
                <Container className="w-100">
                  <Form>
                    <hr/>
                    <Row className="text-center w=100 h-auto">
                      <Col className="form-group text-end col-5 p-0">
                        <label className="w-20" htmlFor="tanggalAwal"> Tanggal Awal </label>
                        <DatePicker
                          className="text-center w-80 bg-white"
                          selected={tglAwal} 
                          locale='id' 
                          onChange={date => {setTglAwal(date)}} 
                          selectsStart
                          startDate={tglAwal}
                          endDate={tglAkhir}
                          minDate={new Date(tglMulai)} 
                          maxDate={new Date()} 
                          dateFormat="dd-MMMM-yyyy"
                          showYearDropdown
                          showMonthDropdown
                          scrollableMonthYearDropdown
                        />
                        <ErrorMessage name="tanggalAwal" component={TextError} className="invalid-feedback" />
                      </Col>
                      <Col className="form-group text-center col-5 p-0">
                        <label className="w-20" htmlFor="tanggalAkhir"> Tanggal Akhir </label>
                        <DatePicker
                          className="text-center w-80 bg-white"
                          selected={tglAkhir}
                          locale='id'
                          onChange={date => {setTglAkhir(date)}}
                          selectsEnd
                          startDate={tglAwal}
                          endDate={tglAkhir}
                          minDate={tglAwal} 
                          maxDate={new Date()}
                          dateFormat="dd-MMMM-yyyy"
                          showYearDropdown
                          showMonthDropdown
                          scrollableMonthYearDropdown
                        />
                        <ErrorMessage name="tanggalAkhir" component={TextError} className="invalid-feedback" />
                      </Col>
                      <Col className='form-group text-start col-2'>
                        <button 
                          type="button" 
                          disabled={
                            (formik.values.tanggalAwal && formik.values.tanggalAkhir)
                            ? false : true} 
                          onClick={() => onProses()} 
                          className="btn btn-primary d-inline text-center p-0"
                          style={{'width': '75px', 'height': '32px'}}
                          > 
                            Proses 
                        </button>
                      </Col>
                    </Row>
                  </Form>
                  <hr/>
                  {transaksiList ?
                    <Row> 
                      <Col>
                        <Row className="justify-content-center mt-0 pt-0">
                          <p className="text-center fw-bold"> TRANSAKSI KEUANGAN </p>
                        </Row>
                        { isLoading && 
                          <>
                            <div className="w-100 d-flex mt-3 justify-content-center">
                              <Row className="w-25 justify-content-center">
                                <img 
                                  src={imgLogo} 
                                  width="15%" 
                                  className="rounded-circle img-thumbnail border-danger" 
                                  alt="GKJ"
                                ></img>
                              </Row>
                            </div>
                            <div className="w-100 mt-3 justify-content-center">
                              <Row className="text-center">
                                <strong> Loading ... </strong>
                              </Row>
                              <Row className="mb-3 justify-content-center">
                                <div 
                                  className="spinner-grow mt-2 text-primary" 
                                  role="status" 
                                  aria-hidden="true"
                                ></div>
                              </Row>
                            </div>
                            <hr/>
                          </>
                        }
                        { isError && (<div> Tabel transaksi tidak dapat diakses </div>)}
                        { isSuccess && (
                          <div>
                            <Row>
                              {editDetail &&
                                <TransaksiEdit
                                  onClickHandleEdit = {onClickHandleEdit}
                                  idUserMaint = {idUser}
                                  nomorIdTransaksi = {noIdTransaksi}
                                  klmpkList = {klpList}
                                  aknList = {aknList}
                                  prgList = {prgList}
                                  kegList = {kegList}
                                >
                                </TransaksiEdit>
                              }
                            </Row>
                            <Row> 
                              <TransaksiListTable 
                                onClickHandleEdit = {onClickHandleEdit}
                                transaksiList = {transaksiList.filter((item) => item.idGereja !== 'nf')} 
                              />
                            </Row>
                          </div>
                        )}
                      </Col>
                      <Row className="justify-content-start tglfootnote footnote mt-0">
                        <div className="fst-italic text-start text-muted small"> gkj-ag-wr-2022 </div>
                      </Row>                                
                    </Row>
                  :
                    <Row>
                      <div className="w-100 mt-5 text-center">
                        <img 
                          src={imgLogo} 
                          width="15%" 
                          className="rounded-circle img-thumbnail border-danger" 
                          alt="GKJ"
                        ></img>
                      </div>
                    </Row>
                  }
                </Container>
              )
            }} 
          </Formik> 
        </Container>
      </div>
    </div>
  )
}

export default TransaksiList