import React, { useEffect, useState } from "react"
import useAuth from '../../hooks/useAuth'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

import "./transaksiadd.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Table from "../../components/table/Table"
import { useKlpList, useAknList, usePrgList, useKegList } 
  from '../../components/data/UseDataComponents'
import TransaksiEdit from "./TransaksiEdit"

import { Row, Container } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'

import NumberFormat from 'react-number-format'
import Logo from '../../images/Logo'
import moment from 'moment'

const imgLogo = Logo()

toast.configure()

const TransaksiAdd = () => {
  
  window.scrollTo(0, 0)
  const { auth } = useAuth()
  
  // const apiUrlAG = "/api/ag-transaksi"
  const apiUrlAG = auth.API_URL + "/ag-transaksi"

  const idGereja = auth.authIdGereja
  const idBakowi = auth.authIdBakowi
  const idUser = auth.authIdUser
  const [nomorIdTransaksi, setNomorIdTransaksi] = useState([])
  const [editDetail, setEditDetail] = useState(false)
  const [adaData, setAdaData] = useState(false)

  const axiosPrivate = useAxiosPrivate()

  const fetchTransaksiAddList = (idGereja, idBakowi) => {
    return axiosPrivate.get(`${apiUrlAG}/transaksiadd/${idGereja}/${idBakowi}`)
  }

  const useTransaksiAddList = (idGereja, idBakowi, onSuccess, onError) => {
    return useQuery(
      ['transaksiadd', idGereja, idBakowi], 
      () => fetchTransaksiAddList(idGereja, idBakowi), 
      {
        onSuccess,
        onError: () => {
          toast.error('Tidak dapat meng-akses data !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        select: (data) => {
          const transaksiAddList = data.data[0]
          return transaksiAddList
        }
      }
    )
  }

  const { data: transaksiList, isLoading, isSuccess, isError } = useTransaksiAddList(idGereja, idBakowi)
  const { data: klmpkList } = useKlpList(idGereja, idBakowi)
  const { data: aknList } = useAknList(idGereja, idBakowi)
  const { data: prgList } = usePrgList(idGereja, idBakowi)
  const { data: kegList } = useKegList(idGereja, idBakowi)
  
  useEffect(() => {
    setAdaData
      ( transaksiList && transaksiList[0]?.idGereja === 'nf' 
        ? false : true
      )
  }, [transaksiList])
  
  const transaksiTableHead = [
    // posisi judul [L]eft/[C]enter/[R]ight - nama judul
    'C-ID',
    'L-Keterangan',
    'L-Kelompok',
    'L-Akun',
    'L-Program',
    'L-Kegiatan',
    'L-No. Voucher',
    'L-Tanggal Transaksi',
    'R-Nilai',
    'L-Verifikasi',
  ]
  const renderHead = (item, index) => (
    <th 
      key={index} 
      className={item.substring(0,1) === 'C' 
        ? 'text-center' 
        : (item.substring(0,1) === 'R' 
          ? 'text-end' 
          : 'text-start')
      }
    >
      {item.substring(2)}
    </th>
  )

  const renderBody = (item, index) => (
    <tr 
      onClick={() => onClickHandleEdit(
        [item],
        true
      )} 
      key={index}
    >
      <td className="text-center">{item.idTransaksi}</td>
      <td>{item.keterangan}</td>
      <td>{item.namaKelompokTransaksi}</td>
      <td>{item.namaAkunKasBank}</td>
      <td>{item.namaProgram}</td>
      <td>{item.namaKegiatan}</td>
      <td>{item.nomorVoucher}</td>
      <td>{item.tanggalTransaksi && moment(item.tanggalTransaksi).format('DD-MMMM-YYYY')}</td>
      <td className="text-end">
        <NumberFormat
          displayType={"text"}
          thousandSeparator={"."}
          decimalSeparator={","}
          decimalScale={2}
          fixedDecimalScale={true}
          value={item.nilaiTransaksi}
        />
      </td>
      <td>{item.verifikasi}</td>
    </tr>
  )

  const onClickEntry = () => {
    onClickHandleEdit('', true)
  }

  const onClickHandleEdit = (noIdTransaksi, editDisplay) => {
    setNomorIdTransaksi(noIdTransaksi)
    setEditDetail(editDisplay)
  }

  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <h3 className="ms-4 my-3"> Entry Transaksi </h3>
        {!editDetail && 
          <button 
            type="button" 
            className="ms-4 mb-2 btn btn-sm btn-outline-primary fst-italic fw-bold" 
            onClick={() => onClickEntry()}
          > 
            <span className="bi bi-plus-circle-dotted mx-3" style={{fontSize: "21px"}}> Mulai Entry </span>
          </button>
        }
        <Container className="w-100">
          { isLoading && !editDetail && (
            <>
              <div className="w-100 d-flex mt-3 justify-content-center">
                  <Row className="w-25 justify-content-center">
                      <img 
                        src={imgLogo} 
                        width="15%" 
                        className="rounded-circle img-thumbnail border-danger" 
                        alt="GKJ"
                      ></img>
                  </Row>
              </div>
              <div className="w-100 mt-3 justify-content-center">
                  <Row className="text-center">
                      <strong> Loading ... </strong>
                  </Row>
                  <Row className="mb-3 justify-content-center">
                      <div className="spinner-grow mt-2 text-primary" role="status" aria-hidden="true"></div>
                  </Row>
              </div>
            </>
          )}
          { isError && (<div> Tabel transaksi tidak dapat diakses </div>)}
          { isSuccess && (
            <div>
              <Row>
                {editDetail &&
                  <>
                    <hr />
                    <TransaksiEdit
                      onClickHandleEdit = {onClickHandleEdit}
                      idGereja = {idGereja}
                      idBakowi = {idBakowi}
                      idUserMaint = {idUser}
                      nomorIdTransaksi = {nomorIdTransaksi[0]}
                      klmpkList = {klmpkList}
                      aknList = {aknList}
                      prgList = {prgList}
                      kegList = {kegList}
                    >
                    </TransaksiEdit>
                  </>
                }
              </Row>
              <Row className="w-100"> 
                { adaData 
                  ? 
                    <Table 
                      onClickHandleEdit = {onClickHandleEdit}
                      headData={transaksiTableHead}
                      renderHead={renderHead}
                      bodyData={transaksiList.filter((item) => item.idGereja !== 'nf')}
                      renderBody={renderBody}
                    />
                  : 
                    (<div> Belum ada data </div>)
                }
              </Row>
            </div>
          )}
        </Container>
      </div>
    </div>
  )
}

export default TransaksiAdd