import * as Yup from "yup"

// ##### format zip code
// Yup.object().shape({
//     zipCode: Yup.number()
//       .required('Zip code is a required field')// optional
//       .typeError('Zip code can only be a number')// optional as well
//       .test('len', 'Zip code needs to be excatly 5 digits', val => val.toString().length === 5)
//   });

export const EditProgramValidationSchema = Yup.object().shape({
    idProgram: Yup.number()
        .required('id program harus diisi angka')
        .typeError('harus diisi angka')
        .moreThan(0, 'harus diisi integer > 0'),
    namaProgram: Yup.string()
        .required('Nama program perlu diisi'),
    kategori: Yup.string()
        .required('kategori program perlu diisi [R]utin, [N]on Rutin')
        .oneOf(['R', 'N'], 'diisi R atau N (huruf besar)')
        .min(1, 'hanya diisi R atau N')
        .max(1, 'hanya diisi R atau N'),
});

export const EditKegiatanValidationSchema = Yup.object().shape({
    idKegiatan: Yup.number()
        .required('id kegiatan harus diisi angka')
        .typeError('harus diisi angka')
        .moreThan(0, 'harus diisi integer > 0'),
    namaKegiatan: Yup.string()
        .required('Nama kegiatan perlu diisi'),
    idProgram: Yup.number()
        .required('program harus diisi'),
    prioritas: Yup.string()
        .required('kategori program perlu diisi [R]utin, [N]on Rutin')
        .oneOf(['T', 'S', 'R'], 'diisi [T]inggi / [S]edang / [R]endah')
        .max(1, 'hanya diisi T / S / R'),
});

export const EditAkunKasBankValidationSchema = Yup.object().shape({
    idAkunKasBank: Yup.number()
        .required('Id akun harus diisi angka')
        .typeError('harus diisi angka')
        .moreThan(0, 'diisi angka 1-255')
        .lessThan(256, 'diisi angka 1-255'),
    namaAkunKasBank: Yup.string()
        .required('Nama jenis program perlu diisi'),
    tipe: Yup.string()
        .required('tipe akun perlu diisi [K]as, [B]ank')
        .oneOf(['K', 'B'], 'diisi K atau B (huruf besar)')
        .min(1, 'hanya diisi K atau B')
        .max(1, 'hanya diisi K atau B'),
    idPerkiraan: Yup.string()
        .required('nomor perkiraan perlu diisi')
        .typeError('hanya boleh diisi dengan angka')
        .min(8, 'harus 8 digit')
        .max(8, 'harus 8 digit')
        .length(8, 'harus 8 digit'),
});

export const EditKelompokTransaksiValidationSchema = Yup.object().shape({
    idKelompokTransaksi: Yup.number()
        .required('Id jenis program harus diisi angka')
        .typeError('harus diisi angka')
        .moreThan(0, 'diisi angka 1-255')
        .lessThan(256, 'diisi angka 1-255'),
    namaKelompokTransaksi: Yup.string()
        .required('Nama jenis program perlu diisi'),
    inisial: Yup.string()
        .required('Inisial kelompok perlu diisi')
        .max(3, 'maksimal 3 digit'),        
    tipe: Yup.string()
        .required('tipe transaksi perlu diisi [T] Penerimaan, [K] Pengeluaran')
        .oneOf(['T', 'K'], 'diisi T atau K (huruf besar)')
        .min(1, 'hanya diisi T atau K')
        .max(1, 'hanya diisi T atau K'),
    idPerkiraan: Yup.string()
        .required('nomor perkiraan perlu diisi')
        .typeError('hanya boleh diisi dengan angka')
        .min(8, 'harus 8 digit')
        .max(8, 'harus 8 digit')
        .length(8, 'harus 8 digit'),
});

export const EditJenisProgramValidationSchema = Yup.object().shape({
    idJenisProgram: Yup.number()
        .required('Id jenis program harus diisi angka')
        .typeError('harus diisi angka')
        .moreThan(0, 'diisi angka 1-255')
        .lessThan(256, 'diisi angka 1-255'),
    namaJenisProgram: Yup.string()
        .required('Nama jenis program perlu diisi'),
});