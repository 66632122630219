import React, { useState, useEffect } from 'react'
import useAuth from '../../hooks/useAuth'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

// import "./editprogram.scss"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import { EditProgramValidationSchema } from '../../components/data/ValidationSchema'
import TextError from '../../components/form/TextError'
import CustomSelect from '../../components/form/CustomSelect'
import NumberFormat from 'react-number-format'
import DatePickerField from '../../components/form/DatePickerField'

require('moment/locale/id')
let config = ""
toast.configure()

const EditProgram = ({onClickHandleEdit, idGereja, idBakowi, idUserMaint, nomorIdProgram, jnsPrgList, wrgList}) => {
  
  window.scrollTo(0, 0)
  const { auth } = useAuth()

  // const apiUrlAG = "/api/ag-program"
  const apiUrlAG = auth.API_URL + "/ag-program"

  const [jenisProgramList, setJenisProgramList] = useState([])
  const [wargaList, setWargaList] = useState([])

  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const addProgram = (input) => {
    config = {
      method : "post",
      url : `${apiUrlAG}/program/}`,
      headers : {
        "Content-Type":"application/json"
      },
      data : {
        idGereja: input.idGereja,
        idBakowi: input.idBakowi,
        namaProgram: input.namaProgram,
        idJenisProgram: input.idJenisProgram,
        deskripsi: input.deskripsi,
        kategori: input.kategori,
        danaDukunganMajelis: input.danaDukunganMajelis,
        tanggalRencanaMulai: input.tanggalRencanaMulai,
        tanggalRencanaSelesai: input.tanggalRencanaSelesai,
        tanggalPengajuan: input.tanggalPengajuan,
        tanggalPersetujuan: input.tanggalPersetujuan,
        idPenanggungjawab: input.idPenanggungjawab,
        counterpart: input.counterpart,
        statusProgram: input.statusProgram,
        statusTanggal: input.statusTanggal,
        keterangan: input.keterangan,
        idUser: input.idUserMaint,
        userActivity: 'Add'
      }
    }
    return axiosPrivate(config)
  }

  const useAddProgram = () => {
    return useMutation(
      addProgram, 
      {
        onError: () => {
          toast.error('Data program gagal ditambah !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: (data) => {
          toast.success('Data program berhasil ditambah ...', data, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
          queryClient.invalidateQueries()
        },
      }
    )
  }

  const updateProgram = (input) => {
    const config = {
      method : "patch",
      url : `${apiUrlAG}/program/${input.idProgram}`,
      headers : {
        "Content-Type":"application/json"
      },
      data : {
        idProgram: input.idProgram,
        namaProgram: input.namaProgram,
        idJenisProgram: input.idJenisProgram,
        deskripsi: input.deskripsi,
        kategori: input.kategori,
        danaDukunganMajelis: input.danaDukunganMajelis,
        tanggalRencanaMulai: input.tanggalRencanaMulai,
        tanggalRencanaSelesai: input.tanggalRencanaSelesai,
        tanggalPengajuan: input.tanggalPengajuan,
        tanggalPersetujuan: input.tanggalPersetujuan,
        idPenanggungjawab: input.idPenanggungjawab,
        counterpart: input.counterpart,
        statusProgram: input.statusProgram,
        statusTanggal: input.statusTanggal,
        keterangan: input.keterangan,
        idUser: input.idUserMaint,
        userActivity: 'Update'
      }
    }
    return axiosPrivate(config)
  }

  const useUpdateProgram = () => {
    return useMutation(
      updateProgram, 
      {
        onError: (err) => {
          toast.error('Data program gagal diubah !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: (data) => {
          toast.success('Data program berhasil diubah ...', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
          queryClient.invalidateQueries()
        },
      }
    )
  }

  useEffect(() => {
    jnsPrgList && setJenisProgramList(jnsPrgList)
    wrgList && setWargaList(wrgList)
  }, [jnsPrgList, wrgList])

  let menuScreenClassName = 'w-100 justify-content-end text-center border-0 fw-bold text-light'
  let btnTambahClassName = 'btn btn-outline-primary btn-sm fst-italic fw-bold'
  let btnUbahClassName = 'btn btn-outline-success btn-sm fst-italic fw-bold'
  let btnConfirmClassName = 'btn btn-sm fst-italic fw-bold text-light'
  let btnCancelClassName = 'btn btn-dark btn-sm fst-italic fw-bold text-light'
  const initialValues = {
    idUserMaint: idUserMaint,
    idGereja: idGereja,
    idProgram: nomorIdProgram ? nomorIdProgram.idProgram : '',
    idBakowi: idBakowi,
    namaProgram: nomorIdProgram ? nomorIdProgram.namaProgram : '',
    idJenisProgram: nomorIdProgram ? nomorIdProgram.idJenisProgram : '',
    jenisProgram: nomorIdProgram ? nomorIdProgram.jenisProgram : '',
    deskripsi: nomorIdProgram ? nomorIdProgram.deskripsi : '',
    kategori: nomorIdProgram ? nomorIdProgram.kategori : '',
    kategoriNama: nomorIdProgram 
      ? (
          nomorIdProgram.kategori === 'R' 
            ? 'Rutin' 
            : (
                nomorIdProgram.kategori === 'N' 
                  ? 'Non Rutin' 
                  : '*?*'
              )
        ) 
      : '',
    danaDukunganMajelis: nomorIdProgram ? nomorIdProgram.danaDukunganMajelis : '',
    tanggalRencanaMulai: nomorIdProgram ? nomorIdProgram.tanggalRencanaMulai : '',
    tanggalRencanaSelesai: nomorIdProgram ? nomorIdProgram.tanggalRencanaSelesai : '',
    tanggalPengajuan: nomorIdProgram ? nomorIdProgram.tanggalPengajuan : '',
    tanggalPersetujuan: nomorIdProgram ? nomorIdProgram.tanggalPersetujuan : '',
    idPenanggungjawab: nomorIdProgram ? nomorIdProgram.idPenanggungjawab : '',
    namaPenanggungjawab: nomorIdProgram ? nomorIdProgram.namaPenanggungjawab : '',
    counterpart: nomorIdProgram ? nomorIdProgram.counterpart : '',
    statusProgram: nomorIdProgram ? nomorIdProgram.statusProgram : '',
    statusTanggal: nomorIdProgram ? nomorIdProgram.statusTanggal : '',
    keterangan: nomorIdProgram ? nomorIdProgram.keterangan : '',
    idUser: nomorIdProgram ? (nomorIdProgram.idUser ? nomorIdProgram.idUser : '*') : idUserMaint,
    namaUser: nomorIdProgram && nomorIdProgram.namaUser ? nomorIdProgram.namaUser : '*',
    userActivity: nomorIdProgram && nomorIdProgram.userActivity ? nomorIdProgram.userActivity : '*',
    tanggalSystem: nomorIdProgram && nomorIdProgram.tanggalSystem ? nomorIdProgram.tanggalSystem : '*',
    menuScreen: nomorIdProgram ? 'Idle' : 'A D D',
    menuClass: menuScreenClassName.concat(nomorIdProgram ? ' bg-info' : ' bg-primary'),
    fieldDisable: nomorIdProgram ? true : false,
    btnMenuDisable: false,
    btnTambahClass: btnTambahClassName.concat(nomorIdProgram ? '' : ' invisible'),
    btnUbahClass: btnUbahClassName.concat(nomorIdProgram ? '' : ' invisible'),
    btnConfirmClass: btnConfirmClassName.concat(nomorIdProgram ? ' invisible' : ' bg-primary visible'),
    btnCancelClass: btnCancelClassName.concat(nomorIdProgram ? ' invisible' : ' bg-primary visible'),
    btnConfirmText: nomorIdProgram ? '' : 'Tambahkan data ...'
  }

  const { mutate: addPrg } = useAddProgram()
  const { mutate: updatePrg } = useUpdateProgram()

  const onClickConfirm = (input) => {
    if (input.menuScreen === "A D D") {
      addPrg(input)
    }
    if (input.menuScreen === "UPDATE") {
      updatePrg(input)
    }
    onClickHandleEdit(false)
  }

  const clickExit = () => {
    onClickHandleEdit(false)
  }

  const renderNilai = ({
    field: { name, value, onChange, onBlur },
    form: { touched, errors, values, setFieldValue, setFieldTouched },
    ...custom
  }) => {
    return (
      <NumberFormat
        name={name}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        value={value}
        onValueChange={val =>
            setFieldValue(name, val.floatValue)
        }
        {...custom}
      />
    )
  }

  return ( 
    <Formik
      initialValues={initialValues}
      validationSchema={EditProgramValidationSchema}
      enableReinitialize
    >
      {formik => {
        // function screenIdle
        const screenIdle = () => {
          formik.setFieldValue('menuScreen', 'Idle')
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'visible'))
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-primary'))
          // show buttonTU => 'FF'
          formik.setFieldValue('btnMenuDisable', false)
          // hidden button konfirmasi
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'invisible'))
          // disable semua field
          formik.setFieldValue('fieldDisable', true)
        }
        // function clickTambah
        const clickTambah = () => {
          formik.setFieldValue('idProgram', '')
          formik.setFieldValue('namaProgram', '')
          formik.setFieldValue('idJenisProgram', '')
          formik.setFieldValue('jenisProgram', '')
          formik.setFieldValue('deskripsi', '')
          formik.setFieldValue('kategori', '')
          formik.setFieldValue('kategoriNama', '')
          formik.setFieldValue('danaDukunganMajelis', '')
          formik.setFieldValue('tanggalRencanaMulai', '')
          formik.setFieldValue('tanggalRencanaSelesai', '')
          formik.setFieldValue('tanggalPengajuan', '')
          formik.setFieldValue('tanggalPersetujuan', '')
          formik.setFieldValue('idPenanggungjawab', '')
          formik.setFieldValue('namaPenanggungjawab', '')
          formik.setFieldValue('counterpart', '')
          formik.setFieldValue('statusProgram', '')
          formik.setFieldValue('statusTanggal', '')
          formik.setFieldValue('keterangan', '')
          formik.setFieldValue('idUser', idUserMaint)
          formik.setFieldValue('menuScreen', 'A D D')
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-primary'))
          // hidden buttonTU => [T]ambah - [U]bah
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'invisible'))
          // show button konfirmasi (Tambahkan data ... & Batal)
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'bg-primary', ' ', 'visible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnConfirmText', 'Tambahkan data ...')
          // enable semua field
          formik.setFieldValue('fieldDisable', false)
        }
        // function clickUbah
        const clickUbah = () => {
          formik.setFieldValue('menuScreen', 'UPDATE')
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-success'))
          // hidden buttonTU
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'invisible'))
          // show button konfirmasi (Ubah data ... & Batal)
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'bg-primary', ' ', 'visible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnConfirmText', 'Ubah data ...')
          // enable semua field
          formik.setFieldValue('fieldDisable', false)
        }
        function renderKategori(e) {
          const kategoriNm = e.target.value
          formik.handleChange(e)
          switch (kategoriNm.toUpperCase()) {
            case "R":
              formik.setFieldValue('kategoriNama', 'Rutin')
              break
            case "N":
              formik.setFieldValue('kategoriNama', 'Non Rutin')
              break
            default:
              formik.setFieldValue('kategoriNama', 'Kategori salah')
              break
          }
        }
        return (
          <div className="container">
            <Form className="w-100">
              <Row className="fw-bold mb-1">
                <Field name="idUserMaint" type="hidden"/>
                <Field name="idGereja" type="hidden"/>
                <Field name="idBakowi" type="hidden"/>
                <Field name="fieldDisable" type="hidden"/>
                <Field name="menuClass" type="hidden"/>
                <Field name="btnTambahClass" type="hidden"/>
                <Field name="btnUbahClass" type="hidden"/>
                <Field name="btnMenuDisable" type="hidden"/>
                <Field name="btnConfirmClass" type="hidden"/>
                <Field name="btnCancelClass" type="hidden"/>
                <Field name="btnConfirmText" type="hidden"/>
                <Col md={9} sm={6} xs={6}>
                  <p className="mb-1">
                    <span className="text-primary fw-bold"> 
                      <i className="bi bi-arrow-right-square-fill"></i> 
                    </span> &nbsp; 
                    Nama Program &nbsp; : &nbsp; <strong>{formik.values.namaProgram}</strong> 
                  </p>
                </Col>
                <Col md={3} sm={3} xs={3}>
                  <Field name="menuScreen" disabled={true} className={formik.values.menuClass}/>
                </Col>
              </Row>
              <hr className='mb-2'/>
              <Row>
                <Col className="form-group col-2 justify-content-start">
                  <label className="text-primary w-30" htmlFor="idProgram"> Id </label>
                </Col>
                <Col className="form-group col-5 justify-content-start">
                  <label className="text-primary w-30" htmlFor="namaProgram"> Nama </label>
                </Col>
                <Col className="form-group col-5 justify-content-start">
                  <label className="text-primary w-30" htmlFor="idJenisProgram"> Jenis Program </label>
                </Col>
              </Row>
              <Row>
                <Col className="form-group col-2 justify-content-start">
                  <Field 
                    className="text-center w-50" 
                    id="idProgram" 
                    name="idProgram" 
                    disabled={true} 
                    placeholder="id" 
                  />
                </Col>
                <Col className="form-group col-5 justify-content-start">
                  <Field 
                    className="text-start w-100" 
                    id="namaProgram" 
                    name="namaProgram" 
                    disabled={formik.values.fieldDisable} 
                    placeholder="Nama Program" 
                  />
                  <ErrorMessage name="namaProgram" component={TextError} className="invalid-feedback text-warning" />
                </Col>
                <Col className="form-group col-5 justify-content-start">
                  <CustomSelect
                    options={jenisProgramList}
                    value={formik.values.idJenisProgram}
                    placeholder="Ketik / Pilih => id / Nama Jenis Program"
                    onChange={value => value ? formik.setFieldValue('idJenisProgram', value.value) : null}
                    disableSelect={formik.values.fieldDisable}
                    clearable={false}
                    className="mb-1 w-100"
                  />
                  <ErrorMessage name="idJenisProgram" component={TextError} className="invalid-feedback" />
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col className="form-group col-2 justify-content-start">
                  <label className="text-primary w-25" htmlFor="kategori"> Kategori </label>
                </Col>
                <Col className="form-group col-5 justify-content-start">
                  <label className="text-primary" htmlFor="idPenanggungjawab"> Penanggungjawab </label>
                </Col>
                <Col className="form-group col-5 justify-content-start">
                  <label className="text-primary w-15" htmlFor="deskripsi"> Deskripsi </label>
                </Col>
              </Row>
              <Row>
                <Col className="form-group col-2 justify-content-start">
                  <Field 
                    className="text-center w-20" 
                    id="kategori" 
                    name="kategori" 
                    onChange={renderKategori}
                    disabled={formik.values.fieldDisable} 
                  />
                  <Field className={
                    "text-center w-70 ms-1".concat(
                        ['R','N'].includes(formik.values.kategori) 
                          ? ' text-success border-success' 
                          : ' text-warning border-danger'
                      )
                    } 
                    id="kategoriNama" 
                    name="kategoriNama" 
                    disabled={true} 
                  />
                  <ErrorMessage name="kategori" component={TextError} className="invalid-feedback text-warning" />
                </Col>
                <Col className="form-group col-5 justify-content-start">
                  <CustomSelect
                    options={wargaList}
                    value={formik.values.idPenanggungjawab}
                    placeholder="Ketik / Pilih => Nomor / Nama Warga"
                    onChange={value => value ? formik.setFieldValue('idPenanggungjawab', value.value) : null}
                    disableSelect={formik.values.fieldDisable}
                    clearable={false}
                    className="mb-1 w-100"
                  />
                  <ErrorMessage name="idPenanggungjawab" component={TextError} className="invalid-feedback" />
                </Col>
                <Col className="form-group col-5 justify-content-start">
                  <Field 
                    as='textarea' 
                    className="text-start w-100" 
                    id="deskripsi"
                    name="deskripsi" 
                    disabled={formik.values.fieldDisable} 
                    placeholder="Deskripsi" 
                  />
                  <ErrorMessage name="deskripsi" component={TextError} className="invalid-feedback text-warning" />
                </Col>
              </Row>
              <hr className='mb-1'/>
              <Row>
                <Col className="form-group col-3">
                  <label 
                    className="text-primary w-75" 
                    htmlFor="tanggalRencanaMulai"
                  > Tanggal Rencana Mulai </label>
                </Col>
                <Col className="form-group col-3">
                  <label 
                    className="text-primary w-75" 
                    htmlFor="tanggalRencanaSelesai"
                  > Tanggal Rencana Selesai </label>
                </Col>
                <Col className="form-group col-3">
                  <label 
                    className="text-primary w-75" 
                    htmlFor="tanggalPengajuan"
                  > Tanggal Pengajuan </label>
                </Col>
                <Col className="form-group col-3">
                  <label 
                    className="text-primary w-75" 
                    htmlFor="tanggalPersetujuan"
                  > Tanggal Persetujuan </label>
                </Col>
              </Row>
              <Row>
                {/* min dan max date nya jgn dibatasin !!! */}
                <Col className="form-group col-3">
                  <DatePickerField 
                    className="w-100" 
                    name="tanggalRencanaMulai" 
                    disabled={formik.values.fieldDisable}
                  />
                </Col>
                <Col className="form-group col-3">
                  <DatePickerField 
                    className="w-100" 
                    name="tanggalRencanaSelesai" 
                    disabled={formik.values.fieldDisable}
                  />
                </Col>
                <Col className="form-group col-3">
                  <DatePickerField 
                    className="w-100" 
                    name="tanggalPengajuan" 
                    disabled={formik.values.fieldDisable}
                  />
                </Col>
                <Col className="form-group col-3">
                  <DatePickerField 
                    className="w-100" 
                    name="tanggalPersetujuan" 
                    disabled={formik.values.fieldDisable}
                  />
                </Col>
              </Row>
              <hr className='mb-1'/>
              <Row>
                <Col className="form-group col-3">
                  <label className="text-primary w-50" htmlFor="danaDukunganMajelis"> Dana Dukungan </label>
                </Col>
                <Col className="form-group col-3">
                  <label className="text-primary w-50" htmlFor="counterpart"> Counterpart </label>
                </Col>
                <Col className="form-group col-3">
                  <label className="text-primary w-50" htmlFor="statusProgram"> Status Program </label>
                </Col>
                <Col className="form-group col-3">
                  <label className="text-primary w-50" htmlFor="statusTanggal"> Tanggal Status </label>
                </Col>
              </Row>
              <Row>
                <Col className="form-group col-3">
                  <Field 
                    className="text-end w-100" 
                    id="danaDukunganMajelis" 
                    name="danaDukunganMajelis"  
                    component={renderNilai} 
                    disabled={formik.values.fieldDisable} 
                  />
                </Col>
                <Col className="form-group col-3">
                  <Field 
                    className="text-start w-100" 
                    id="counterpart" 
                    name="counterpart" 
                    disabled={formik.values.fieldDisable} 
                  />
                </Col>
                <Col className="form-group col-3">
                  <Field 
                    className="text-start w-100" 
                    id="statusProgram" 
                    name="statusProgram" 
                    disabled={formik.values.fieldDisable} 
                  />
                </Col>
                <Col className="form-group col-3">
                  <DatePickerField 
                    className="w-100" 
                    name="statusTanggal" 
                    disabled={formik.values.fieldDisable}
                  />
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col className="form-group col-12">
                  <label className="text-primary w-10" htmlFor="keterangan"> Keterangan </label>
                  <Field 
                    className="text-start w-100" 
                    id="keterangan" 
                    name="keterangan" 
                    disabled={formik.values.fieldDisable} 
                  />
                </Col>
              </Row>
              <hr />
              <Row className="fw-bold small mb-0">
                <p>
                  <span className="text-warning"> <i className="bi bi-x-circle-fill"></i> </span> &nbsp; 
                  Maintenance : {formik.values.userActivity} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                  <span className="text-warning fw-bold"> <i className="bi bi-arrow-right-circle"></i> </span> &nbsp; 
                  Id User Maint : {formik.values.idUser} - {formik.values.namaUser} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="text-warning fw-bold"> <i className="bi bi-arrow-right-circle"></i> </span> &nbsp; 
                  Tanggal Maintenance : {
                    formik.values.tanggalSystem === '*' 
                      ? formik.values.tanggalSystem 
                      : moment(formik.values.tanggalSystem).format('LLLL')
                  } &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
              </Row>
              <Row className="fw-bold small mt-0">
                <Col className="justify-content-start">
                  <button 
                    type="button" 
                    className={formik.values.btnConfirmClass}
                    disabled={
                      (
                        formik.values.namaProgram &&
                        formik.values.kategori && 
                        ['R','N'].includes(formik.values.kategori) && 
                        formik.values.danaDukunganMajelis > 0 
                      ) 
                        ? false : true}
                    onClick={() => onClickConfirm(formik.values)}
                  > {formik.values.btnConfirmText} </button>
                  &nbsp;&nbsp;&nbsp;
                  <button 
                    type="button" 
                    className={formik.values.btnCancelClass} 
                    onClick={() => formik.values.menuScreen === "UPDATE" ? screenIdle() : onClickHandleEdit(false)}
                  > Batal </button>
                </Col>
                <Col className="text-end">
                  <button 
                    type="button" 
                    className={formik.values.btnTambahClass} 
                    disabled={formik.values.btnMenuDisable}
                    onClick={() => clickTambah()}
                  > Tambah </button>
                  &nbsp;&nbsp;&nbsp;
                  <button 
                    type="button" 
                    className={formik.values.btnUbahClass} 
                    disabled={formik.values.btnMenuDisable} 
                    onClick={() => clickUbah()}
                  > Ubah </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button 
                    type="button"
                    className='btn btn-outline-secondary btn-sm fst-italic fw-bold'
                    onClick={() => clickExit()}
                  > Keluar </button>
                </Col>                                
              </Row>
              <hr/>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

export default EditProgram