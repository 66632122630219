import axios from "../api/axios"
import useAuth from "./useAuth"

const useRefreshToken = () => {
  const { setAuth } = useAuth()
  
  const refresh = async () => {
    // const response = await axios.get("/api/auth/refreshtoken", {
    const response = await axios.get("https://ag.gkj.web.id/api/auth/refreshtoken", {
      withCredentials: true,
    })
    setAuth((prev) => {
      return {
        ...prev,
        // roles: response.data.roles,
        accessToken: response.data.accessToken
      };
    });
    return response.data.accessToken
  };
  return refresh
};

export default useRefreshToken
