import React from 'react'

import { Table } from 'react-bootstrap'

import "./table.scss"

const colStyle = {
  // backgroundColor: '#002b28',
  // color: "#ffffff",
  backgroundColor: '#004b48',
  color: "#00ffff",
  fontSize: "12px",
}

const List = ({onClickHandleEdit, headData, renderHead, bodyData, renderBody}) => {
  return (
    <div>
      <div className="table w-auto small">
        <Table bordered hover>
          {
            headData && renderHead ? (
              <thead style={colStyle}>
                <tr>
                  {
                    headData.map((item, index) => renderHead(item, index))
                  }
                </tr>
              </thead>
            ) : null
          }
          {
            bodyData && renderBody ? (
              <tbody className="fw-bold text-primary dark-background" style={{fontSize: "11px"}}>
                {
                  bodyData.map((item, index) => renderBody(item, index))
                }
              </tbody>
            ) : null
          }
        </Table>
      </div>
    </div>
  )
}

export default List
