import React from 'react'

export const TransaksiListTableColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column
  return (
    <span>
      Cari: {' '}
      <input className="w-100" value={filterValue || ''} onChange={(e) => setFilter(e.target.value)} />
    </span>
  )
}
