import React, { useEffect } from "react"

import "./profileuser.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import ProfileNPasswordChange from "../../components/profile/ProfileNPasswordChange"

const ProfileUser = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <h3 className="ms-4 my-3"> Profile & Change Password </h3>
        <ProfileNPasswordChange className="mt-5 ms-0 ps-0"/>
      </div>
    </div>
  )
}

export default ProfileUser