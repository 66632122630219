import React, { useState, useEffect } from 'react'
import useAuth from '../../hooks/useAuth'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import { EditKegiatanValidationSchema } from '../../components/data/ValidationSchema'
import TextError from '../../components/form/TextError'
import CustomSelect from '../../components/form/CustomSelect'
import NumberFormat from 'react-number-format'
import DatePickerField from '../../components/form/DatePickerField'

require('moment/locale/id')
let config = ""
toast.configure()

const EditKegiatan = ({onClickHandleEdit, idGereja, idBakowi, idUserMaint, nomorIdKegiatan, prgList, wrgList}) => {
  
  window.scrollTo(0, 0)
  const { auth } = useAuth()

  // const apiUrlAG = "/api/ag-kegiatan"
  const apiUrlAG = auth.API_URL + "/ag-kegiatan"

  const [programList, setProgramList] = useState()
  const [wargaList, setWargaList] = useState([])

  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const addKegiatan = (input) => {
    config = {
      method : "post",
      url : `${apiUrlAG}/kegiatan/`,
      headers : {
        "Content-Type":"application/json"
      },
      data : {
        idProgram: input.idProgram,
        idKegiatan: input.idKegiatan,
        idGereja: input.idGereja,
        namaKegiatan: input.namaKegiatan,
        deskripsi: input.deskripsi,
        tanggalMulai: input.tanggalMulai,
        tanggalSelesai: input.tanggalSelesai,
        prioritas: input.prioritas,
        anggaran: input.anggaran,
        lokasi: input.lokasi,
        bobot: input.bobot,
        counterpart: input.counterpart,
        idPenanggungjawab: input.idPenanggungjawab,
        statusKegiatan: input.statusProgram,
        statusTanggal: input.statusTanggal,
        keterangan: input.keterangan,
        idUser: input.idUserMaint,
        userActivity: 'Add'
      }
    }
    return axiosPrivate(config)
  }

  const useAddKegiatan = () => {
    return useMutation(
      addKegiatan, 
      {
        onError: () => {
          toast.error('Data kegiatan gagal ditambah !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: (data) => {
          toast.success('Data kegiatan berhasil ditambah ...', data, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
          console.log('sukses data 1: ', data)
          queryClient.invalidateQueries()
        },
      }
    )
  }

  const updateKegiatan = (input) => {
    const config = {
      method : "patch",
      url : `${apiUrlAG}/kegiatan/${input.idProgram}/${input.idKegiatan}`,
      headers : {
        "Content-Type":"application/json"
      },
      data : {
        idGereja: input.idGereja,
        namaKegiatan: input.namaKegiatan,
        deskripsi: input.deskripsi,
        tanggalMulai: input.tanggalMulai,
        tanggalSelesai: input.tanggalSelesai,
        prioritas: input.prioritas,
        anggaran: input.anggaran,
        lokasi: input.lokasi,
        bobot: input.bobot,
        counterpart: input.counterpart,
        idPenanggungjawab: input.idPenanggungjawab,
        statusKegiatan: input.statusProgram,
        statusTanggal: input.statusTanggal,
        keterangan: input.keterangan,
        idUser: input.idUserMaint,
        userActivity: 'Update'
      }
    }
    return axiosPrivate(config)
  }

  const useUpdateKegiatan = () => {
    return useMutation(
      updateKegiatan, 
      {
        onError: (err) => {
          toast.error('Data kegiatan gagal diubah !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        onSuccess: (data) => {
          toast.success('Data kegiatan berhasil diubah ...', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
          queryClient.invalidateQueries()
        },
      }
    )
  }

  useEffect(() => {
    prgList && setProgramList(prgList)
    wrgList && setWargaList(wrgList)
  }, [prgList, wrgList])

  let menuScreenClassName = 'w-100 justify-content-end text-center border-0 fw-bold text-light'
  let btnTambahClassName = 'btn btn-outline-primary btn-sm fst-italic fw-bold'
  let btnUbahClassName = 'btn btn-outline-success btn-sm fst-italic fw-bold'
  let btnConfirmClassName = 'btn btn-sm fst-italic fw-bold text-light'
  let btnCancelClassName = 'btn btn-dark btn-sm fst-italic fw-bold text-light'
  const initialValues = {
    idUserMaint: idUserMaint,
    idProgram: nomorIdKegiatan ? parseInt(nomorIdKegiatan.idProgram, 10) : '',
    namaProgram: nomorIdKegiatan ? nomorIdKegiatan.namaProgram : '',
    idKegiatan: nomorIdKegiatan ? nomorIdKegiatan.idKegiatan : '',
    idGereja: idGereja,
    idBakowi: idBakowi,
    namaKegiatan: nomorIdKegiatan ? nomorIdKegiatan.namaKegiatan : '',
    deskripsi: nomorIdKegiatan && nomorIdKegiatan.deskripsi ? nomorIdKegiatan.deskripsi : '',
    tanggalMulai: nomorIdKegiatan ? nomorIdKegiatan.tanggalMulai : '',
    tanggalSelesai: nomorIdKegiatan && nomorIdKegiatan.tanggalSelesai ? nomorIdKegiatan.tanggalSelesai : '',
    prioritas: nomorIdKegiatan ? nomorIdKegiatan.prioritas : '',
    prioritasNama: nomorIdKegiatan 
      ? (
          nomorIdKegiatan.prioritas === 'T' 
            ? 'Tinggi' 
            : (
                nomorIdKegiatan.prioritas === 'S' 
                  ? 'Sedang' 
                  : (
                      nomorIdKegiatan.prioritas === 'R' 
                        ? 'Rendah' 
                        : 'N/A'
                    )
              )
        ) : '',
    anggaran: nomorIdKegiatan ? nomorIdKegiatan.anggaran : '',
    lokasi: nomorIdKegiatan ? nomorIdKegiatan.lokasi : '',
    bobot: nomorIdKegiatan ? nomorIdKegiatan.bobot : '',
    counterpart: nomorIdKegiatan ? nomorIdKegiatan.counterpart : '',
    idPenanggungjawab: nomorIdKegiatan ? nomorIdKegiatan.idPenanggungjawab : '',
    namaPenanggungjawab: nomorIdKegiatan ? nomorIdKegiatan.namaPenanggungjawab : '',
    statusKegiatan: nomorIdKegiatan && nomorIdKegiatan.statusKegiatan ? nomorIdKegiatan.statusKegiatan : '',
    statusTanggal: nomorIdKegiatan && nomorIdKegiatan.statusTanggal ? nomorIdKegiatan.statusTanggal : '',
    keterangan: nomorIdKegiatan && nomorIdKegiatan.keterangan ? nomorIdKegiatan.keterangan : '',
    idUser: nomorIdKegiatan ? (nomorIdKegiatan.idUser ? nomorIdKegiatan.idUser : '*') : idUserMaint,
    namaUser: nomorIdKegiatan && nomorIdKegiatan.namaUser ? nomorIdKegiatan.namaUser : '*',
    userActivity: nomorIdKegiatan && nomorIdKegiatan.userActivity ? nomorIdKegiatan.userActivity : '*',
    tanggalSystem: nomorIdKegiatan && nomorIdKegiatan.tanggalSystem ? nomorIdKegiatan.tanggalSystem : '*',
    menuScreen: nomorIdKegiatan ? 'Idle' : 'A D D',
    menuClass: menuScreenClassName.concat(nomorIdKegiatan ? ' bg-info' : ' bg-primary'),
    fieldDisableKey: nomorIdKegiatan ? true : false,
    fieldDisable: nomorIdKegiatan ? true : false,
    btnMenuDisable: false,
    btnTambahClass: btnTambahClassName.concat(nomorIdKegiatan ? '' : ' invisible'),
    btnUbahClass: btnUbahClassName.concat(nomorIdKegiatan ? '' : ' invisible'),
    btnConfirmClass: btnConfirmClassName.concat(nomorIdKegiatan ? ' invisible' : ' bg-primary visible'),
    btnCancelClass: btnCancelClassName.concat(nomorIdKegiatan ? ' invisible' : ' bg-primary visible'),
    btnConfirmText: nomorIdKegiatan ? '' : 'Tambahkan data ...'
  }
  const { mutate: addKgt } = useAddKegiatan()
  const { mutate: updateKgt } = useUpdateKegiatan()

  const onClickConfirm = (input) => {
    if (input.menuScreen === "A D D") {
      addKgt(input)
    }
    if (input.menuScreen === "UPDATE") {
      updateKgt(input)
    }
    onClickHandleEdit(false)
  }

  const clickExit = () => {
    onClickHandleEdit(false)
  }

  const renderInt = ({
    field: { name, value, onChange, onBlur },
    form: { touched, errors, values, setFieldValue, setFieldTouched },
    ...custom
  }) => {
    return (
      <NumberFormat
        name={name}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={0}
        fixedDecimalScale={true}
        value={value}
        onValueChange={val =>
            setFieldValue(name, val.floatValue)
        }
        {...custom}
      />
    )
  }

  const renderNilai = ({
    field: { name, value, onChange, onBlur },
    form: { touched, errors, values, setFieldValue, setFieldTouched },
    ...custom
  }) => {
    return (
      <NumberFormat
        name={name}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        value={value}
        onValueChange={val =>
          setFieldValue(name, val.floatValue)
        }
        {...custom}
      />
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EditKegiatanValidationSchema}
      enableReinitialize
    >
      {formik => {
        // function screenIdle
        const screenIdle = () => {
          formik.setFieldValue('menuScreen', 'Idle')
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'visible'))
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-primary'))
          // show buttonTU => 'FF'
          formik.setFieldValue('btnMenuDisable', false)
          // hidden button konfirmasi
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'invisible'))
          // disable semua field
          formik.setFieldValue('fieldDisableKey', true)
          formik.setFieldValue('fieldDisable', true)
        }
        // function clickTambah
        const clickTambah = () => {
          formik.setFieldValue('idKegiatan', '')
          formik.setFieldValue('namaKegiatan', '')
          formik.setFieldValue('idKegiatan', '')
          formik.setFieldValue('namaKegiatan', '')
          formik.setFieldValue('deskripsi', '')
          formik.setFieldValue('tanggalMulai', '')
          formik.setFieldValue('tanggalSelesai', '')
          formik.setFieldValue('prioritas', '')
          formik.setFieldValue('prioritasNama', '')
          formik.setFieldValue('anggaran', '')
          formik.setFieldValue('lokasi', '')
          formik.setFieldValue('bobot', '')
          formik.setFieldValue('counterpart', '')
          formik.setFieldValue('idPenanggungjawab', '')
          formik.setFieldValue('namaPenanggungjawab', '')
          formik.setFieldValue('statusKegiatan', '')
          formik.setFieldValue('statusTanggal', '')
          formik.setFieldValue('keterangan', '')
          formik.setFieldValue('idUser', idUserMaint)
          formik.setFieldValue('menuScreen', 'A D D')
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-primary'))
          // hidden buttonTU => [T]ambah - [U]bah
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'invisible'))
          // show button konfirmasi (Tambahkan data ... & Batal)
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'bg-primary', ' ', 'visible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnConfirmText', 'Tambahkan data ...')
          // enable semua field
          formik.setFieldValue('fieldDisableKey', false)
          formik.setFieldValue('fieldDisable', false)
        }
        // function clickUbah
        const clickUbah = () => {
          formik.setFieldValue('menuScreen', 'UPDATE')
          formik.setFieldValue('menuClass', menuScreenClassName.concat(' ', 'bg-success'))
          // hidden buttonTU
          formik.setFieldValue('btnTambahClass', btnTambahClassName.concat(' ', 'invisible'))
          formik.setFieldValue('btnUbahClass', btnUbahClassName.concat(' ', 'invisible'))
          // show button konfirmasi (Ubah data ... & Batal)
          formik.setFieldValue('btnConfirmClass', btnConfirmClassName.concat(' ', 'bg-primary', ' ', 'visible'))
          formik.setFieldValue('btnCancelClass', btnCancelClassName.concat(' ', 'visible'))
          formik.setFieldValue('btnConfirmText', 'Ubah data ...')
          // enable semua field
          formik.setFieldValue('fieldDisable', false)
        }
        function renderPrioritas(e) {
          const prioritasNm = e.target.value
          formik.handleChange(e)
          switch (prioritasNm.toUpperCase()) {
            case null:
              formik.setFieldValue('prioritasNama', 'N/A')
              break
            case "T":
              formik.setFieldValue('prioritasNama', 'Tinggi')
              break
            case "S":
              formik.setFieldValue('prioritasNama', 'Sedang')
              break
            case "R":
              formik.setFieldValue('prioritasNama', 'Rendah')
              break
            default:
              formik.setFieldValue('prioritasNama', 'Prioritas salah')
              break
          }
        }
        return (
          <div className="container">
            <Form className="w-100">
              <Row className="fw-bold mb-1">
                <Field name="idUserMaint" type="hidden"/>
                <Field name="idGereja" type="hidden"/>
                <Field name="idBakowi" type="hidden"/>
                <Field name="fieldDisableKey" type="hidden"/>
                <Field name="fieldDisable" type="hidden"/>
                <Field name="menuClass" type="hidden"/>
                <Field name="btnTambahClass" type="hidden"/>
                <Field name="btnUbahClass" type="hidden"/>
                <Field name="btnMenuDisable" type="hidden"/>
                <Field name="btnConfirmClass" type="hidden"/>
                <Field name="btnCancelClass" type="hidden"/>
                <Field name="btnConfirmText" type="hidden"/>
                <Col md={9} sm={6} xs={6}>
                  <p className="mb-1">
                    <span className="text-primary fw-bold"> <i className="bi bi-arrow-right-square-fill"></i> </span> &nbsp; 
                    Nama Kegiatan &nbsp; : &nbsp; <strong>{formik.values.namaKegiatan}</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                  </p>
                </Col>
                <Col md={3} sm={3} xs={3}>
                  <Field name="menuScreen" disabled={true} className={formik.values.menuClass}/>
                </Col>
              </Row>
              <hr className='mb-2'/>
              <Row>
                <Col className="form-group col-2 justify-content-start">
                  <label className="text-primary w-30" htmlFor="idKegiatan"> Id </label>
                </Col>
                <Col className="form-group col-5 justify-content-start">
                  <label className="text-primary w-30" htmlFor="namaKegiatan"> Nama Kegiatan </label>
                </Col>
                <Col className="form-group col-5 justify-content-start">
                  <label className="text-primary w-30" htmlFor="idProgram"> Nama Program </label>
                </Col>
              </Row>
              <Row>
                <Col className="form-group col-2 justify-content-start">
                  <Field 
                    className="text-center w-50" 
                    id="idKegiatan" 
                    name="idKegiatan" 
                    disabled={formik.values.fieldDisableKey} 
                    placeholder="id" 
                  />
                  <ErrorMessage name="idKegiatan" component={TextError} className="invalid-feedback text-warning" />
                </Col>
                <Col className="form-group col-5 justify-content-start">
                  <Field 
                    className="text-start w-100" 
                    id="namaKegiatan" 
                    name="namaKegiatan" 
                    disabled={formik.values.fieldDisable} 
                    placeholder="Nama Kegiatan" 
                  />
                  <ErrorMessage name="namaKegiatan" component={TextError} className="invalid-feedback text-warning" />
                </Col>
                <Col className="form-group col-5 justify-content-start">
                  <CustomSelect
                    options={programList}
                    value={formik.values.idProgram}
                    placeholder="Ketik / Pilih => id / Nama Jenis Program"
                    onChange={value => value ? formik.setFieldValue('idProgram', value.value) : null}
                    disableSelect={formik.values.fieldDisableKey}
                    clearable={false}
                    className="mb-1 w-100"
                  />
                  <ErrorMessage name="idProgram" component={TextError} className="invalid-feedback" />
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col className="form-group col-5 justify-content-start">
                  <label className="text-primary w-15" htmlFor="deskripsi"> Deskripsi </label>
                </Col>
                <Col className="form-group col-5 justify-content-start">
                  <label className="text-primary" htmlFor="idPenanggungjawab"> Penanggungjawab </label>
                </Col>
                <Col className="form-group col-2 justify-content-start">
                  <label className="text-primary w-25" htmlFor="prioritas"> Prioritas </label>
                </Col>
              </Row>
              <Row>
                <Col className="form-group col-5 justify-content-start">
                  <Field 
                    as='textarea' 
                    className="text-start w-100" 
                    id="deskripsi" 
                    name="deskripsi" 
                    disabled={formik.values.fieldDisable} 
                    placeholder="Deskripsi" 
                  />
                  <ErrorMessage name="deskripsi" component={TextError} className="invalid-feedback text-warning" />
                </Col>
                <Col className="form-group col-5 justify-content-start">
                  <CustomSelect
                    options={wargaList}
                    value={formik.values.idPenanggungjawab}
                    placeholder="Ketik / Pilih => Nomor / Nama Warga"
                    onChange={value => value ? formik.setFieldValue('idPenanggungjawab', value.value) : null}
                    disableSelect={formik.values.fieldDisable}
                    clearable={false}
                    className="mb-1 w-100"
                  />
                  <ErrorMessage name="idPenanggungjawab" component={TextError} className="invalid-feedback" />
                </Col>
                <Col className="form-group col-2 justify-content-start">
                  <Field 
                    className="text-center w-20" id="prioritas" name="prioritas" 
                    onChange={renderPrioritas}
                    disabled={formik.values.fieldDisable} 
                  />
                  <Field className={
                    "text-center w-70 ms-1".concat(
                      ['T','S','R'].includes(formik.values.prioritas) 
                        ? ' text-success border-success' : ' text-warning border-danger'
                    )
                  } 
                    id="prioritasNama" name="prioritasNama" disabled={true} 
                  />
                  <ErrorMessage name="prioritas" component={TextError} className="invalid-feedback text-warning" />
                </Col>
              </Row>
              <hr className='mb-1'/>
              <Row>
                <Col className="form-group col-3">
                  <label className="text-primary w-75" htmlFor="tanggalMulai"> Tanggal Mulai </label>
                </Col>
                <Col className="form-group col-3">
                  <label className="text-primary w-75" htmlFor="tanggalSelesai"> Tanggal Selesai </label>
                </Col>
                <Col className="form-group col-3">
                  <label className="text-primary w-75" htmlFor="anggaran"> Anggaran </label>
                </Col>
                <Col className="form-group col-3">
                  <label className="text-primary w-75" htmlFor="bobot"> Bobot </label>
                </Col>
              </Row>
              <Row>
                <Col className="form-group col-3">
                  <DatePickerField 
                    className="w-100" 
                    name="tanggalMulai" 
                    disabled={formik.values.fieldDisable}
                  />
                </Col>
                <Col className="form-group col-3">
                  <DatePickerField 
                    className="w-100" 
                    name="tanggalSelesai" 
                    disabled={formik.values.fieldDisable}
                  />
                </Col>
                <Col className="form-group col-3">
                  <Field 
                    className="text-end w-100" 
                    id="anggaran" 
                    name="anggaran" 
                    component={renderNilai} 
                    disabled={formik.values.fieldDisable} 
                  />
                </Col>
                <Col className="form-group col-3">
                  <Field 
                    className="text-end w-100" 
                    id="bobot" 
                    name="bobot" 
                    component={renderInt} 
                    disabled={formik.values.fieldDisable} 
                  />
                </Col>
              </Row>
              <hr className='mb-1'/>
              <Row>
                <Col className="form-group col-3">
                  <label className="text-primary w-50" htmlFor="lokasi"> Lokasi Kegiatan </label>
                </Col>
                <Col className="form-group col-3">
                  <label className="text-primary w-50" htmlFor="counterpart"> Counterpart </label>
                </Col>
                <Col className="form-group col-3">
                  <label className="text-primary w-50" htmlFor="statusKegiatan"> Status Kegiatan </label>
                </Col>
                <Col className="form-group col-3">
                  <label className="text-primary w-50" htmlFor="statusTanggal"> Tanggal Status </label>
                </Col>
              </Row>
              <Row>
                <Col className="form-group col-3">
                  <Field 
                    className="text-start w-100" 
                    id="lokasi" 
                    name="lokasi" 
                    disabled={formik.values.fieldDisable} 
                  />
                </Col>
                <Col className="form-group col-3">
                  <Field 
                    className="text-start w-100" 
                    id="counterpart" 
                    name="counterpart" 
                    disabled={formik.values.fieldDisable} 
                  />
                </Col>
                <Col className="form-group col-3">
                  <Field 
                    className="text-start w-100" 
                    id="statusKegiatan" 
                    name="statusKegiatan" 
                    disabled={formik.values.fieldDisable} 
                  />
                </Col>
                <Col className="form-group col-3">
                  <DatePickerField 
                    className="w-100" 
                    name="statusTanggal" 
                    disabled={formik.values.fieldDisable}
                  />
                </Col>
              </Row>
              <Row className='mt-2'>
                <Col className="form-group col-12">
                  <label className="text-primary w-10" htmlFor="keterangan"> Keterangan </label>
                  <Field 
                    className="text-start w-100" 
                    id="keterangan" 
                    name="keterangan" 
                    disabled={formik.values.fieldDisable} 
                  />
                </Col>
              </Row>
              <hr />
              <Row className="fw-bold small mb-0">
                <p>
                  <span className="text-warning"> <i className="bi bi-x-circle-fill"></i> </span> &nbsp; 
                  Maintenance : {formik.values.userActivity} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                  <span className="text-warning fw-bold"> <i className="bi bi-arrow-right-circle"></i> </span> &nbsp; 
                  Id User Maint : {formik.values.idUser} - {formik.values.namaUser} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="text-warning fw-bold"> <i className="bi bi-arrow-right-circle"></i> </span> &nbsp; 
                  Tanggal Maintenance : {
                    formik.values.tanggalSystem === '*' 
                      ? formik.values.tanggalSystem 
                      : moment(formik.values.tanggalSystem).format('LLLL')
                  } &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </p>
              </Row>
              <Row className="fw-bold small mt-0">
                <Col className="justify-content-start">
                  <button 
                    type="button" 
                    className={formik.values.btnConfirmClass}
                    disabled={
                      (
                        parseInt(formik.values.idKegiatan, 10) > 0 && 
                        parseInt(formik.values.idKegiatan, 10) < 256 &&
                        formik.values.idKegiatan &&
                        formik.values.namaKegiatan && 
                        formik.values.idProgram && 
                        ([null,'','T','S','R'].includes(formik.values.prioritas)) && 
                        formik.values.anggaran && formik.values.bobot 
                      ) 
                        ? false : true
                    }
                    onClick={() => onClickConfirm(formik.values)}
                  > {formik.values.btnConfirmText} </button>
                  &nbsp;&nbsp;&nbsp;
                  <button 
                    type="button" 
                    className={formik.values.btnCancelClass} 
                    onClick={() => formik.values.menuScreen === "UPDATE" ? screenIdle() : onClickHandleEdit(false)}
                  > Batal </button>
                </Col>
                <Col className="text-end">
                  <button 
                    type="button" 
                    className={formik.values.btnTambahClass} 
                    disabled={formik.values.btnMenuDisable}
                    onClick={() => clickTambah()}
                  > Tambah </button>
                  &nbsp;&nbsp;&nbsp;
                  <button 
                    type="button" 
                    className={formik.values.btnUbahClass} 
                    disabled={formik.values.btnMenuDisable} 
                    onClick={() => clickUbah()}
                  > Ubah </button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <button 
                    type="button"
                    className='btn btn-outline-secondary btn-sm fst-italic fw-bold'
                    onClick={() => clickExit()}
                  > Keluar </button>
                </Col>                                
              </Row>
              <hr/>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

export default EditKegiatan