import React from 'react'

import useAuth from '../../hooks/useAuth'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import './home.scss'
import Widget from '../../components/widget/Widget'
import Featured from '../../components/featured/Featured'
import Chart from '../../components/chart/Chart'
import Table from '../../components/table/Table'

import NumberFormat from 'react-number-format'
import moment from 'moment'

import { useWidgetsFeatured, useChart, useTable } from '../../components/data/UseDataComponents'

// import AcerNitro5 from "../../images/Acer Nitro 5.png"

require('moment/locale/id')

// varibel widgets
let nilaiProgram = ""
let diffProgram = ""
let nilaiKegiatan = ""
let diffKegiatan = ""
let nilaiAnggaran = ""
let diffAnggaran = ""
let nilaiDukungan = ""
let diffDukungan = ""
// variabel featured
let pctProgram = 0
let amtProgram = 0
let pctKegiatan = 0
let amtKegiatan = 0
let pctAnggaran = 0
let amtAnggaran = 0
let pctDukungan = 0
let amtDukungan = 0

const Home = () => {
  const { auth } = useAuth()

  const idGereja = auth.authIdGereja
  const idBakowi = auth.authIdBakowi
  // const idUser = auth.authIdUser // nanti, untuk idUser dgn idBakowi = 0 , akan menampilkan seluruh badan dan komisi
  // const tahunMulai = auth.authTahunMulai // nanti, untuk bisa melihat tahun2 sebelumnya, tahunMulai utk batasan pilihan tahun
  const tahun = auth.authTahunAkhir 
  
  const { data: dataWidgetsFeatured } = useWidgetsFeatured(idGereja, idBakowi, tahun)
  const { data: dataChart } = useChart(idGereja, idBakowi, tahun)
  const { data: dataTable } = useTable(idGereja, idBakowi)

  if (dataWidgetsFeatured) {
    // variabel widgets
    nilaiProgram = dataWidgetsFeatured[0].jumlahProgram
    diffProgram = dataWidgetsFeatured[0].diffProgram
    nilaiKegiatan = dataWidgetsFeatured[0].jumlahKegiatan
    diffKegiatan = dataWidgetsFeatured[0].diffKegiatan
    nilaiAnggaran = dataWidgetsFeatured[0].jumlahAnggaran
    diffAnggaran = dataWidgetsFeatured[0].diffAnggaran
    nilaiDukungan = dataWidgetsFeatured[0].jumlahDukungan
    diffDukungan = dataWidgetsFeatured[0].diffDukungan
    // variabel featured
    pctProgram = dataWidgetsFeatured[0].pctProgramSelesai
    amtProgram = dataWidgetsFeatured[0].jumlahProgramSelesai
    pctKegiatan = dataWidgetsFeatured[0].pctKegiatanSelesai
    amtKegiatan = dataWidgetsFeatured[0].jumlahKegiatanSelesai
    pctAnggaran = dataWidgetsFeatured[0].pctAnggaran
    amtAnggaran = dataWidgetsFeatured[0].jumlahTransaksiAnggaran
    pctDukungan = dataWidgetsFeatured[0].pctDukungan
    amtDukungan = dataWidgetsFeatured[0].jumlahTransaksiDukungan
  }
  // variabel chart
  let title = "Penerimaan vs Pengeluaran"
  const data = dataChart
  // variabel table
  const trnList = dataTable
  const trnTableHead = [
    // posisi judul [L]eft/[C]enter/[R]ight - nama judul
    'L-ID Trn',
    'L-Keterangan',
    'L-Kelompok',
    'L-Akun',
    'L-Program',
    'L-Kegiatan',
    'L-Tanggal',
    'R-Nilai'
  ]
  const renderHead = (item, index) => (
    <th 
      key={index} 
      className={item.substring(0,1) === 'C' ? 'text-center' : (item.substring(0,1) === 'R' ? 'text-end' : 'text-start')}
    >
      {item.substring(2)}
    </th>
  )
  const renderBody = (item, index) => (
    <tr key={index}>
      <td className="text-center">{item.idTransaksi}</td>
      <td>{item.keterangan}</td>
      <td>{item.namaKelompokTransaksi}</td>
      <td>{item.namaAkunKasBank}</td>
      <td>{item.namaProgram}</td>
      <td>{item.namaKegiatan}</td>
      <td>{moment(item.tanggalTransaksi).format('LL')}</td>
      <td className="text-end">
        <NumberFormat
          displayType={"text"}
          thousandSeparator={"."}
          decimalSeparator={","}
          decimalScale={0}
          fixedDecimalScale={true}
          value={item.nilaiTransaksi}
        />
      </td>
      {/* <td>
        <span className={`status ${item.status}`}>{item.status}</span>
      </td> */}
    </tr>
  )
  
  return (
    <div className="home" style={{ backgroundImage:"none"}}>
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widget type="program" nilai={nilaiProgram} diff={diffProgram} />
          <Widget type="kegiatan" nilai={nilaiKegiatan} diff={diffKegiatan} />
          <Widget type="anggaran" nilai={nilaiAnggaran} diff={diffAnggaran} />
          <Widget type="dukungan" nilai={nilaiDukungan} diff={diffDukungan} />
        </div>
        <div className="charts">
          <Featured 
            pctProgram = {pctProgram}
            amtProgram = {amtProgram}
            pctKegiatan = {pctKegiatan}
            amtKegiatan = {amtKegiatan}
            pctAnggaran = {pctAnggaran}
            amtAnggaran = {amtAnggaran}
            pctDukungan = {pctDukungan}
            amtDukungan = {amtDukungan}
          />
          <Chart 
            title={title}
            aspect={2 / 1} 
            data={data}
          />
        </div>
        <div className="listContainer fw-bold">
          <div className="listTitle">Transaksi Terakhir</div>
          <Table 
            headData={trnTableHead}
            renderHead={renderHead}
            bodyData={trnList}
            renderBody={renderBody}
          />
        </div>
      </div>
    </div>
  )
}

export default Home
