import React, { useMemo } from 'react'
import { useTable, useBlockLayout, useSortBy, useGlobalFilter, useFilters, usePagination } from 'react-table'
import { TLCOLUMNS } from './TransaksiListTableColumn'
import './transaksilisttable.css'
import { TransaksiListTableGlobalFilter } from './TransaksiListTableGlobalFilter'

const TransaksiListTable = ({onClickHandleEdit, transaksiList}) => {
  const columns = useMemo(() => TLCOLUMNS, [])
  const defaultColumn = useMemo(
    () => ({
      minWidth: 20,
      width: 150,
      maxWidth: 500
    }),
    []
  )
  const data = useMemo(() => transaksiList, [transaksiList])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setGlobalFilter
  } = useTable({
    columns,
    data,
    defaultColumn
  }, 
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { globalFilter, pageIndex, pageSize } = state

  return (
    <div>
      <div className="d-flex justify-content-between w-100">
        <TransaksiListTableGlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map( column => (
                <th 
                  width={column.width} 
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <div>
                    {column.canFilter ? column.render('Filter') : null}
                  </div>
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? '🔽' : '🔼') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>  
        <tbody {...getTableBodyProps()}>
          {
            page.map(row => {
              prepareRow(row)
              return (
                <tr 
                  onClick={() => onClickHandleEdit(row.original, true)} 
                  {...row.getRowProps()}
                >
                  {
                    row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    })
                  }
                </tr>
              )
            })
          }
        </tbody>
      </table>
      <div className="pageControl mt-1">
        <span className="mt-2">
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span className="me-3">
          <div className="input-group input-group-sm flex-nowrap ms-2">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-sm">
                Go to page: {' '}
              </span>
            </div>
            <input 
              className="text-center form-control me-2 bg-white" 
              aria-describedby="inputGroup-sizing-sm" 
              type='number'
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(pageNumber)
              }}
              style= {{ width: '50px'}} 
            />
          </div>
        </span>
        <button 
          className="btn btn-primary btn-sm" 
          onClick={() => gotoPage(0)} 
          disabled={!canPreviousPage}
        >
          {'<<'}
        </button>
        <button 
          className="btn btn-outline-primary btn-sm bg-white" 
          onClick={() => previousPage()} 
          disabled={!canPreviousPage}
        >
          Previous
        </button>
        <button 
          className="btn btn-outline-primary btn-sm bg-white" 
          onClick={() => nextPage()} 
          disabled={!canNextPage}
        >
          Next
        </button>
        <button 
          className="btn btn-primary btn-sm me-2" 
          onClick={() => gotoPage(pageCount - 1)} 
          disabled={!canNextPage}
        >
          {'>>'}
        </button>
        <select
          className="bg-primary text-white"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  )  
}  

export default TransaksiListTable