import React from "react"
import { Row, Col } from 'react-bootstrap'
import "./chart.scss"
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  YAxis,
  // Legend,
} from "recharts"

const Chart = ({ aspect, title, data }) => {
  return (
    <div className="chart">
      <Row>
        <Col>
          <div className="title fw-bold fst-italic">
            <i className="bi bi-cash-coin text-success"></i>
            &nbsp;
            &nbsp;
            <i className="bi bi-box-arrow-in-down-right text-success"></i>
            &nbsp;
            &nbsp;
            {title}
            &nbsp;
            &nbsp;
            <i className="bi bi-box-arrow-up-right text-warning"></i>
            &nbsp;
            &nbsp;
            <i className="bi bi-cash-coin text-warning"></i>
          </div>
        </Col>
        <Col>
          <p className="text-end fw-lighter fst-italic">(Rp. Juta)</p>
        </Col>
      </Row>
      <ResponsiveContainer width="100%" aspect={aspect}>
        <AreaChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="Penerimaan" x1="0" y1="0" x2="0" y2="1">
              {/* <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} /> */}
              <stop offset="5%" stopColor="#46b505" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#46b505" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="Pengeluaran" x1="0" y1="0" x2="0" y2="1">
              {/* <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} /> */}
              <stop offset="5%" stopColor="#f5bf42" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#f5bf42" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="Bulan" stroke="gray" />
          {/* <YAxis dataKey="Pengeluaran" /> */}
          <YAxis type="number" domain={[0, 'auto']} />
          {/* <YAxis type="number" domain={[0, 3000]} tickCount={7} /> */}
          {/* <Legend width={100} wrapperStyle={{ top: 40, right: 20, backgroundColor: '#f5f5f5', border: '1px solid #d5d5d5', borderRadius: 3, lineHeight: '40px' }} /> */}
          {/* <Legend width={200} wrapperStyle={{ top: -50, right: 10, backgroundColor: '#f5f5f5', border: '1px solid #d5d5d5', borderRadius: 3, lineHeight: '30px' }} /> */}
          <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="Penerimaan"
            stroke="#82ca9d"
            fillOpacity={1}
            fill="url(#Penerimaan)"
          />
          <Area
            type="monotone"
            dataKey="Pengeluaran"
            // stroke="#8884d8"
            stroke="#f5bf42"
            fillOpacity={1}
            fill="url(#Pengeluaran)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart
