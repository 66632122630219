import React, { useState } from 'react'
import useAuth from '../../hooks/useAuth'

import "./laporankeuangan.scss"
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Row, Col, Container, Table } from 'react-bootstrap'

import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import { useKetuaBadanKomisiList, usePenanggungjawabList, useBendaharaList } 
  from '../../components/data/UseDataComponents'
import { useLaporanKeuanganList } from '../../components/data/UseDataLaporan'

import moment from 'moment'
import NumberFormat from 'react-number-format'
import CustomSelect from '../../components/form/CustomSelect'
import TextError from '../../components/form/TextError'
// import CetakLaporanKeuangan from './CetakLaporanKeuangan'
import Logo from '../../images/Logo'

const imgLogo = Logo()

const namaBulan = [
  'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 
  'Juli', 'Agustus', 'September', 'Oktober', 'Nopember', 'Desember'
]
let thn = 0
let tglPeriode = ""
let defaultKetua = ""
let defaultPenanggungjawab = ""
let defaultBendahara = ""

const colStyle = {
  backgroundColor: '#004b48',
  color: "#00ffff",
  fontSize: "12px",
}

const LaporanKeuangan = () => {
  const { auth } = useAuth()
  const idGereja = auth.authIdGereja
  // const namaGereja = auth.authNamaGereja
  const namaKotaKabupaten = auth.authNamaKotaKabupaten
  const idBakowi = auth.authIdBakowi
  // const namaBadanKomisi = auth.authNamaBakowi
  const tahunMulai = auth.authTahunMulai
  // const idUser = auth.authIdUser

  const [thnTrn, setThnTrn] = useState((new Date()).getFullYear())
  const [blnTrn, setBlnTrn] = useState(new Date().getMonth()+1)
  const [ketua, setKetua] = useState('')
  const [penanggungjawab, setPenanggungjawab] = useState('')
  const [bendahara, setBendahara] = useState('')
  const [keyFromButton, setKeyFromButton] = 
    useState({tahun: thnTrn, bulan: blnTrn, penanggungjawab: ketua, keuangan: bendahara})
  
  tglPeriode = thnTrn > 0 ? moment([thnTrn,blnTrn-1,1]).endOf("month").format('DD-MMMM-YYYY') : ""

  window.scrollTo(0, 0)

  const onProses = () => {
    setKeyFromButton({ 
      ...keyFromButton, 
      tahun: thnTrn, 
      bulan: blnTrn, 
      ketua: ketua, 
      penanggungjawab: penanggungjawab, 
      keuangan: bendahara 
    })
  }

  const { data: laporanKeuanganList, isLoading, isSuccess, isError } = 
    useLaporanKeuanganList(idGereja, idBakowi, thnTrn, blnTrn)
  const { data: ketuaBadanKomisiList} = useKetuaBadanKomisiList(idGereja, idBakowi)
  const { data: penanggungjawabList} = usePenanggungjawabList(idGereja, idBakowi)
  const { data: bendaharaList} = useBendaharaList(idGereja)

  defaultKetua = ketuaBadanKomisiList ? ketuaBadanKomisiList[0].value : null
  defaultPenanggungjawab = penanggungjawabList ? penanggungjawabList[0].value : null
  defaultBendahara = bendaharaList ? bendaharaList[0].value : null

  const onMinus = () => {
    thn = thnTrn.valueOf()
    if (thn > tahunMulai) setThnTrn(thn - 1)
  }
  const onPlus = () => {
    thn = thnTrn.valueOf()
    if (thn < (new Date()).getFullYear()) setThnTrn(thn - 1 + 2)
  }

  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <h3 className="ms-4 mt-3"> Laporan Keuangan </h3>
        <Container className="w-100">
          <Formik
            initialValues={{ 
              idGereja: idGereja, 
              tahun: thnTrn, 
              bulan: blnTrn, 
              ketua: defaultKetua, 
              penanggungjawab: defaultPenanggungjawab, 
              keuangan: defaultBendahara 
            }}
            // validationSchema={TahunBulanValidationSchema}
            enableReinitialize
          >
            {formik => {
              function renderKetua(e) {
                formik.setFieldValue('ketua', e.value)
                setKetua(e.value)
              }
              function renderPenanggungjawab(e) {
                formik.setFieldValue('penanggungjawab', e.value)
                setPenanggungjawab(e.value)
              }
              function renderBendahara(e) {
                formik.setFieldValue('keuangan', e.value)
                setBendahara(e.value)
              }
              return (
                <Container className="w-100">
                  <Form>
                    <Row className="text-center">
                      <h5 className="fw-bold"> Laporan Keuangan </h5>
                      <hr/>
                    </Row>
                    <Row className="text-center d-block h-auto">
                      <button 
                        type="button" 
                        className="btn btn-outline-secondary text-dark bg-white d-inline mb-1"
                        style={{'width': '75px'}}
                      > 
                        Tahun 
                      </button>
                      <div className="input-group d-inline">
                        {/* <!-- aria-label="..." on the control -->
                        <button ... aria-label="Mute">
                          <svg class="bi bi-volume-mute-fill" aria-hidden="true" ...>
                          ...
                          </svg>
                        </button>
                        <i class="bi-alarm" style="font-size: 2rem; color: cornflowerblue;"></i>
                        <img src="/assets/img/bootstrap.svg" alt="Bootstrap" width="32" height="32"></img> */}
                        <button type="button" 
                          className="btn bi bi-arrow-down-circle mb-1 bg-white" 
                          style={{'width': '40px', 'height': '37px'}}
                          onClick={() => onMinus()}
                        >
                        </button>
                        <Field id="tahun" name="tahun" type="text" 
                          onChange={e => setThnTrn(e.target.value)} 
                          className="text-center d-inline mt-2 border-2 border-primary mx-1" 
                          style={{'width': '70px', 'height': '37px'}}
                          disabled={true}
                        />
                        <ErrorMessage name="tahun" component="div" className="invalid-feedback" />
                        <button type="button" 
                          className="btn bi bi-arrow-up-circle mb-1 bg-white" 
                          style={{'width': '40px', 'height': '37px'}}
                          onClick={() => onPlus()}
                        >
                        </button>
                      </div>
                        <button 
                          type="button" 
                          className="btn btn-outline-secondary text-dark bg-white ms-3 mb-1"
                          style={{'width': '75px'}}
                        > 
                          Bulan 
                        </button>
                        <Field as='select' name="bulan" value={blnTrn} 
                          onChange={e => setBlnTrn(e.target.value)} 
                          className="d-inline ms-2 border-2 border-primary" 
                          style={{'width': '130px', 'height': '37px'}}
                        >
                          {namaBulan.map((namaBulan, index) => {
                            return (
                              <option key={index} value={index+1}>
                                {namaBulan}
                              </option>
                            )
                          })}
                        </Field>
                        <ErrorMessage name="bulan" component="div" className="invalid-feedback" />
                        <button 
                          type="button" 
                          onClick={() => onProses()} 
                          className="btn btn-primary d-inline ms-5 mb-1"
                          style={{'width': '75px', 'height': '37px'}}
                        > 
                          Proses 
                        </button>
                    </Row>
                    <Row className="text-center mt-2 w-100">
                      <Col className="form-group col-4 justify-content-start">
                        <CustomSelect
                          options={ketuaBadanKomisiList}
                          value={formik.values.ketua}
                          placeholder="Ketik / Pilih => Nama Ketua"
                          onChange={value => value ? renderKetua(value) : null}
                          disableSelect={formik.values.fieldDisable}
                          noOptionsMessage={() => 'tidak ada data ...'}
                          clearable={false}
                          className="mb-1 w-100"
                        />
                        <ErrorMessage name="ketua" component={TextError} className="invalid-feedback" />
                      </Col>
                      <Col className="form-group col-4 justify-content-start">
                        <CustomSelect
                          options={penanggungjawabList}
                          value={formik.values.penanggungjawab}
                          placeholder="Ketik / Pilih => Nama Penanggungjawab"
                          onChange={value => value ? renderPenanggungjawab(value) : null}
                          disableSelect={formik.values.fieldDisable}
                          noOptionsMessage={() => 'tidak ada data ...'}
                          clearable={false}
                          className="mb-1 w-100"
                        />
                        <ErrorMessage name="penanggungjawab" component={TextError} className="invalid-feedback" />
                      </Col>
                      <Col className="form-group col-4 justify-content-end">
                        <CustomSelect
                          options={bendaharaList}
                          value={formik.values.keuangan}
                          placeholder="Ketik / Pilih => Nama Bendahara"
                          onChange={value => value ? renderBendahara(value) : null}
                          disableSelect={formik.values.fieldDisable}
                          noOptionsMessage={() => 'tidak ada data ...'}
                          clearable={false}
                          className="mb-1 w-100"
                        />
                        <ErrorMessage name="keuangan" component={TextError} className="invalid-feedback" />
                      </Col>
                    </Row>
                  </Form>
                  <hr/>
                  {laporanKeuanganList ?
                    (laporanKeuanganList.length === 8 
                      ? "Belum ada data" 
                      :
                        <Row> 
                          <Col>
                            <Row className="justify-content-center mt-0 pt-0"> 
                              <Col className="text-start col-2"> 
                              </Col> 
                              <Col className="text-start col-8"> 
                                <p className="text-center fw-bold my-0"> LAPORAN KEUANGAN TAHUN {thnTrn} </p> 
                                <p className='text-center my-0'> Periode: {tglPeriode} </p>
                              </Col> 
                              <Col className="text-end col-2 mb-1"> 
                                <button type="button" 
                                  className = "btn btn-labeled btn-outline-success fw-bold btn-sm" 
                                  // onClick={() => CetakNeraca(
                                  //   namaGereja, 
                                  //   tglPeriode, 
                                  //   neraca.filter(neraca => neraca.Level <= lvlTrn)
                                  // )}
                                > 
                                  <span className="btn-label me-2">
                                    <i className="bi bi-printer-fill"></i>
                                  </span> 
                                  Cetak
                                </button> 
                              </Col> 
                            </Row> 
                            { isLoading &&
                              <>
                                <div className="w-100 d-flex mt-3 justify-content-center">
                                  <Row className="w-20 justify-content-center">
                                    <img 
                                      src={imgLogo} 
                                      width="15%" 
                                      className="rounded-circle img-thumbnail border-danger" 
                                      alt="GKJ"
                                    ></img>
                                  </Row>
                                </div>
                                <div className="w-100 mt-3 justify-content-center">
                                  <Row className="text-center">
                                    <strong> Loading ... </strong>
                                  </Row>
                                  <Row className="mb-3 justify-content-center">
                                    <div 
                                      className="spinner-grow mt-2 text-primary" 
                                      role="status" 
                                      aria-hidden="true"
                                    ></div>
                                  </Row>
                                </div>
                                <hr/>
                              </>
                            }
                            { isError && (<div> Data laporan keuangan tidak dapat diakses </div>)}
                            { isSuccess &&
                              <Container className='w-100 ms-5'>
                                <Table striped bordered hover className="w-100 ms-5" responsive="lg">
                                  <thead className='w-100' style={colStyle}> 
                                    <tr className='w-100'>
                                      <th className='text-center w-55'> Uraian </th>
                                      <th className='text-center w-20'> Jumlah </th>
                                      <th colSpan="2" className='text-center w-25'> Keterangan </th>
                                    </tr>
                                  </thead> 
                                  <tbody className="fw-bold dark-background" style={{fontSize: "12px"}}>
                                    {laporanKeuanganList && 
                                      laporanKeuanganList.map((laporan, index) => (
                                        <tr key={index}>
                                          <td className="text-start bg-white"> 
                                            {
                                              laporan.uraian.match('bariskosong') 
                                              ? <span> &nbsp; </span> 
                                              : (
                                                  laporan.uraian.match('Jumlah Penerimaan') != null || 
                                                  laporan.uraian.match('Jumlah Pengeluaran') != null 
                                                    ? <span> 
                                                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
                                                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
                                                    </span> 
                                                    : (
                                                      (laporan.uraian.match('PENERIMAAN') != null || 
                                                        laporan.uraian.match('PENGELUARAN') != null) && 
                                                        laporan.jumlah === '' 
                                                          ? <span> &nbsp; &nbsp; </span> 
                                                          : (laporan.uraian.match('SALDO AWAL') != null || 
                                                            laporan.uraian.match('SALDO AKHIR') != null 
                                                              ? '' 
                                                              : <span> 
                                                                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 
                                                              </span> 
                                                      )
                                                    )
                                                )
                                            } {laporan.uraian.match('bariskosong') ? '' : laporan.uraian}
                                          </td>
                                          <td className="text-end bg-white"> 
                                            <NumberFormat 
                                              displayType={'text'} 
                                              thousandSeparator={'.'} 
                                              decimalSeparator={','} 
                                              decimalScale={2} 
                                              fixedDecimalScale={true} 
                                              allowNegative={true} 
                                              value={laporan.jumlah} 
                                            /> 
                                          </td> 
                                          <td className="text-start bg-white border-end-0"> 
                                            { 
                                              laporan.keterangan1.match('Jumlah') != null 
                                                ? <span> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>
                                                : <span> &nbsp; &nbsp; </span>
                                            } 
                                            {laporan.keterangan1} 
                                          </td> 
                                          <td className="text-end bg-white border-start-0"> 
                                            <NumberFormat 
                                              displayType={'text'} 
                                              thousandSeparator={'.'} 
                                              decimalSeparator={','}
                                              decimalScale={2} 
                                              fixedDecimalScale={true} 
                                              allowNegative={true} 
                                              value={laporan.keterangan2} 
                                            /> 
                                          </td> 
                                        </tr> 
                                      ))
                                    }
                                  </tbody>
                                </Table>
                                <Row className='ms-1 w-100' style={{fontSize: '12px'}}>
                                  <Col className='col-3'>
                                    <Row className='ms-4'>
                                      <span> &nbsp; </span>
                                      Ketua Komisi,
                                      <span> &nbsp; </span>
                                      <span> &nbsp; </span>
                                      <div className="text-start ps-0"> {'( ' + formik.values.ketua + ' )'} </div>
                                    </Row>
                                  </Col>
                                  <Col className='col-5'>
                                    <Row>
                                      <span> &nbsp; </span>
                                      <span className='text-center'> Majelis Penanggungjawab, </span>
                                      <span> &nbsp; </span>
                                      <span> &nbsp; </span>
                                      <div className="text-center"> {'( ' + formik.values.penanggungjawab + ' )'} </div>
                                    </Row>
                                  </Col>
                                  <Col className='col-4'>
                                    <Row className='ms-4'>
                                      <div className='ps-0'>{namaKotaKabupaten}, {moment().format('LL')}</div>
                                      Bendahara Majelis,
                                      <span> &nbsp; </span>
                                      <span> &nbsp; </span>
                                      <div className="text-start ps-0"> {'( ' + formik.values.keuangan + ' )'} </div>
                                    </Row>
                                  </Col>
                                </Row>
                              </Container>
                            }
                          </Col>
                          <Row className="justify-content-start tglfootnote footnote mt-0">
                              <div className="fst-italic text-start text-muted small"> gkj-ag-wr-2022 </div>
                          </Row>                                
                        </Row>
                    )
                  :
                    <Row>
                        <div className="w-100 mt-5 text-center">
                            <img 
                              src={imgLogo} 
                              width="15%" 
                              className="rounded-circle img-thumbnail border-danger" 
                              alt="GKJ"
                            ></img>
                        </div>
                    </Row>
                  }
                </Container>
              )
            }} 
          </Formik> 
        </Container>
      </div>
    </div>
  )
}

export default LaporanKeuangan