import React, { useEffect, useState } from "react"
import useAuth from '../../hooks/useAuth'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

import "./maintuserakses.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Table from "../../components/table/Table"
import EditUserAkses from "./EditUserAkses"

import { Row, Container } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'

import moment from 'moment'
import Logo from '../../images/Logo'

const imgLogo = Logo()
require('moment/locale/id')
toast.configure()

// =================
const idAplikasi = 'AG'
// -----------------

const MaintUserAkses = () => {
  
  window.scrollTo(0, 0)
  const { auth } = useAuth()
  
  // const apiUrlUser = "/api/user"
  const apiUrlUser = auth.API_URL + "/user"

  const idGereja = auth.authIdGereja
  const idBakowi = auth.authIdBakowi
  const idUser = auth.authIdUser
  const [nomorIdUser, setNomorIdUser] = useState([])
  const [editDetail, setEditDetail] = useState(false)
  const [adaData, setAdaData] = useState(false)

  const axiosPrivate = useAxiosPrivate()

  const fetchUserAkses = (nomorIdGereja, idAplikasi) => {
    return axiosPrivate.get(`${apiUrlUser}/userakses/${nomorIdGereja}/${idAplikasi}`)
  }

  const useUserAksesList = (nomorIdGereja, idAplikasi, onSuccess, onError) => {
    return useQuery(
      ['userakses', nomorIdGereja, idAplikasi],
      () => fetchUserAkses(nomorIdGereja, idAplikasi), 
      {
        onSuccess,
        onError: () => {
          toast.error('Tidak dapat meng-akses data !', {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000
          })
        },
        select: (data) => {
          const userAksesList = data.data[0]
          return userAksesList
        }
      }
    )
  }

  const fetchUsrList = (nomorIdGereja) => {
    return axiosPrivate.get(`${apiUrlUser}/usrlist/${nomorIdGereja}`)
  }

  const useUsrList = (nomorIdGereja, onSuccess, onError) => {
    return useQuery(
      ['usrlist', nomorIdGereja], () => fetchUsrList(nomorIdGereja), 
      {
        onSuccess,
        onError,
        select: (data) => {
          const usrList = data.data[0]
          return usrList
        }
      }
    )
  }


  const { data: userAksesList, isLoading, isSuccess, isError } = useUserAksesList(idGereja, idAplikasi)
  const { data: usrList } = useUsrList(idGereja)

  useEffect(() => {
    setAdaData
      ( userAksesList && userAksesList[0]?.idGereja === 'nf' 
        ? false : true
      )
  }, [userAksesList])

  const userTableHead = [
    // posisi judul [L]eft/[C]enter/[R]ight - nama judul
    'C-ID',
    'L-Nama',
    'L-Status User',
    'L-Aplikasi',
    'C-Level Akses',
    'L-Status Akses',
    'L-User Authorize',
    'L-User Aktivitas',
    'L-Tanggal Aktivitas'
  ]
  const renderHead = (item, index) => (
    <th key={index} className={item.substring(0,1) === 'C' ? 'text-center' : (item.substring(0,1) === 'R' ? 'text-end' : 'text-start')}>
      {item.substring(2)}
    </th>
  )

  const renderBody = (item, index) => (
    <tr 
      onClick={() => onClickHandleEdit(
        [item],
        true
      )} 
      key={index}>
      <td className="text-center">{item.idUser}</td>
      <td>{item.namaUser}</td>
      <td>
        <span className={`status ${item.statusUser}`}>{item.statusUser}</span>
      </td>
      <td>AG - Aktivitas Gereja</td>
      <td className="text-center">{item.levelAkses}</td>
      <td>
        <span className={`status ${item.statusAkses}`}>{item.statusAkses}</span>
      </td>
      <td>{item.namaUserAuthorize}</td>
      <td>{item.aktivitas}</td>
      <td>{moment(item.tanggalAktivitas).format('LL')}</td>
    </tr>
  )

  const onClickEntry = () => {
    onClickHandleEdit('', true)
  }

  const onClickHandleEdit = (noIdUser, editDisplay) => {
    setNomorIdUser(noIdUser)
    setEditDetail(editDisplay)
  }

  return (
    <div className="list">
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <h3 className="ms-4 my-3"> Create - Update User Akses </h3>
        {!editDetail && 
          <button 
            type="button" 
            className="ms-4 mb-2 btn btn-sm btn-outline-primary fst-italic fw-bold" 
            onClick={() => onClickEntry()}
          > 
            <span className="bi bi-plus-circle-dotted mx-3" style={{fontSize: "21px"}}> Mulai Entry </span>
          </button>
        }
        <Container className="w-100">
          { isLoading && !editDetail && (
            <>
              <div className="w-100 d-flex mt-3 justify-content-center">
                  <Row className="w-25 justify-content-center">
                      <img src={imgLogo} width="15%" className="rounded-circle img-thumbnail border-danger" alt="GKJ"></img>
                  </Row>
              </div>
              <div className="w-100 mt-3 justify-content-center">
                  <Row className="text-center">
                      <strong> Loading ... </strong>
                  </Row>
                  <Row className="mb-3 justify-content-center">
                      <div className="spinner-grow mt-2 text-primary" role="status" aria-hidden="true"></div>
                  </Row>
              </div>
            </>
          )}
          { isError && (<div> Tabel data user tidak dapat diakses </div>)}
          { isSuccess && (
            <div>
              <Row>
                {editDetail &&
                  <>
                    <hr />
                    <EditUserAkses
                      onClickHandleEdit = {onClickHandleEdit}
                      idGereja = {idGereja}
                      idBakowi = {idBakowi}
                      idAplikasi = {idAplikasi}
                      idUserMaint = {idUser}
                      nomorIdUser = {nomorIdUser[0]}
                      usrList = {usrList}
                    >
                    </EditUserAkses>
                  </>
                }
              </Row>
              <Row> 
                { adaData 
                  ? 
                    <Table 
                      onClickHandleEdit = {onClickHandleEdit}
                      headData={userTableHead}
                      renderHead={renderHead}
                      bodyData={userAksesList.filter((item) => item.idGereja !== 'nf')}
                      renderBody={renderBody}
                    />
                    : 
                    (<div> Belum ada data </div>)
                }
              </Row>
            </div>
          )}
        </Container>
      </div>
    </div>
  )
}

export default MaintUserAkses